import React, { useEffect, useMemo, useState } from "react";
import { Grid, Tabs, Button, Avatar, Box, Tab } from "@material-ui/core";

export default function CategoryTree({
  products,
  onProductSelect,
  base,
  categories = [],
}) {
  const filteredCategories = useMemo(() => {
    return categories.filter((cat) => {
      return cat[base] === true;
    });
  }, [categories, base]);

  return (
    <Grid item sm={12}>
      <CategoryTreeBranch
        categories={filteredCategories}
        products={products}
        parent={null}
        onProductSelect={onProductSelect}
      />
    </Grid>
  );
}

const sorter = (a, b) => {
  if (a.order_in_category > b.order_in_category) {
    return 1;
  }
  if (a.order_in_category < b.order_in_category) {
    return -1;
  }
  return 0;
};

function CategoryTreeBranch({ categories, products, parent, onProductSelect }) {
  const [active, setActive] = useState(null);
  const myCategories = categories.filter(
    (category) => category.category_id === parent
  );
  const myProducts = products.filter(
    (p) => p.category_id !== null && p.category_id === parent
  );

  useEffect(() => {
    if (myCategories.length > 0 && active === null) {
      setActive(myCategories[0].name);
    }
  }, [active, myCategories]);

  const handleChange = (_, newValue) => {
    setActive(newValue);
  };

  return (
    <div style={{ background: "rgba(0,0,0,0.05)", padding: "8px" }}>
      {active && (
        <>
          <Tabs
            value={active}
            variant="scrollable"
            scrollButtons="auto"
            onChange={handleChange}
          >
            {myCategories.sort(sorter).map((cat) => (
              <Tab key={cat.name} label={cat.name} value={cat.name} />
            ))}
          </Tabs>
          {myCategories.map((cat) => (
            <div key={cat.name} hidden={cat.name !== active}>
              <CategoryTreeBranch
                onProductSelect={onProductSelect}
                categories={categories}
                products={products}
                parent={cat.id}
              />
            </div>
          ))}
        </>
      )}
      <Box display="flex" flexWrap="wrap">
        {myProducts.sort(sorter).map((product) => (
          <Box p={1} key={product.id}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => onProductSelect(product.product_id, 1)}
              //   size="large"
              startIcon={
                <Avatar alt={product.name} src={`/uploads/${product.image}`} />
              }
              disableElevation
            >
              {/* {product.image && (
                    <img src={`/uploads/${product.image}`} alt={product.name} />
                  )} */}
              {product.name}
            </Button>
          </Box>
        ))}
      </Box>
    </div>
  );
}
