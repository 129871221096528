import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { Sorting } from "commons/pages/categories/Single";

const base = "categories";
const initialModel = {
  saleable: true,
  purchasable: false,
};

export default function Single() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base,
    initialModel
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/categories/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "category_sorting",
            path: `/s/categories/${id}/sorting`,
            component: <Sorting id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [categories] = useResourcesByQuery("categories", true);

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={6}
        options={categories}
        label="category_id"
        value={model.category_id}
        onChange={updateModel("category_id")}
        error={rules.category_id}
      />
    </ResourceEditor>
  );
}
