import React from "react";
import { FormDateField } from "commons/components/FormDateField";
// import { DateField } from "/commons/components/DateTimeField";

export default function Datefield({ item, onChange }) {
  return (
    <FormDateField
      label={item.label}
      value={item.value}
      onChange={(val) => onChange({ ...item, value: val })}
    />
  );
}
