import { useEffect, useMemo } from "react";

import useResourceMachine from "commons/hooks/useResourceMachine";
import { useParams, useHistory, useLocation } from "react-router-dom";

export default function useControlledResourcePage(
  url,
  initialModel = {},
  autosave = true,
  route = url
) {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
  } = useResourceMachine(url, id, initialModel, autosave);
  const query = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  // STATES to Cover
  // 1- When you go to create, reset the data
  // 2- When the id in url, and id in model are, update model to match url
  // 3- When press back after create have moved to model id, reset the model
  // 4- When Reset is clicked, reset model

  useEffect(() => {
    const isCreate = id === "create";
    const hasID = Boolean(model.id);
    if (isCreate) {
      if (hasID) {
        // move to the new model with the id;
        history.push(`/s/${route}/${model.id}`);
      } else {
        // reset the model
        // use query to get starting model
        const initial = query.get("model");
        send("RESET", { data: JSON.parse(initial) ?? {} });
      }
    } else {
      if (parseInt(id) !== parseInt(model.id)) {
        send("OVERRIDE", { data: { id } });
      }
    }
  }, [id, send, history, model.id, route, query]);

  return {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
    id,
  };
}
