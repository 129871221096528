import common from "commons/languages/arabic";

const trans = {
  program_name: "DS Schools",
  version: "1.0.3",
  operations: "عام",
  manage: "شئون الطلاب",
  hr: "شئون ادارية",
  "teachers-ops": "شئون المعلم",
  courses: "المناهج الدراسية",
  course: "منهج دراسي",
  books: "الكتب",
  book: "كتاب",
  "book-delivery": "تسليم الكتب",
  facilities: "المدارس",
  facility: "مدرسة",
  facility_id: "المدرسة",
  "service-locations": "الفصول",
  "service-location": "فصل",
  service_location: "فصل",
  sessions: "الحصص",
  session: "الحصة",
  "session-attendance": "حضور الطلاب",
  "session-attendances": "حضور الطلاب",
  "customer-payments": "مدفوعات الطلاب",
  "customer-evaluation": "تقييم طالب",
  "customer-evaluations": "تقييمات الطلاب",
  enrollments: "تسجيلات",
  enrollment: "ربط المدارس",
  groups: "المجموعات الدراسية",
  group: "المجموعة",
  customers: "الطلاب",
  customer: "الطالب",
  customer_id: "الطالب",
  product: "خدمة",
  "employee-attendances": "حضور الموظف",
  "employee-leaves": "اجازات الموظف",
  "employee-evaluations": "تقييم الموظف",
  "employee-rewards": "مكافأت الموظف",
  "employee-penalties": "جزاءات الموظف",
  "employee-salaries": "رواتب الموظف",
  teachers: "المعلمون",
  teacher: "المعلم",
  "teacher-attendances": "حضور المعلم",
  "teacher-evaluations": "تقييم المعلم",
  "teacher-salaries": "رواتب المعلم",
  "teacher-leaves": "اجازات المعلم",
  "teacher-rewards": "مكافأت المعلم",
  "teacher-penalties": "جزاءات المعلم",
  num_of_classes: "عدد الحصص",
  class_duration: "مدة الحصة بالدقيقة",
  num_of_pages: "عدد الصفحات",
  author: "المؤلف",
  dob_certificate: "شهادة الميلاد",
  parent_name: "اسم ولي الأمر",
  status: "الحالة",
  REGISTERED: "تسجيل",
  TRANSFERRED: "تحويل",
  EXPELLED: "فصل",
  GRADUATED: "تخرج",
  sales: "ربط مجموعات",
  content: "المحتوي",
  scan: "صورة ضوئية",
  quantity: "العدد",
  PRESENT: "حضور",
  ABSENT: "غياب",
  EXECUSED: "غياب بعذر",
  evaluation_by_session: "تقييم بالحصة",
  evaluation_by_group: "تقييم المجموعة",
  final_grade: "درجة نهائية",
  final_rate: "تقييم",
  average_grade: "متوسط الدرجات",
  verygood: "جيد جدا",
  teachersReport: "تقرير المعلمون",
  personal_photo: "صورة شخصية",
  father_nid_scan: "بطاقة الأب",
  mother_nid_scan: "بطاقة الأم",
};

export default { ...common, ...trans };
