import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "shifts.name",
  },
  {
    name: "facility",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "facilities.name",
  },
  {
    name: "starts",
    type: "time",
    sortable: true,
    searchable: true,
  },
  {
    name: "duration_in_hours",
    type: "number",
    sortable: true,
    searchable: true,
  },
  {
    name: "active_from",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "active_to",
    type: "date",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="shifts" columns={columns} />;
}
