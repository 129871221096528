import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "customer",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "closed",
    type: "boolean",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="projects" columns={columns} />;
}
