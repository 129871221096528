import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import Editor from "./Editor";

const base = "products";
const initialModel = {
  active: true,
  stockable: true,
  purchasable: true,
  saleable: true,
  type: "PIECE",
  image: "product.png",
};

export default function Single() {
  const { current, send, updateModel } = useControlledResourcePage(
    base,
    initialModel
  );
  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <Editor current={current} send={send} updateModel={updateModel} />
    </ResourceSinglePage>
  );
}
