import React, { useEffect } from "react";
import useTranslate from "commons/hooks/useTranslate";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateField } from "commons/components/FormDateField";
// import { pick } from "ramda";
import ResourceRelations from "commons/components/ResourceRelations";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { v4 as uuidv4 } from "uuid";
import { sumField } from "commons/helpers/utils";
import { Grid, IconButton } from "@material-ui/core";
import CardSection from "commons/components/CardSection";
import { Delete } from "@material-ui/icons";
import { parseValue } from "commons/helpers/utils";
import DataCard from "commons/components/DataCard";

const base = "salaries";
const baseModel = {
  lines: [],
  base_salary: 0,
  net_salary: 0,
};
const gradeLineToLine = ({ name, value, min, max }) => ({
  name,
  value,
  min,
  max,
  id: uuidv4(),
  applied_value: 0,
  notes: "",
});

const calculateLine = (base = 0) => (line) => ({
  ...line,
  applied_value: parseValue(line.value, base, line.min, line.max),
});

// TODO
//  UPDATE balance for employees on server, get all rewards and penalties, commissions
//  UPDATE the on employees change to have one request to get totals
//  UPDATE DB to include lines for, commissions, rewards, penalties, basic_salary
//  OR only basic salary as is, and the others in lines
//  Basic Salary is a must for percentage of items
//

export default function Single({ query, title, label }) {
  const {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
  } = useControlledResourcePage(base, baseModel, false, title);

  const [employees] = useResourcesByQuery("employees", true, query);
  const [grades] = useResourcesByQuery("grades", true);
  const [gradeLines] = useResourcesByQuery("grade-lines", true);

  const onEmployeeChange = (val) => {
    const emp = employees.find((emp) => emp.id === val);
    if (!emp) return;
    const grade = grades.find((grade) => grade.id === emp.grade_id);
    const lines_from_grade = !grade
      ? []
      : gradeLines
          .filter((line) => line.grade_id === grade.id)
          .map(gradeLineToLine)
          .map(calculateLine(grade.basic_salary));
    const basic_salary = grade?.basic_salary || 0;
    mergeModel({
      employee_id: emp.id,
      lines: lines_from_grade,
      base_salary: basic_salary,
      net_salary: getNetSalary(lines_from_grade, basic_salary),
    });
  };

  const getNetSalary = (lines = [], base_salary = 0) => {
    const sum = sumField("applied_value")(lines);
    return base_salary + sum;
  };

  const onLinesUpdate = (lines) => {
    const updates = lines.map(calculateLine(model.base_salary));
    mergeModel({
      lines: updates,
      net_salary: getNetSalary(updates, model.base_salary),
    });
  };

  const hasMainData =
    model.employee_id && model.period_start_date && model.period_end_date;

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        grid={3}
        options={employees}
        label={label}
        value={model.employee_id}
        onChange={onEmployeeChange}
        error={rules.employee_id}
      />
      <FormDateField
        grid={3}
        label="period_start_date"
        value={model.period_start_date}
        onChange={updateModel("period_start_date")}
        error={rules.period_start_date}
      />
      <FormDateField
        grid={3}
        label="period_end_date"
        value={model.period_end_date}
        onChange={updateModel("period_end_date")}
        error={rules.period_end_date}
      />
      <FormDateField
        grid={3}
        label="due_date"
        value={model.due_date}
        onChange={updateModel("due_date")}
        error={rules.due_date}
      />
      {hasMainData && (
        <>
          <DataCards model={model} />
          <LineManager
            base_salary={model.base_salary}
            net_salary={model.net_salary}
            value={model.lines}
            onChange={onLinesUpdate}
          />
        </>
      )}
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceSinglePage>
  );
}

function LineManager({ base_salary = 0, net_salary = 0, value, onChange }) {
  const { t } = useTranslate();

  const getFixedRow = (title, value) => (
    <Grid container spacing={2}>
      <Grid item sm>
        <Grid container spacing={2}>
          <FormTextField
            grid={3}
            label="name"
            value={t(title)}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormTextField
            grid={3}
            label="value"
            value={value / 100}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormMoneyField
            grid={3}
            label="applied_value"
            value={value}
            InputProps={{
              readOnly: true,
            }}
          />
          <FormTextField grid={3} label="notes" disabled />
        </Grid>
      </Grid>
      <Grid item xs sm="auto">
        <IconButton size="small" disabled>
          <Delete />
        </IconButton>
      </Grid>
    </Grid>
  );

  const onValueChange = (value, func, { min, max }) => {
    func({
      value: value,
      applied_value: parseValue(value, base_salary, min, max),
    });
  };

  return (
    <ResourceRelations
      title="salary_lines"
      value={value}
      onChange={onChange}
      model={{
        name: "",
        value: "0",
        applied_value: 0,
        notes: "",
      }}
      header={getFixedRow("basic_salary", base_salary)}
      footer={getFixedRow("net_salary", net_salary)}
      rowRenderer={(record, onItemChange, onItemMerge) => (
        <>
          <FormTextField
            grid={3}
            label="name"
            value={record.name}
            onChange={onItemChange("name")}
          />
          <FormTextField
            grid={3}
            label="value"
            value={record.value}
            onChange={(val) => onValueChange(val, onItemMerge, record)}
          />
          <FormMoneyField
            grid={3}
            label="applied_value"
            value={record.applied_value}
            disabled
          />
          <FormTextField
            grid={3}
            label="notes"
            value={record.notes}
            onChange={onItemChange("notes")}
          />
        </>
      )}
    />
  );
}

const query = (
  { employee_id, period_end_date, period_start_date },
  date_field = "date"
) => ({
  employee_id,
  [date_field]: {
    $lte: period_end_date,
    $gte: period_start_date,
  },
});

function DataCards({ model }) {
  const [rewards, rewardsSend] = useResourcesByQuery("rewards");
  const [penalties, penaltiesSend] = useResourcesByQuery("penalties");
  const [attendances, attendancesSend] = useResourcesByQuery("attendances");
  const [leaves, leavesSend] = useResourcesByQuery("leaves");
  const [payments, paymentsSend] = useResourcesByQuery("employee-payments");
  const [evaluations, evaluationsSend] = useResourcesByQuery("evaluations");

  useEffect(() => {
    if (model.period_start_date && model.period_end_date && model.employee_id) {
      rewardsSend("SET_QUERY", { query: query(model) });
      penaltiesSend("SET_QUERY", { query: query(model) });
      leavesSend("SET_QUERY", { query: query(model) });
      paymentsSend("SET_QUERY", { query: query(model) });
      attendancesSend("SET_QUERY", { query: query(model, "enter") });
      evaluationsSend("SET_QUERY", { query: query(model, "starts") });
    }
  }, [
    model,
    rewardsSend,
    penaltiesSend,
    attendancesSend,
    leavesSend,
    paymentsSend,
    evaluationsSend,
  ]);

  return (
    <Grid item xs={12}>
      <CardSection>
        <Grid container spacing={1}>
          <DataCard title="attendances" value={attendances.length} />
          <DataCard title="leaves" value={leaves.length} />
          <DataCard title="rewards" value={sumField("amount")(rewards) / 100} />
          <DataCard
            title="penalties"
            value={sumField("amount")(penalties) / 100}
          />
          <DataCard
            title="payments"
            value={sumField("amount")(payments) / 100}
          />
          <DataCard title="evaluations" value={evaluations.length} />
        </Grid>
      </CardSection>
    </Grid>
  );
}
