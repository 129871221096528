import React, { useCallback, useEffect, useMemo } from "react";
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from "@material-ui/core";
import {
  Add,
  AddLocation,
  AirportShuttle,
  Delete,
  LocalOffer,
  Settings,
  SettingsOverscan,
  Timer,
} from "@material-ui/icons";
import CardSection from "commons/components/CardSection";
import { MoneyField } from "commons/components/FormMoneyField";
import { QuantityField } from "commons/components/FormQuantityField";
import useTranslate from "commons/hooks/useTranslate";
import FormSelectField from "commons/components/FormSelectField";
import useQueryAllResources from "commons/hooks/useQueryAllResources";
import { adjust, assoc, remove } from "ramda";
import FormTextField from "commons/components/FormTextField";
import { FormNumberField } from "commons/components/FormNumberField";
import {
  FormDateTimeField,
  FormTimeField,
} from "commons/components/FormDateField";
import dayjs from "dayjs";
import ExtrasCard from "./ExtrasCard";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
  code: {
    width: "15ch",
  },
  numberField: {
    width: "12ch",
    textAlign: "center",
  },
  number: {
    width: "8ch",
    textAlign: "center",
  },
}));

export default function LinesCard({
  model,
  sales,
  locations = [],
  discounts = [],
  taxes = [],
  commissions = [],
  canDoAction,
  totals = {},
  updateLineField,
  mergeLineFields,
  onChangeQuantity,
  onChangeReturned,
  onDeleteLine,
}) {
  const { t } = useTranslate();
  const classes = useStyles();

  const onChangePrice = updateLineField("price");
  const onChangeServiceLocation = useCallback(
    updateLineField("service_location_id"),
    [updateLineField]
  );
  const onChangeCarRentals = updateLineField("carRentals");

  return (
    <CardSection p={0}>
      <TableContainer style={{ height: 550 }}>
        <Table stickyHeader>
          <TableHead className={classes.highlight}>
            <TableRow>
              {/* <TableCell></TableCell> */}
              <TableCell className={classes.code}>
                <strong>{t("code")}</strong>
              </TableCell>
              <TableCell>
                <strong>{t("name")}</strong>
              </TableCell>
              <TableCell className={classes.numberField}>
                <strong>{t("price")}</strong>
              </TableCell>
              <TableCell className={classes.numberField}>
                <strong>{t("quantity")}</strong>
              </TableCell>
              <TableCell className={classes.numberField}>
                <strong>{t("returned")}</strong>
              </TableCell>
              <TableCell className={classes.number}>
                <strong>{t("stocksTotal")}</strong>
              </TableCell>
              <TableCell className={classes.number}>
                <strong>{t("total")}</strong>
              </TableCell>
              <TableCell padding="checkbox" style={{ textAlign: "center" }}>
                <Settings />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {model.lines.map((line, index) => (
              <TableRow
                key={line.id}
                className={index % 2 ? classes.strip : ""}
              >
                <TableCell padding="checkbox">
                  <Box px={1}>{line.code}</Box>
                </TableCell>
                <TableCell padding="none">
                  <Box px={1}>
                    <Grid container alignItems="center">
                      <Grid item xs>
                        {line.name.substring(0, 50)}
                      </Grid>
                      <Grid item xs="auto">
                        <LocationsPanel
                          line={line}
                          facility_id={model.facility_id}
                          locations={locations}
                          onChange={onChangeServiceLocation(line.id)}
                        />
                      </Grid>
                      {"CAR_RENTAL" === line.type && sales && (
                        <Grid item xs="auto">
                          <CarRentalPanel
                            line={line}
                            items={line.carRentals}
                            locations={locations}
                            onChange={onChangeCarRentals(line.id)}
                          />
                        </Grid>
                      )}
                      <Grid item xs="auto">
                        <QuantityDetailsPanel
                          line={line}
                          updateLineFields={mergeLineFields}
                          onChangeQuantity={onChangeQuantity}
                        />
                      </Grid>
                      <Grid item xs="auto">
                        <ExtrasPanel
                          line={line}
                          updateLineField={updateLineField}
                          discounts={discounts}
                          taxes={taxes}
                          commissions={commissions}
                          canDoAction={canDoAction}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </TableCell>
                <TableCell padding="none">
                  <MoneyField
                    size="small"
                    margin="dense"
                    value={line.price}
                    onChange={onChangePrice(line.id)}
                    disabled={!canDoAction("edit-line-price")}
                  />
                </TableCell>
                <TableCell padding="none">
                  <Box paddingLeft={1}>
                    <QuantityField
                      size="small"
                      margin="dense"
                      value={line.quantity}
                      onChange={onChangeQuantity(line.id)}
                    />
                  </Box>
                </TableCell>
                <TableCell padding="none" style={{ textAlign: "center" }}>
                  <Box paddingLeft={1}>
                    <QuantityField
                      size="small"
                      margin="dense"
                      value={line.returned}
                      onChange={onChangeReturned(line.id)}
                      disabled={!canDoAction("return-line")}
                    />
                  </Box>
                </TableCell>
                <TableCell
                  padding="none"
                  style={{ textAlign: "center", fontSize: "1.2em" }}
                >
                  <strong>{totals[line.product_id]}</strong>
                </TableCell>
                <TableCell
                  padding="none"
                  style={{ textAlign: "center", fontSize: "1.1em" }}
                >
                  <strong>{(line.total / 100).toLocaleString()}</strong>
                </TableCell>
                <TableCell padding="checkbox">
                  <IconButton
                    onClick={onDeleteLine(line)}
                    disabled={
                      line.savedQty > 0 && !canDoAction("remove-saved-line")
                    }
                  >
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </CardSection>
  );
}

function LinePanel({ id, icon, show = true, children }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      {show && (
        <IconButton size="small" onClick={handleClick}>
          {icon}
        </IconButton>
      )}
      <Popover
        id={open ? id : undefined}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        {children}
      </Popover>
    </>
  );
}

function LocationsPanel({ line, locations = [], onChange, facility_id }) {
  const myLocations = useMemo(
    () =>
      locations.filter(
        (loc) =>
          loc.product_id === line.product_id && loc.facility_id === facility_id
      ),
    [locations, line, facility_id]
  );

  useEffect(() => {
    if (myLocations.length === 1 && !line.service_location_id) {
      onChange(myLocations[0].service_location_id);
    }
  }, [myLocations, line.service_location_id, onChange]);

  return (
    <LinePanel
      id={`line-location-popover-${line.id}`}
      icon={
        <AddLocation color={line.service_location_id ? "primary" : "inherit"} />
      }
      show={myLocations.length > 0 && line.type !== "CAR_RENTAL"}
    >
      <Box p={1} minWidth={200}>
        <Grid container spacing={1}>
          <FormSelectField
            label={"service_location"}
            options={myLocations}
            optionKey="service_location_id"
            value={line.service_location_id}
            onChange={onChange}
          />
        </Grid>
      </Box>
    </LinePanel>
  );
}

function CarRentalPanel({ line, items = [], onChange, locations = [] }) {
  const { t } = useTranslate();
  const [employees] = useQueryAllResources("employees");
  const [languages] = useQueryAllResources("languages");

  const guides = useMemo(() => {
    return employees.filter((emp) => !emp.permanent);
  }, [employees]);

  const representatives = useMemo(() => {
    return employees.filter((emp) => emp.permanent);
  }, [employees]);

  const myLocations = useMemo(
    () => locations.filter((loc) => loc.product_id === line.product_id),
    [locations, line]
  );

  const addItem = () => {
    onChange([
      ...items,
      {
        car_id: null,
        external_car: "",
        guest: "",
        passengers: "",
        time: "",
        pickup: "",
        dropoff: "",
        language: "",
        guide_id: null,
        representative_id: null,
        notes: "",
      },
    ]);
  };

  const onItemChange = (index) => (field) => (val) => {
    onChange(adjust(index, assoc(field, val), items));
  };

  const onRemove = (index) => {
    onChange(remove(index, 1, items));
  };

  return (
    <LinePanel
      minWidth="95vw"
      id={`car-rentals-popover`}
      icon={<AirportShuttle />}
    >
      <Box p={1} minWidth="95vw">
        <Toolbar disableGutters>
          <Typography style={{ flex: "1 1 100%" }} variant="h5">
            {t("car-movement")}
          </Typography>
          <IconButton size="small" onClick={addItem}>
            <Add />
          </IconButton>
        </Toolbar>
        {items.map((record, index) => (
          <CardSection p={1} key={index} m={1}>
            <Grid container spacing={2}>
              <Grid item sm>
                <Grid container spacing={1}>
                  <FormTextField
                    label="guest"
                    grid={2}
                    value={record.guest}
                    onChange={onItemChange(index)("guest")}
                  />
                  <FormNumberField
                    label="passengers"
                    grid={2}
                    value={record.passengers}
                    onChange={onItemChange(index)("passengers")}
                  />
                  <FormTimeField
                    label="time"
                    grid={2}
                    value={record.time}
                    onChange={onItemChange(index)("time")}
                  />
                  <FormTextField
                    label="pickup"
                    grid={2}
                    value={record.pickup}
                    onChange={onItemChange(index)("pickup")}
                  />
                  <FormTextField
                    label="dropoff"
                    grid={2}
                    value={record.dropoff}
                    onChange={onItemChange(index)("dropoff")}
                  />
                  <FormSelectField
                    label="car"
                    grid={2}
                    options={myLocations}
                    optionKey="service_location_id"
                    value={record.car_id}
                    onChange={onItemChange(index)("car_id")}
                  />
                </Grid>
                <Grid container spacing={1}>
                  <FormSelectField
                    label="language"
                    grid={2}
                    // options={[
                    //   { id: "English", name: "English" },
                    //   { id: "French", name: "French" },
                    //   { id: "Italian", name: "Italian" },
                    //   { id: "Spanish", name: "Spanish" },
                    //   { id: "German", name: "German" },
                    //   { id: "عربي", name: "عربي" },
                    // ]}
                    options={languages.map(({ name }) => ({
                      id: name,
                      name,
                    }))}
                    value={record.language}
                    onChange={onItemChange(index)("language")}
                  />
                  <FormSelectField
                    label="guide_id"
                    grid={2}
                    options={guides}
                    value={record.guide_id}
                    onChange={onItemChange(index)("guide_id")}
                  />
                  <FormSelectField
                    label="representative_id"
                    grid={2}
                    options={representatives}
                    value={record.representative_id}
                    onChange={onItemChange(index)("representative_id")}
                  />
                  <FormTextField
                    label="external_car"
                    grid={2}
                    value={record.external_car}
                    onChange={onItemChange(index)("external_car")}
                  />
                  <FormTextField
                    label="notes"
                    grid={4}
                    value={record.notes}
                    onChange={onItemChange(index)("notes")}
                  />
                </Grid>
              </Grid>
              <Grid item xs sm="auto">
                <IconButton size="small" onClick={() => onRemove(index)}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </CardSection>
        ))}
      </Box>
    </LinePanel>
  );
}

const getTimeDiff = (scale, record) => {
  const { duration_start, duration_end, threshold, quantity } = record;
  const hasTime = Boolean(duration_start) && Boolean(duration_end);
  if (!hasTime) return quantity;
  const diff = dayjs(duration_end).diff(dayjs(duration_start), scale);
  const scaleValue = getScaleValue(scale);
  const extra = threshold > 0 && diff % scaleValue > threshold ? 1 : 0;
  return Math.floor(diff / scaleValue) + extra;
};

const getScaleValue = (scale) => {
  switch (scale) {
    case "hour":
      return 24;
    case "day":
      return 30;
    case "month":
      return 12;
    default:
      return 60;
  }
};

function QuantityDetailsPanel({ line, onChangeQuantity }) {
  const hasDetails = [
    "AREA",
    "VOLUME",
    "ONCE",
    "HOURLY",
    "MONTHLY",
    "DAILY",
    "YEARLY",
    "CAR_RENTAL",
  ].includes(line.type);

  const hasDistance = ["CAR_RENTAL"].includes(line.type);
  const hasLength = ["VOLUME", "AREA"].includes(line.type);
  const hasWidth = ["VOLUME", "AREA"].includes(line.type);
  const hasHeight = line.type === "VOLUME";
  const hasTime = ["ONCE", "HOURLY", "MONTHLY", "DAILY", "YEARLY"].includes(
    line.type
  );

  const updateField = (field) => (value) => {
    const updated = { ...line, [field]: value };
    let quantity = 0;
    switch (updated.type) {
      case "AREA":
        quantity = updated.length * updated.width;
        break;
      case "VOLUME":
        quantity = updated.length * updated.width * updated.height;
        break;
      case "HOURLY":
        quantity = getTimeDiff("minute", updated);
        break;
      case "DAILY":
        quantity = getTimeDiff("hour", updated);
        break;
      case "MONTHLY":
        quantity = getTimeDiff("day", updated);
        break;
      case "YEARLY":
        quantity = getTimeDiff("month", updated);
        break;
      default:
        quantity = updated.quantity;
    }
    onChangeQuantity(line.id)(quantity, {
      [field]: value,
    });
  };

  return (
    <LinePanel
      id={`quantity-details-popover-${line.id}`}
      icon={hasTime ? <Timer /> : <SettingsOverscan />}
      show={hasDetails}
    >
      <Box p={1}>
        <Grid container spacing={1}>
          {hasDistance && (
            <FormNumberField
              label="distance"
              grid="auto"
              xs="auto"
              value={line.length}
              onChange={updateField("length")}
              disabled={true}
            />
          )}
          {hasLength && (
            <FormNumberField
              label="length"
              grid="auto"
              xs="auto"
              value={line.length}
              onChange={updateField("length")}
            />
          )}
          {hasWidth && (
            <FormNumberField
              label="width"
              grid="auto"
              xs="auto"
              value={line.width}
              onChange={updateField("width")}
            />
          )}
          {hasHeight && (
            <FormNumberField
              label="height"
              grid="auto"
              xs="auto"
              value={line.height}
              onChange={updateField("height")}
            />
          )}
          {hasTime && (
            <>
              <FormDateTimeField
                label="starts"
                grid="auto"
                xs="auto"
                value={line.duration_start}
                onChange={updateField("duration_start")}
              />
              <FormDateTimeField
                label="ends"
                grid="auto"
                xs="auto"
                value={line.duration_end}
                onChange={updateField("duration_end")}
              />
            </>
          )}
        </Grid>
      </Box>
    </LinePanel>
  );
}

function ExtrasPanel({
  line,
  updateLineField,
  discounts = [],
  taxes = [],
  commissions = [],
  canDoAction,
}) {
  const productCommissions = commissions.filter(
    (com) => com.product_id === line.product_id
  );

  return (
    <LinePanel id={`line-extras-popover-${line.id}`} icon={<LocalOffer />}>
      <Box p={1} minWidth={800}>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <ExtrasCard
              Wrapper={PlainWrapper}
              items={line.discounts}
              options={discounts}
              title="discounts"
              onChange={updateLineField("discounts")(line.id)}
              canDoAction={canDoAction}
            />
          </Grid>
          <Grid item xs={4}>
            <ExtrasCard
              Wrapper={PlainWrapper}
              items={line.taxes}
              options={taxes}
              title="taxes"
              onChange={updateLineField("taxes")(line.id)}
              canDoAction={canDoAction}
            />
          </Grid>
          <Grid item xs={4}>
            <ExtrasCard
              Wrapper={PlainWrapper}
              items={line.commissions}
              options={productCommissions}
              title="commissions"
              onChange={updateLineField("commissions")(line.id)}
              canDoAction={canDoAction}
              commissionLabel="employee"
            />
          </Grid>
        </Grid>
      </Box>
    </LinePanel>
  );
}

function PlainWrapper({ children }) {
  return (
    <Box p={1} style={{ background: "rgba(0,0,0,0.05" }}>
      {children}
    </Box>
  );
}
