import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "value",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "active_from",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "active_to",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "min_quantity",
    type: "number",
    sortable: true,
    searchable: true,
  },
  {
    name: "min_price",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "max_value",
    type: "money",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="discounts" columns={columns} />;
}
