import React from "react";
import PageCard from "commons/components/PageCard";
import {
  Typography,
  Grid,
  List,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { Link as RouterLink } from "react-router-dom";
import CardSection from "commons/components/CardSection";
import Stack from "commons/components/Stack";

export const salesReports = [
  { path: "sales-by-operation", title: "salesByOperation" },
  { path: "sales-by-product", title: "salesByProduct" },
  { path: "sales-by-customer", title: "salesByCustomer" },
  { path: "sales-by-category", title: "salesByCategory" },
  { path: "sales-by-user", title: "salesByUser" },
];

export const purchasesReports = [
  { path: "purchases-by-operation", title: "purchasesByOperation" },
  { path: "purchases-by-product", title: "purchasesByProduct" },
  { path: "purchases-by-supplier", title: "purchasesBySupplier" },
  { path: "purchases-by-category", title: "purchasesByCategory" },
  { path: "purchases-by-user", title: "purchasesByUser" },
];

export const accounting = [
  { path: "finance-overview", title: "financeOverview" },
  { path: "accounts-balance", title: "accountsBalance" },
  { path: "currencies-balance", title: "currenciesBalance" },
  { path: "transactions", title: "transactions" },
  { path: "accounting-reports", title: "accountingReports" },
  { path: "accounting-reports-templates", title: "accountingReportsTemplates" },
  // { path: "taxes", title: "taxes" },
  // { path: "accounts", title: "accounts" },
];

export const inventory = [
  { path: "stock-levels", title: "stock-levels" },
  { path: "stock-movements", title: "movements" },
  { path: "stock-value", title: "stock-value" },
  { path: "stock-shortages", title: "shortages" },
  { path: "stock-expired", title: "expired" },
];

export const entities = [
  { path: "customers", title: "customers" },
  { path: "suppliers", title: "suppliers" },
  { path: "contractors", title: "contractors" },
  { path: "employees", title: "employees" },
];

export const misc = [
  { path: "overview", title: "overviewReport" },
  { path: "forms", title: "formsReport" },
  { path: "tasks", title: "tasksReport" },
  { path: "ongoing-services", title: "ongoing-services" },
  { path: "ending-services", title: "ending-services" },
  { path: "audit", title: "audit-logs" },
];

export default function ReportsIndex() {
  const { t } = useTranslate();
  return (
    <PageCard>
      <Stack>
        <Typography variant="h4">{t("reports")}</Typography>
        <Grid container spacing={2}>
          <ReportCard reports={salesReports} title={"salesReports"} />
          <ReportCard reports={purchasesReports} title={"purchasesReports"} />
          <ReportCard reports={inventory} title={"inventory"} />
          <ReportCard reports={entities} title={"entities"} />
          <ReportCard reports={accounting} title={"accounts"} />
          <ReportCard reports={misc} title={"misc"} />
        </Grid>
      </Stack>
    </PageCard>
  );
}

export function ReportCard({ reports = [], title }) {
  const { t } = useTranslate();
  return (
    <Grid item xs={12} sm={4}>
      <CardSection>
        <Stack>
          <Typography variant="h6">{t(title)}</Typography>
          <List disablePadding>
            {reports.map((report) => (
              <ListItem
                button
                key={report.title}
                component={RouterLink}
                to={`/s/reports/${report.path}`}
              >
                <ListItemText primary={t(report.title)} />
              </ListItem>
            ))}
          </List>
        </Stack>
      </CardSection>
    </Grid>
  );
}
