import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import React, {
  useMemo,
  useState,
  useRef,
  useCallback,
  useEffect,
} from "react";
import { Editor, Transforms, Range, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, ReactEditor, withReact } from "slate-react";
import ReactDOM from "react-dom";
import { Grid } from "@material-ui/core";

const initialValue = [
  {
    children: [
      {
        text: "",
      },
    ],
  },
];

export default function PrescriptionBody({
  label = "Rx",
  onChange,
  value = initialValue,
}) {
  const ref = useRef();
  const [target, setTarget] = useState();
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState("");
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const editor = useMemo(
    () => withMentions(withReact(withHistory(createEditor()))),
    []
  );
  const [SHORTCUTS] = useResourcesByQuery("shortcuts", true);

  const shortcuts = SHORTCUTS.filter((c) =>
    c.code.toLowerCase().startsWith(search.toLowerCase())
  ).slice(0, 10);

  const onKeyDown = useCallback(
    (event) => {
      if (target && shortcuts.length > 0) {
        // eslint-disable-next-line default-case
        switch (event.key) {
          case "ArrowDown":
            event.preventDefault();
            const prevIndex = index >= shortcuts.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case "ArrowUp":
            event.preventDefault();
            const nextIndex = index <= 0 ? shortcuts.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case "Tab":
          case "Enter":
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, shortcuts[index]);
            setTarget(null);
            break;
          case "Escape":
            event.preventDefault();
            setTarget(null);
            break;
        }
      }
    },
    [index, target, shortcuts, editor]
  );

  useEffect(() => {
    if (target && shortcuts.length > 0) {
      const el = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.pageYOffset + 24}px`;
      el.style.left = `${rect.left + window.pageXOffset}px`;
    }
  }, [shortcuts.length, editor, index, search, target]);

  return (
    <Grid item xs={12}>
      <h3 style={{ marginBottom: 0 }}>{label}</h3>
      <div className="prescription-body">
        <Slate
          editor={editor}
          value={value}
          onChange={(value) => {
            onChange(value);
            const { selection } = editor;

            if (selection && Range.isCollapsed(selection)) {
              const [start] = Range.edges(selection);
              const wordBefore = Editor.before(editor, start, { unit: "word" });
              const before = wordBefore && Editor.before(editor, wordBefore);
              const beforeRange = before && Editor.range(editor, before, start);
              const beforeText =
                beforeRange && Editor.string(editor, beforeRange);
              const beforeMatch = beforeText && beforeText.match(/^\s?(\w+)$/);
              const after = Editor.after(editor, start);
              const afterRange = Editor.range(editor, start, after);
              const afterText = Editor.string(editor, afterRange);
              const afterMatch = afterText.match(/^(\s|$)/);

              if (beforeMatch && afterMatch) {
                setTarget(beforeRange);
                setSearch(beforeMatch[1]);
                setIndex(0);
                return;
              }
            }

            setTarget(null);
          }}
        >
          <Editable
            renderElement={renderElement}
            onKeyDown={onKeyDown}
            placeholder="Rx."
          />
          {target && shortcuts.length > 0 && (
            <Portal>
              <div
                ref={ref}
                style={{
                  top: "-9999px",
                  left: "-9999px",
                  position: "absolute",
                  zIndex: 1,
                  padding: "3px",
                  background: "white",
                  borderRadius: "4px",
                  boxShadow: "0 1px 5px rgba(0,0,0,.2)",
                }}
              >
                {shortcuts.map((shortcut, i) => (
                  <div
                    key={shortcut.id}
                    style={{
                      padding: "1px 3px",
                      borderRadius: "3px",
                      background: i === index ? "#B4D5FF" : "transparent",
                    }}
                  >
                    {shortcut.code}
                  </div>
                ))}
              </div>
            </Portal>
          )}
        </Slate>
      </div>
    </Grid>
  );
}

export const Portal = ({ children }) => {
  return ReactDOM.createPortal(children, document.body);
};

const Element = (props) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case "shortcut":
      return <ShortcutElement {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const ShortcutElement = ({ attributes, children, element }) => {
  return (
    <span
      {...attributes}
      contentEditable={false}
      style={{
        verticalAlign: "baseline",
        display: "inline-block",
      }}
    >
      {element.shortcut}
      {children}
    </span>
  );
};

const withMentions = (editor) => {
  const { isInline, isVoid } = editor;
  editor.isInline = (element) => {
    return element.type === "shortcut" ? true : isInline(element);
  };
  editor.isVoid = (element) => {
    return element.type === "shortcut" ? true : isVoid(element);
  };
  return editor;
};

const insertMention = (editor, shortcut) => {
  const node = {
    children: [{ text: shortcut ? shortcut.value : "" }],
  };
  Transforms.insertNodes(editor, node);
  Transforms.move(editor);
};
