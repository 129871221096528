import React from "react";
import PageCard from "./PageCard";
import ResourceToolbar from "./ResourceToolbar";
import ResourceEditor from "./ResourceEditor";
import { usePrintManager, PrintTemplate } from "./PrintManager";

export default function ResourceSinglePage({
  title,
  current,
  send,
  children,
  accessGroup,
  notifications,
  toolbarIcons,
  route,
  printer,
  deleteURL,
  ...props
}) {
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <PageCard>
        <ResourceToolbar
          title={title}
          current={current}
          send={send}
          accessGroup={accessGroup}
          notifications={notifications}
          extras={toolbarIcons}
          route={route}
          onPrintRequest={onPrintRequest}
          printer={printer}
          deleteURL={deleteURL}
        />
        <ResourceEditor current={current} send={send} {...props}>
          {children}
        </ResourceEditor>
      </PageCard>
    </PrintTemplate>
  );
}
