import React from "react";
import FormFileField from "commons/components/FormFileField";

export default function Upload({ item, onChange }) {
  return (
    <div style={{ width: "100%" }}>
      <FormFileField
        label={item.label}
        value={item.value}
        onChange={(e) => onChange({ ...item, value: e })}
        preview
      />
    </div>
  );
}
