import React, { useState, useEffect } from "react";
import useTranslate from "commons/hooks/useTranslate";
import { Toolbar, Typography, IconButton } from "@material-ui/core";
import { ViewList, Add } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import PageCard from "commons/components/PageCard";
import { ResourceForm } from "commons/components/ResourceEditor";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceRelations from "commons/components/ResourceRelations";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateField } from "commons/components/FormDateField";
import AttendanceRow from "./AttendanceRow";
import dayjs from "dayjs";

export default function ManageByShift({ query }) {
  const { t } = useTranslate();

  const [data, setData] = useState([]);
  const [facility, setFacility] = useState(null);
  const [shift, setShift] = useState(null);
  const [date, setDate] = useState("");

  const [employees] = useResourcesByQuery("employees", true);
  const [facilities] = useResourcesByQuery("facilities", true);
  const [shifts, shiftsSend] = useResourcesByQuery("shifts");
  const [schedules, schedulesSend] = useResourcesByQuery("schedules");
  const [attendances, attendancesSend] = useResourcesByQuery("attendances");

  useEffect(() => {
    if (facility && date) {
      shiftsSend("SET_QUERY", {
        query: {
          facility_id: facility,
          active_from: { $lte: date },
          active_to: { $gte: date }
        }
      });
    }
  }, [facility, date, shiftsSend]);

  useEffect(() => {
    if (shift && date) {
      //   changeSchedulesQuery({ shift_id: shift });
      schedulesSend("SET_QUERY", {
        query: {
          shift_id: shift,
          "schedules.active_from": { $lte: date },
          "schedules.active_to": { $gte: date }
        }
      });
      attendancesSend("SET_QUERY", {
        query: {
          enter: {
            $gte: dayjs(date).startOf("day"),
            $lte: dayjs(date).endOf("day")
          }
        }
      });
    }
  }, [shift, date, schedulesSend, attendancesSend]);

  const onReset = () => {
    setData([]);
  };

  const onSubmit = () => {
    setData([]);
  };

  const onChange = () => {
    setData([]);
  };

  return (
    <PageCard>
      <p>{JSON.stringify(data)}</p>
      <br />
      <p>{JSON.stringify(schedules)}</p>
      <br />
      <p>{JSON.stringify(attendances)}</p>
      <Toolbar disableGutters>
        <Typography style={{ flex: "1 1 100%" }} variant="h4">
          {t("attendances")}
        </Typography>
        <IconButton onClick={onReset}>
          <Add />
        </IconButton>
        <IconButton component={RouterLink} to={`/s/attendances`}>
          <ViewList />
        </IconButton>
      </Toolbar>
      <ResourceForm onSubmit={onSubmit} dirty={data.length > 0}>
        <FormDateField grid={4} label="date" value={date} onChange={setDate} />
        <FormSelectField
          grid={4}
          options={facilities}
          label="facility"
          value={facility}
          onChange={setFacility}
        />
        <FormSelectField
          grid={4}
          options={shifts}
          label="shift"
          value={shift}
          onChange={setShift}
          //   filters={shiftFilter}
        />
        <ResourceRelations
          title="employees"
          value={data}
          onChange={onChange}
          model={{
            employee_id: null,
            exit: "",
            enter: "",
            hours: 0
          }}
          rowRenderer={(record, onItemChange) => (
            <AttendanceRow
              employees={employees}
              model={record}
              updateModel={onItemChange}
            />
          )}
        />
      </ResourceForm>
    </PageCard>
  );
}
