import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { FormDateField } from "commons/components/FormDateField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";

const base = "schedules";

export default function Single({ query }) {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);
  const [employees] = useResourcesByQuery("employees", true, query);
  const [shifts] = useResourcesByQuery("shifts", true);

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        label="employee"
        grid={3}
        options={employees}
        value={model.employee_id}
        onChange={updateModel("employee_id")}
      />
      <FormSelectField
        label="shift"
        grid={3}
        options={shifts}
        value={model.shift_id}
        onChange={updateModel("shift_id")}
      />
      <FormDateField
        grid={3}
        label="active_from"
        value={model.active_from}
        onChange={updateModel("active_from")}
        error={rules.active_from}
      />
      <FormDateField
        grid={3}
        label="active_to"
        value={model.active_to}
        onChange={updateModel("active_to")}
        error={rules.active_to}
      />
    </ResourceSinglePage>
  );
}
