import React, { useState, useMemo, useCallback } from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import api from "commons/helpers/api";
import { useQuery } from "react-query";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import useTranslate from "commons/hooks/useTranslate";
import {
  TextField,
  Typography,
  Grid,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@material-ui/core";
import { toggle } from "commons/helpers/utils";

const base = "roles";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="label"
        value={model.label}
        onChange={updateModel("label")}
        error={rules.label}
      />
      <PermissionCheckGroup
        value={model.permissions}
        onChange={updateModel("permissions")}
      />
    </ResourceSinglePage>
  );
}

function fetch() {
  return api.service("permissions").find();
}

function PermissionCheckGroup({ value = [], onChange }) {
  const { status, error, data = [] } = useQuery("permissions", fetch);
  const [search, setSearch] = useState("");

  const { t } = useTranslate();

  const trans = useCallback(
    name => {
      const index = name.indexOf("-");
      return index > -1
        ? t(name.substring(0, index)) + " " + t(name.substring(index + 1))
        : t(name);
    },
    [t]
  );

  const permissions = useMemo(
    () =>
      data.map(item => ({
        ...item,
        translated: trans(item.name)
      })),
    [data, trans]
  );

  const handleChange = e => {
    onChange(toggle(parseInt(e.target.value), value));
  };

  return (
    <Grid item xs={12}>
      <LoadingIndicator show={status === "loading"} />
      <ErrorAlert error={error} />
      <br />
      <Typography variant="h5">{t("permissions")}</Typography>
      <TextField
        label={t("filter")}
        value={search}
        onChange={e => setSearch(e.target.value)}
        fullWidth
      />
      {permissions && (
        <FormGroup>
          <Grid container>
            {permissions
              .filter(item =>
                item.translated.toLowerCase().includes(search.toLowerCase())
              )
              .map(item => (
                <Grid key={item.id} item xs={12} sm={3}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={value.includes(item.id)}
                        onChange={handleChange}
                        value={item.id}
                      />
                    }
                    label={t(item.translated)}
                  />
                </Grid>
              ))}
          </Grid>
        </FormGroup>
      )}
    </Grid>
  );
}
