import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { FormNumberField } from "commons/components/FormNumberField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";

const base = "books";

export default function Single() {
  const { current, send, model, updateModel, rules } =
    useControlledResourcePage(base);
  const [courses] = useResourcesByQuery("products", true, {
    type: "COURSE",
  });

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={4}
        label="author"
        value={model.author}
        onChange={updateModel("author")}
        error={rules.author}
      />
      <FormSelectField
        grid={4}
        multiple
        disableCloseOnSelect
        label="groups"
        options={courses}
        value={model.courses}
        onChange={updateModel("courses")}
        error={rules.groups}
      />
      <FormNumberField
        grid={4}
        label="num_of_pages"
        value={model.num_of_pages}
        onChange={updateModel("num_of_pages")}
        error={rules.num_of_pages}
      />
      <FormTextField
        grid={12}
        label="description"
        value={model.description}
        onChange={updateModel("description")}
        error={rules.description}
      />
    </ResourceSinglePage>
  );
}
