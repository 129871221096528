import React from "react";
import { Grid, FormControlLabel, Switch } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";

export default function FormSwitch({
  grid = 12,
  label,
  value = false,
  onChange,
  error,
  ...props
}) {
  const { t } = useTranslate();
  return (
    <Grid item sm={grid} xs={12}>
      <FormControlLabel
        control={<Switch checked={value} onChange={e => onChange(!value)} />}
        label={t(label)}
      />
    </Grid>
  );
}
