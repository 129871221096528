import React, { useMemo } from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import {
  PrintButton,
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";
import usePrintTemplates from "commons/hooks/usePrintTemplates";
import useTranslate from "commons/hooks/useTranslate";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function TransferPrinter({ model, source, destination }) {
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();

  const sourceLines = useMemo(() => {
    if (model.type === "move") {
      return model.lines.filter((line) => line.facility_id === source);
    }
    return model.lines;
  }, [source, model.lines, model.type]);

  const destinationLines = useMemo(() => {
    if (model.type === "move") {
      return model.lines.filter((line) => line.facility_id === destination);
    }
    return [];
  }, [destination, model.lines, model.type]);

  return (
    <>
      <PrintButton
        onPrintRequest={onPrintRequest}
        filterFunc={(item) => item.stocks === true}
      />
      <div style={{ display: "none" }}>
        <PrintTemplate
          active={active}
          onPrintCompleted={onPrintCompleted}
          content={(language) => (
            <div>
              {sourceLines.length > 0 && (
                <TransferFacility
                  language={language}
                  id={model.id}
                  date={model.date}
                  type={model.type}
                  notes={model.notes}
                  lines={sourceLines}
                />
              )}
              <div className="page-break-always" />
              {destinationLines.length > 0 && (
                <TransferFacility
                  language={language}
                  id={model.id}
                  date={model.date}
                  type={model.type}
                  notes={model.notes}
                  lines={destinationLines}
                />
              )}
            </div>
          )}
        />
      </div>
    </>
  );
}

function TransferFacility({ language, id, date, type, notes, lines = [] }) {
  const { t } = useTranslate();
  const { translate } = usePrintTemplates();
  const classes = useStyles();

  return (
    <>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell>{`${t("facility")} : ${lines[0].facility}`}</TableCell>
            <TableCell>{`${t("type")} : ${t(type)}`}</TableCell>
            <TableCell>
              {`${translate(language, "labels", 20, t("date"))} : 
                      ${dayjs(date).format("YYYY-MM-DD")}`}
            </TableCell>
            <TableCell>
              {translate(language, "labels", 1, t("reference_number"))}:{id}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
      <Table>
        <TableHead className={classes.highlight}>
          <TableRow>
            <TableCell></TableCell>
            <TableCell>
              {translate(language, "labels", 12, t("code"))}
            </TableCell>
            <TableCell>{translate(language, "labels", 2, t("line"))}</TableCell>
            <TableCell>
              {translate(language, "labels", 3, t("quantity"))}
            </TableCell>
            <TableCell>
              {translate(language, "labels", 21, t("serial"))}
            </TableCell>
            <TableCell>
              {translate(language, "labels", 22, t("mfg_date"))}
            </TableCell>
            <TableCell>
              {translate(language, "labels", 23, t("exp_date"))}
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {lines.map((line) => (
            <TableRow key={line.id}>
              <TableCell>{t(line.quantity > 0 ? "enter" : "exit")}</TableCell>
              <TableCell>{line.code}</TableCell>
              <TableCell>{line.product}</TableCell>
              <TableCell>{Math.abs(line.quantity)}</TableCell>
              <TableCell>{line.serial}</TableCell>
              <TableCell>
                {line.mfg_date ? dayjs(line.mfg_date).format("YYYY-MM-DD") : ""}
              </TableCell>
              <TableCell>
                {line.exp_date ? dayjs(line.exp_date).format("YYYY-MM-DD") : ""}
              </TableCell>
            </TableRow>
          ))}
          <TableRow>
            <TableCell colSpan={7}>
              {translate(language, "labels", 19, t("notes"))} :{notes}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </>
  );
}
