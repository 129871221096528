import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import { FormDateTimeField } from "commons/components/FormDateField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { FormNumberField } from "commons/components/FormNumberField";

export default function Editor({ current, updateModel }) {
  const { model, rules } = current.context;
  const [products] = useResourcesByQuery("products", true);
  const [policies] = useResourcesByQuery("policies", true, { type: "SALES" });

  return (
    <>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        multiple
        disableCloseOnSelect
        grid={3}
        options={products}
        label="products"
        value={model.products}
        onChange={updateModel("products")}
        error={rules.products}
      />
      <FormSelectField
        multiple
        disableCloseOnSelect
        grid={3}
        options={policies}
        label="policies"
        value={model.policies}
        onChange={updateModel("policies")}
        error={rules.policies}
      />
      <FormDateTimeField
        grid={3}
        label="active_from"
        value={model.active_from}
        onChange={updateModel("active_from")}
        error={rules.active_from}
      />
      <FormDateTimeField
        grid={3}
        label="active_to"
        value={model.active_to}
        onChange={updateModel("active_to")}
        error={rules.active_to}
      />
      <FormTextField
        grid={3}
        label="value"
        value={model.value}
        onChange={updateModel("value")}
        error={rules.value}
      />
      <FormNumberField
        grid={3}
        label="min_quantity"
        value={model.min_quantity}
        onChange={updateModel("min_quantity")}
        error={rules.min_quantity}
      />
      <FormMoneyField
        grid={3}
        label="min_price"
        value={model.min_price}
        onChange={updateModel("min_price")}
        error={rules.min_price}
      />
      <FormMoneyField
        grid={3}
        label="max_value"
        value={model.max_value}
        onChange={updateModel("max_value")}
        error={rules.max_value}
      />
    </>
  );
}
