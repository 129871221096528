import React, { useState } from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import TemplateBuilder from "./TemplateBuilder";
import FormSelectField from "commons/components/FormSelectField";
import FormSwitch from "commons/components/FormSwitch";
import { IconButton, Tooltip } from "@material-ui/core";
import { Code } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";

const base = "form-templates";

export default function Single({ medical = false }) {
  const { t } = useTranslate();
  const [showSource, setShowSource] = useState(false);

  const { current, send, model, updateModel, rules } =
    useControlledResourcePage(
      base,
      { print_customer: true, print_date: true },
      false
    );

  return (
    <ResourceSinglePage
      title={base}
      current={current}
      send={send}
      toolbarIcons={
        <Tooltip title={t("new")}>
          <IconButton onClick={() => setShowSource(!showSource)}>
            <Code />
          </IconButton>
        </Tooltip>
      }
      accessGroup
    >
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      {medical ? (
        <FormSelectField
          grid={3}
          label="type"
          value={model.type}
          onChange={updateModel("type")}
          error={rules.type}
          options={[{ id: "PRESCRIPTION", name: "Prescription" }]}
        />
      ) : (
        <FormTextField
          grid={3}
          label="type"
          value={model.type}
          onChange={updateModel("type")}
          error={rules.type}
        />
      )}
      <FormSelectField
        grid={3}
        label="direction"
        options={[
          { id: "rtl", name: t("rtl") },
          { id: "ltr", name: t("ltr") },
        ]}
        value={model.direction}
        onChange={updateModel("direction")}
        error={rules.direction}
      />
      <FormSwitch
        grid={3}
        label="print_customer"
        value={model.print_customer}
        onChange={updateModel("print_customer")}
      />
      <FormSwitch
        grid={3}
        label="print_date"
        value={model.print_date}
        onChange={updateModel("print_date")}
      />
      {showSource ? (
        <FormTextField
          multiline
          label="Source"
          value={
            typeof model.body === "string"
              ? model.body
              : JSON.stringify(model.body)
          }
          onChange={updateModel("body")}
          error={rules.body}
          style={{ direction: "LTR" }}
        />
      ) : (
        <TemplateBuilder
          value={
            typeof model.body === "string" ? JSON.parse(model.body) : model.body
          }
          onChange={(val) => updateModel("body")(JSON.stringify(val))}
          error={rules.body}
          isPrescription={model.type === "PRESCRIPTION"}
          direction={model.direction}
        />
      )}
    </ResourceSinglePage>
  );
}
