import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateTimeField } from "commons/components/FormDateField";
import ServiceLocationSelect from "commons/components/ServiceLocationSelect";
import { Grid } from "@material-ui/core";
import FormTextField from "commons/components/FormTextField";

const base = "sessions";

export default function Single() {
  const { current, send, updateModel, model, rules } =
    useControlledResourcePage(base);
  const [products] = useResourcesByQuery("products", true, {
    type: "COURSE",
  });

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <Grid item xs={12} sm={12}>
        <ServiceLocationSelect
          value={model.service_location_id}
          onChange={updateModel("service_location_id")}
        />
      </Grid>
      <FormSelectField
        grid={4}
        options={products}
        label="group"
        value={model.product_id}
        onChange={updateModel("product_id")}
        error={rules.product_id}
      />
      <FormDateTimeField
        grid={4}
        label="starts"
        value={model.starts}
        onChange={updateModel("starts")}
        error={rules.starts}
      />
      <FormDateTimeField
        grid={4}
        label="ends"
        value={model.ends}
        onChange={updateModel("ends")}
        error={rules.ends}
      />
      <FormTextField
        multiline
        label="content"
        value={model.content}
        onChange={updateModel("content")}
        error={rules.content}
      />
    </ResourceSinglePage>
  );
}
