import { TextField } from "@material-ui/core";
import React from "react";
import FormTextField from "./FormTextField";

export function FormMoneyField({ value, onChange, ...props }) {
  const validateChange = (val) => {
    const number = Number(val);
    const isNumber = !isNaN(number);
    const validVal = isNumber ? Math.abs(number) : 0;
    onChange(Math.round(validVal * 100));
  };

  const currency = (x) => Number.parseInt(x) / 100;

  return (
    <FormTextField
      type="number"
      value={value ? currency(value) : 0}
      onChange={validateChange}
      inputProps={{
        min: 0,
        step: 0.01,
      }}
      {...props}
    />
  );
}

export function MoneyField({ value, onChange, ...props }) {
  const validateChange = (e) => {
    const number = Number(e.target.value);
    const isNumber = !isNaN(number);
    const validVal = isNumber ? Math.abs(number) : 0;
    onChange(Math.round(validVal * 100));
  };

  const currency = (x) => Number.parseInt(x) / 100;

  return (
    <TextField
      type="number"
      value={value ? currency(value) : 0}
      onChange={validateChange}
      inputProps={{
        min: 0,
        step: 0.01,
      }}
      {...props}
    />
  );
}
