import React, { useState } from "react";
import {
  IconButton,
  TableContainer,
  ClickAwayListener,
} from "@material-ui/core";
import { Block, Edit, CallMerge } from "@material-ui/icons";
import ReactDataSheet from "react-datasheet";
import "react-datasheet/lib/react-datasheet.css";
import { assocPath } from "ramda";

export default function FormTable({ item, onChange }) {
  const [selected, setSelected] = useState(null);

  const handleClickAway = () => {
    // console.log("clicked away");
    if (selected) {
      setSelected(null);
    }
  };

  const onDisable = (flag = true) => () => {
    const grid = item.value.map((row, rowIndex) =>
      row.map((cell, colIndex) => ({
        ...cell,
        readOnly:
          rowIndex >= selected.start.i &&
          rowIndex <= selected.end.i &&
          colIndex >= selected.start.j &&
          colIndex <= selected.end.j
            ? flag
            : cell.readOnly,
      }))
    );
    onChange({ ...item, value: grid });
  };

  const isBetween = (x, a, b) => {
    const min = Math.min(a, b);
    const max = Math.max(a, b);
    return min <= x && x <= max;
  };

  const onMerge = () => {
    const colSpan = Math.abs(selected.start.j - selected.end.j) + 1;
    const rowSpan = Math.abs(selected.start.i - selected.end.i) + 1;
    const cell = item.value[selected.start.i][selected.start.j];
    const updated = assocPath(
      [selected.start.i, selected.start.j],
      { ...cell, colSpan, rowSpan },
      item.value
    );
    const grid = updated.map((row, rowIndex) =>
      row.filter(
        (cell, colIndex) =>
          !(
            isBetween(rowIndex, selected.start.i, selected.end.i) &&
            isBetween(colIndex, selected.start.j, selected.end.j)
          ) ||
          (rowIndex === selected.start.i && colIndex === selected.start.j)
      )
    );
    onChange({ ...item, value: grid });
  };

  return (
    <div style={{ width: "100%" }}>
      <h3>{item.label}</h3>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div>
          <div>
            <IconButton disabled={!selected} onClick={onDisable(false)}>
              <Edit />
            </IconButton>
            <IconButton disabled={!selected} onClick={onDisable(true)}>
              <Block />
            </IconButton>
            <IconButton disabled={!selected} onClick={onMerge}>
              <CallMerge />
            </IconButton>
            {/* <IconButton disabled={!selected}>
              <VerticalSplit />
            </IconButton>
            <IconButton disabled={!selected}>
              <HorizontalSplit />
            </IconButton> */}
          </div>
          <TableContainer>
            <ReactDataSheet
              data={item.value}
              valueRenderer={(cell) => cell.value}
              onCellsChanged={(changes) => {
                const grid = item.value.map((row) => [...row]);
                changes.forEach(({ cell, row, col, value }) => {
                  grid[row][col] = { ...grid[row][col], value };
                });
                onChange({ ...item, value: grid });
              }}
              onSelect={setSelected}
            />
          </TableContainer>
        </div>
      </ClickAwayListener>
    </div>
  );
}
