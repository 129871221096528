import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { Grid } from "@material-ui/core";
import FormFileField from "commons/components/FormFileField";

const base = "attachments";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <Grid item xs sm={12}>
        <FormFileField
          label="path"
          value={model.path}
          onChange={updateModel("path")}
          error={rules.path}
          preview
        />
      </Grid>
      <FormTextField
        label="label"
        value={model.label}
        onChange={updateModel("label")}
        error={rules.label}
      />
    </ResourceSinglePage>
  );
}
