import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { Products } from "commons/pages/employees/Controller";

const base = "teachers";

export default function Standalone() {
  const { current, send, model, updateModel, id } =
    useControlledResourcePage(base);
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar
        title={base}
        deleteURL="employees"
        current={current}
        send={send}
        accessGroup
      />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/teachers/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/teachers/${id}/balance`,
            component: null,
          },
          {
            name: "services",
            path: `/s/teachers/${id}/services`,
            component: <Products services id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormTextField
        grid={6}
        label="nid"
        value={model.nid}
        onChange={updateModel("nid")}
        error={rules.nid}
      />
      <FormTextField
        grid={3}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={3}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={3}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />

      <FormDateField
        grid={3}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}
