import React from "react";

export default function Upload({ item }) {
  return (
    <img
      src={`/uploads/${item.value}`}
      width={item.width}
      height={item.height}
      alt="for print"
    />
  );
}
