import { useContext } from "react";
import { TranslateContext } from "commons/helpers/contexts";

export default function useTranslate() {
  const { t, language, setLanguage } = useContext(TranslateContext);

  return {
    t,
    language,
    setLanguage,
  };
}
