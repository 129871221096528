import React, { useCallback, useEffect } from "react";
import useTranslate from "commons/hooks/useTranslate";
import { useHistory, Link as RouterLink } from "react-router-dom";
import {
  Toolbar,
  AppBar,
  Box,
  IconButton,
  Typography,
  Tooltip,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Link,
  Badge,
} from "@material-ui/core";
import {
  AccountCircle,
  Settings,
  ExitToApp,
  Menu as MenuIcon,
  Mail,
  Notifications,
  AssignmentOutlined,
  HourglassFullRounded,
  AirportShuttle,
} from "@material-ui/icons";
import dayjs from "dayjs";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import api from "commons/helpers/api";

export default function MainAppBar({
  onLogout,
  companyName,
  toggleDrawer,
  className,
  user,
  messages = false,
  notifications = false,
  ongoing = false,
  cars = false,
}) {
  const { t } = useTranslate();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onChangePassword = () => {
    history.push("/s/change-password");
  };

  return (
    <AppBar position="fixed" className={className}>
      <Toolbar>
        <Box mx={2}>
          <IconButton
            onClick={toggleDrawer}
            edge="start"
            color="inherit"
            aria-label="menu"
          >
            <MenuIcon />
          </IconButton>
        </Box>
        <Box flexGrow={1}>
          <Typography variant="h6">
            <Link
              component={RouterLink}
              to="/"
              color="inherit"
              underline="none"
            >
              {companyName}
            </Link>
          </Typography>
        </Box>

        {ongoing && (
          <Tooltip title={t("ongoing-services")}>
            <IconButton
              component={RouterLink}
              to="/s/reports/ongoing-services"
              color="inherit"
            >
              <HourglassFullRounded />
            </IconButton>
          </Tooltip>
        )}
        {cars && (
          <Tooltip title={t("car-movement")}>
            <IconButton
              component={RouterLink}
              to="/s/reports/car-movement"
              color="inherit"
            >
              <AirportShuttle />
            </IconButton>
          </Tooltip>
        )}
        {notifications && <NotificationsButton />}
        {notifications && (
          <Tooltip title={t("tasks")}>
            <IconButton component={RouterLink} to="/s/my-tasks" color="inherit">
              <AssignmentOutlined />
            </IconButton>
          </Tooltip>
        )}
        {messages && <MessagesButton />}
        <Tooltip title={user.username}>
          <IconButton
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem onClick={onChangePassword}>
            <ListItemIcon>
              <Settings fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("changePassword")} />
          </MenuItem>
          <MenuItem onClick={onLogout}>
            <ListItemIcon>
              <ExitToApp fontSize="small" />
            </ListItemIcon>
            <ListItemText primary={t("logout")} />
          </MenuItem>
        </Menu>
      </Toolbar>
    </AppBar>
  );
}

function MessagesButton() {
  const { t } = useTranslate();
  const [messages] = useResourcesByQuery("messages", true, {
    $inbox: true,
    read: null,
  });

  return (
    <Tooltip title={t("messages")}>
      <IconButton color="inherit" component={RouterLink} to={`/s/messages`}>
        <Badge badgeContent={messages.length} color="secondary">
          <Mail />
        </Badge>
      </IconButton>
    </Tooltip>
  );
}

function NotificationsButton() {
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [notifications, send] = useResourcesByQuery("notification-user", true, {
    "notifications.date": {
      $gt: dayjs().startOf("day"),
      $lt: dayjs().endOf("day"),
    },
  });

  const onDataChange = useCallback(() => {
    // console.log("onDataChanged");
    send("SET_QUERY", {
      query: {
        "notifications.date": {
          $gt: dayjs().startOf("day"),
          $lt: dayjs().endOf("day"),
        },
      },
    });
  }, [send]);

  useEffect(() => {
    api.service("notifications").on("created", onDataChange);
    api.service("notifications").on("updated", onDataChange);
    api.service("notifications").on("patched", onDataChange);
    api.service("notifications").on("removed", onDataChange);
    return () => {
      api.service("notifications").removeListener("created", onDataChange);
      api.service("notifications").removeListener("updated", onDataChange);
      api.service("notifications").removeListener("patched", onDataChange);
      api.service("notifications").removeListener("removed", onDataChange);
    };
  }, [onDataChange]);

  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    if (notifications.length > 0) {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={t("notifications")}>
        <IconButton color="inherit" onClick={handleMenu}>
          <Badge badgeContent={notifications.length} color="secondary">
            <Notifications />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-notifications"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={open}
        onClose={handleClose}
      >
        {notifications.map((notification) => (
          <MenuItem
            key={notification.id}
            component={RouterLink}
            to={`/s/${notification.resource_type}/${notification.resource_id}`}
          >
            <ListItemText primary={notification.notes.substr(0, 50)} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
