import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import { Importer } from "commons/components/Importer";
import dayjs from "dayjs";

const columns = [
  {
    name: "employee",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "employees.name",
  },
  {
    name: "enter",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "exit",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "hours",
    type: "number",
    sortable: true,
    searchable: true,
  },
];

export default function List({ title, query }) {
  return (
    <ResourceListPage
      url="attendances"
      columns={columns}
      title={title}
      baseQuery={query}
      actions={
        <Importer
          base="attendances"
          columns={["employee_id", "enter", "exit", "hours"]}
          beforeCommit={(records) => {
            console.log(records);
            const recs = records.map((rec) => ({
              ...rec,
              hours: dayjs(rec.exit).diff(dayjs(rec.enter), "hour"),
            }));
            console.log(recs);
            return recs;
          }}
        />
      }
    />
  );
}
