import React from "react";
import { __, endsWith, T, always, cond, identity } from "ramda";

export default function FilePreview({ source, ...props }) {
  const checkEXT = (exts) => (value) => exts.some(endsWith(__, value));
  const getFilePreview = cond([
    [checkEXT([".docx", ".doc"]), always("word_file.png")],
    [checkEXT([".xlsx", ".xls"]), always("excel_file.png")],
    [checkEXT([".zip", ".rar"]), always("zip_file.png")],
    [checkEXT([".pdf"]), always("pdf_file.png")],
    [checkEXT([".png", ".jpg", ".jpeg"]), identity],
    [T, always("file.png")],
  ]);
  return (
    <img src={`/uploads/${getFilePreview(source)}`} alt="Preview" {...props} />
  );
}
