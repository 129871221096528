import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateField } from "commons/components/FormDateField";

const base = "evaluations";

export default function Single({ query, title, label }) {
  const { t } = useTranslate();
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, {}, true, title);
  const [employees] = useResourcesByQuery("employees", true, query);

  return (
    <ResourceSinglePage title={title} current={current} send={send}>
      <FormSelectField
        grid={4}
        options={employees}
        label={label}
        value={model.employee_id}
        onChange={updateModel("employee_id")}
        error={rules.employee_id}
      />
      <FormSelectField
        grid={2}
        label="rate"
        value={model.rate}
        onChange={updateModel("rate")}
        error={rules.rate}
        options={[
          { id: "poor", name: t("poor") },
          { id: "fair", name: t("fair") },
          { id: "good", name: t("good") },
          { id: "excellent", name: t("excellent") },
        ]}
      />
      <FormDateField
        grid={3}
        label="starts"
        value={model.starts}
        onChange={updateModel("starts")}
        error={rules.starts}
      />
      <FormDateField
        grid={3}
        label="ends"
        value={model.ends}
        onChange={updateModel("ends")}
        error={rules.ends}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceSinglePage>
  );
}
