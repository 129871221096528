import React from "react";
import PageCard from "commons/components/PageCard";
import { Grid, Typography } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import Editor from "./Editor";
import ResourceToolbar from "commons/components/ResourceToolbar";
import LinkTabs from "commons/components/LinkTabs";
import ProductConnector from "commons/components/ProductConnector";
import { FormNumberField } from "commons/components/FormNumberField";

const base = "facilities";

export default function Controller() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/facilities/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "services",
            path: `/s/facilities/${id}/services`,
            component: <Products services id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

function Products({ id, ...props }) {
  const { t } = useTranslate();
  return (
    <ProductConnector
      id={id}
      {...props}
      relation="facility-product"
      relationKey="facility_id"
      model={{
        facility_id: id,
        max_level: 0,
        min_level: 0,
        max_in_sale: 0,
        reorder_level: 0,
      }}
      header={
        <>
          <Grid item sm={3}>
            <Typography variant="subtitle1">{t("max_in_sale")}</Typography>
          </Grid>
        </>
      }
      rowRenderer={(record, onItemChange) => (
        <>
          <FormNumberField
            grid={3}
            size="small"
            value={record.max_in_sale}
            onChange={onItemChange("max_in_sale")}
          />
        </>
      )}
    />
  );
}
