import React, { useState } from "react";
import {
  Container,
  Button,
  TextField,
  MenuItem,
  Paper,
  Box,
  Typography,
  Link,
  Grow,
} from "@material-ui/core";
import Stack from "commons/components/Stack";
import { makeStyles } from "@material-ui/core/styles";
import useTranslate from "commons/hooks/useTranslate";

const useStyles = makeStyles((theme) => ({
  page: {
    background: `linear-gradient(to bottom, ${theme.palette.primary.dark}, ${theme.palette.primary.light})`,
    width: "100%",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  link: {
    color: theme.palette.secondary.dark,
  },
}));

export default function Login({
  logo,
  onSubmit,
  companyName = "",
  showLangSelect = false,
  hideCopyright = false,
}) {
  const classes = useStyles();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const { language, setLanguage, t } = useTranslate();

  return (
    <div className={classes.page}>
      <Container component="main" maxWidth="xs">
        <Grow in>
          <Paper elevation={12}>
            <Box
              p={2}
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={logo}
                alt="Logo"
                className="rounded"
                style={{ maxWidth: "80%", height: "auto" }}
              />
              <h1>{companyName}</h1>
              <form
                noValidate
                autoComplete="off"
                onSubmit={(e) => {
                  e.preventDefault();
                  onSubmit(username, password);
                }}
              >
                <Stack>
                  {showLangSelect && (
                    <TextField
                      select
                      id="filled-basic"
                      label={t("language")}
                      value={language}
                      onChange={(e) => setLanguage(e.target.value)}
                    >
                      <MenuItem value="ar">العربية</MenuItem>
                      <MenuItem value="en">English</MenuItem>
                    </TextField>
                  )}
                  <TextField
                    label={t("username")}
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                  <TextField
                    type="password"
                    label={t("password")}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    fullWidth
                  >
                    {t("login")}
                  </Button>
                </Stack>
              </form>
            </Box>
          </Paper>
        </Grow>
        {!hideCopyright && (
          <Grow in>
            <Box mt={2}>
              <Typography variant="body1" align="center">
                {t("developed_by")} &nbsp;
                <Link
                  className={classes.link}
                  title="Digital Space LLC"
                  href="https://www.dsluxor.com"
                >
                  Digital Space
                </Link>
                .
              </Typography>
            </Box>
          </Grow>
        )}
      </Container>
    </div>
  );
}
