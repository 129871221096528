import { sumField } from "commons/helpers/utils";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { calcDiscount, calcTax, calcCommission } from "./utils/calculators";
import isBetween from "dayjs/plugin/isBetween";
import { prepareCommission, prepareDiscount } from "./utils/initializers";
import { prepareTax } from "../sales/utils/initializers";
dayjs.extend(isBetween);

export const isActive =
  ({ date, policy_id }) =>
  (item) =>
    (item.policies.includes(policy_id) || item.policies.length === 0) &&
    dayjs(date).isBetween(item.active_from, item.active_to);

export const isForOp = (model) => (item) => {
  return isActive(model)(item) && item.products.length === 0;
};

const discountMatchesProduct = (model, product_id) => (discount) =>
  discount.products.includes(product_id) && isActive(model)(discount);

const taxMatchesProduct = (model, product_id) => (tax) =>
  tax.products.includes(product_id) && isActive(model)(tax);

const commissionMatchesProduct = (product_id) => (commission) =>
  commission.product_id === product_id && commission.permanent === true;

export const applyLineExtras =
  (discounts, taxes, commissions, model, product) => (line) => {
    return {
      ...line,
      discounts: discounts
        .filter(discountMatchesProduct(model, product.product_id))
        .map(prepareDiscount),
      taxes: taxes
        .filter(taxMatchesProduct(model, product.product_id))
        .map(prepareTax),
      commissions: commissions
        .filter(commissionMatchesProduct(product.product_id))
        .map(prepareCommission),
    };
  };

export const lineFromProduct = (
  product,
  policies,
  policy_id,
  sales = true
) => ({
  id: uuidv4(),
  name: product.name,
  code: product.code,
  type: product.type,
  product_id: product.product_id,
  date: dayjs(),
  savedQty: 0,
  savedReturned: 0,
  savedCost: 0,
  quantity: 0,
  returned: 0,
  actual: 0,
  stocks: [],
  carRentals: [],
  discounts: [],
  taxes: [],
  commissions: [],
  ...getProductPrice(
    policies,
    policy_id,
    product,
    sales ? "sale_price" : "purchase_price"
  ),
  ...getProductTypeFields(product),
});

export const getProductPrice = (
  policies,
  policy_id,
  product,
  field = "sale_price"
) => {
  const policy = policies.find(
    (pol) =>
      pol.policy_id === policy_id && pol.product_id === product.product_id
  );
  return {
    price: policy ? policy.price : product[field],
    cost_price: product.cost_price,
  };
};

const getProductTypeFields = (product) => {
  switch (product.type) {
    case "AREA":
      return { length: 1, width: 1 };
    case "VOLUME":
      return { length: 1, width: 1, height: 1 };
    case "CAR_RENTAL":
      return { length: product.threshold };
    case "ONCE":
    case "HOURLY":
    case "DAILY":
    case "MONTHLY":
    case "YEARLY":
      return {
        duration_start: product.duration_start || dayjs(),
        duration_end: product.duration_end,
        threshold: product.threshold,
      };
    default:
      return {};
  }
};

const generateSummary = (lines) => {
  return lines.reduce(
    (acc, line) => (acc += "(" + line.actual + " " + line.name + ") "),
    ""
  );
};

// calc cost
// make sure negative works as well.
const calculateLine =
  (rate = 100) =>
  (line) => {
    const actual = line.quantity - line.returned;
    const subtotal = line.price * actual;
    const discounts = line.discounts.map((discount) => ({
      ...discount,
      applied_value: calcDiscount(line.price, actual, discount),
    }));
    const taxes = line.taxes.map((tax) => ({
      ...tax,
      applied_value: calcTax(line.price, tax),
    }));
    const commissions = line.commissions.map((commission) => ({
      ...commission,
      applied_value: calcCommission(line.price, commission) * actual,
    }));

    const discount = sumField("applied_value")(discounts) * actual;
    const tax = sumField("applied_value")(taxes) * actual;
    const commission = sumField("applied_value")(commissions);
    const cost =
      rate === 100
        ? sumField("total_value")(line.stocks)
        : Math.round((sumField("total_value")(line.stocks) / rate) * 100);
    // const total = Math.max(0, subtotal - discount);
    const total = subtotal - discount;

    return {
      ...line,
      actual,
      discounts,
      taxes,
      commissions,
      subtotal,
      discount,
      tax,
      commission,
      total,
      cost_price: Math.floor(cost / actual),
      cost,
      profit: total + cost - commission,
    };
  };

// works for purchases, sales, returns, stocks manual and automatic
export const calculateModel = (base) => (model) => {
  // const lines = model.lines.map((line) => calcCost(calcLine(line)));
  const lines = model.lines.map(calculateLine(model.rate));
  const summary = generateSummary(lines);
  const subtotal = sumField("subtotal")(lines);

  const discounts = model.discounts.map((discount) => ({
    ...discount,
    applied_value: calcDiscount(subtotal, lines.length, discount),
  }));

  const model_discounts = sumField("applied_value")(discounts);
  const lines_discounts = sumField("discount")(lines);
  const discount = model_discounts + lines_discounts;

  const taxes = model.taxes.map((tax) => ({
    ...tax,
    applied_value: calcTax(subtotal - discount, tax),
  }));
  const model_taxes = sumField("applied_value")(taxes);
  const lines_taxes = sumField("tax")(lines);
  const tax = model_taxes + lines_taxes;

  const commissions = model.commissions.map((commission) => ({
    ...commission,
    applied_value: calcCommission(subtotal - discount, commission),
  }));

  const model_commissions = sumField("applied_value")(commissions);
  const lines_commissions = sumField("commission")(lines);
  const commission = model_commissions + lines_commissions;

  const lines_total = sumField("total")(lines);
  const total = lines_total + tax - model_discounts;

  const modelStocksCost = sumField("total_value")(model.stocks || []);
  const cost = sumField("cost")(lines) + modelStocksCost;
  const profit = total + cost - commission;

  return {
    ...model,
    lines,
    summary,
    discounts,
    commissions,
    taxes,
    subtotal,
    discount,
    commission,
    tax,
    total,
    cost,
    profit,
  };
};
