import React, { useState, useMemo, useRef, useEffect } from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormSelectField from "commons/components/FormSelectField";
import useTranslate from "commons/hooks/useTranslate";
import {
  FormDateTimeField,
  FormDateField,
} from "commons/components/FormDateField";
import FormTextField from "commons/components/FormTextField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import CardSection from "commons/components/CardSection";
import {
  Grid,
  Typography,
  Box,
  makeStyles,
  IconButton,
} from "@material-ui/core";
import { VariableSizeList } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";
import {
  KeyboardArrowDown,
  FileCopyOutlined,
  Add,
  KeyboardArrowUp,
  Delete,
} from "@material-ui/icons";
import { prop, groupBy, assoc, adjust, remove } from "ramda";
import dayjs from "dayjs";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { FormQuantityField } from "commons/components/FormQuantityField";
import FormSwitch from "commons/components/FormSwitch";
import { toggle } from "commons/helpers/utils";
import { v4 as uuidv4 } from "uuid";
import TransferPrinter from "./TransferPrinter";
import { sumField } from "commons/helpers/utils";

const base = "transfers";
const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.1)",
  },
}));

export default function Single() {
  const { t } = useTranslate();
  const {
    current,
    send,
    model,
    updateModel,
    mergeModel,
    rules,
  } = useControlledResourcePage(base, { date: dayjs(), lines: [] }, false);

  const [facilities] = useResourcesByQuery("facilities", true);
  const [source, setSource] = useState(null);
  const [destination, setDestination] = useState(null);

  const isSaved = Boolean(model.id);
  const hasLines = model.lines && model.lines.length > 0;
  const hasBasicData =
    (model.type === "move" && source && destination) ||
    (model.type === "add" && source) ||
    (model.type === "deduct" && source);

  const types = [
    { id: "add", name: t("add") },
    { id: "deduct", name: t("deduct") },
    { id: "move", name: t("move") },
    // { id: "assemble", name: t("assemble") },
    // { id: "disassemble", name: t("disassemble") }
  ];

  const getFacilityName = (id) => {
    const facility = facilities.find((fac) => fac.id === id);
    return facility ? facility.name : "";
  };

  const updateLines = (updates, type) => {
    // console.log("updates", updates);
    const direction = type === "add" ? 1 : -1;
    let lines = updates.map((line) => ({
      ...line,
      quantity: Math.abs(line.quantity) * direction,
      total_value: Math.abs(line.quantity) * line.value * direction,
      requested: model.date,
      fulfilled: model.date,
      facility_id: source,
      operation_type: "transfer",
    }));
    const summary = lines.reduce(
      (acc, line) =>
        (acc += "(" + Math.abs(line.quantity) + " " + line.name + ") "),
      `[${getFacilityName(source)}] ${
        model.type === "move" && destination
          ? "-> [" + getFacilityName(destination) + "] "
          : ""
      }`
    );
    if (model.type === "move") {
      lines = [
        ...lines,
        ...lines.map((line) => ({
          ...line,
          quantity: line.quantity * -1,
          total_value: line.total_value * -1,
          facility_id: destination,
        })),
      ];
    }
    return [lines, summary];
  };

  const sourceLines = useMemo(() => {
    if (model.type === "move") {
      return model.lines.filter((line) => line.facility_id === source);
    }
    return model.lines;
  }, [source, model.lines, model.type]);

  const onLinesChange = (updates) => {
    const [lines, summary] = updateLines(updates, model.type);
    mergeModel({ lines, summary });
  };

  useEffect(() => {
    if (isSaved && !source && model.lines) {
      if (model.type === "add") {
        const first = model.lines.find((line) => line.quantity > 0);
        setSource(first ? first.facility_id : null);
      } else {
        const first = model.lines.find((line) => line.quantity < 0);
        setSource(first ? first.facility_id : null);
      }
    }
  }, [isSaved, source, model.lines, model.type]);

  useEffect(() => {
    if (isSaved && !destination && model.type === "move" && model.lines) {
      const first = model.lines.find((line) => line.quantity > 0);
      setDestination(first ? first.facility_id : null);
    }
  }, [isSaved, destination, model.lines, model.type]);

  return (
    <ResourceSinglePage
      title={base}
      current={current}
      send={send}
      accessGroup
      printer={
        <TransferPrinter
          model={model}
          source={source}
          destination={destination}
        />
      }
    >
      <FormSelectField
        grid={2}
        label="type"
        options={types}
        value={model.type}
        onChange={updateModel("type")}
        error={rules.type}
        disabled={isSaved || hasLines}
      />
      <FormDateTimeField
        grid={2}
        label="date"
        value={model.date}
        onChange={updateModel("date")}
        error={rules.date}
        disabled={isSaved || hasLines}
      />
      <FormTextField
        grid={2}
        label="reference"
        value={model.reference}
        onChange={updateModel("reference")}
        error={rules.reference}
        disabled={isSaved}
      />
      {Boolean(model.type) && (
        <FormSelectField
          grid={3}
          label={model.type === "move" ? t("from") : t("facility")}
          value={source}
          options={facilities}
          onChange={setSource}
          disabled={isSaved || hasLines}
        />
      )}
      {model.type === "move" && (
        <FormSelectField
          grid={3}
          label="to"
          value={destination}
          options={facilities}
          onChange={setDestination}
          disabled={isSaved || hasLines}
        />
      )}
      {isSaved && <StockLinesViewer lines={sourceLines} />}
      {!isSaved && hasBasicData && (
        <StockLinesManager
          source={source}
          type={model.type}
          value={sourceLines}
          onChange={onLinesChange}
          allowNew={model.type === "add"}
        />
      )}
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.reference}
        disabled={isSaved}
      />
    </ResourceSinglePage>
  );
}

function StockLinesViewer({ lines = [] }) {
  const { t } = useTranslate();
  const [codeFilter, setCodeFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");

  return (
    <Grid item sm={12}>
      <CardSection>
        <Grid container spacing={2}>
          <FormTextField
            grid={3}
            label="searchByCode"
            value={codeFilter}
            onChange={setCodeFilter}
          />
          <FormTextField
            grid={3}
            label="searchByName"
            value={nameFilter}
            onChange={setNameFilter}
          />
        </Grid>
      </CardSection>
      <CardSection>
        <Grid container spacing={1} alignItems="center">
          <ValueViewer value={t("code")} grid={2} />
          <ValueViewer value={t("product")} grid={4} />
          <ValueViewer value={t("unit_value")} />
          <ValueViewer value={t("quantity")} />
          <ValueViewer value={t("total_value")} />
          <ValueViewer value={t("serial")} />
          <ValueViewer value={t("mfg_date")} />
          <ValueViewer value={t("exp_date")} />
        </Grid>
      </CardSection>
      <Box minHeight="50vh">
        {lines.map((line, index) => (
          <Grid key={index} container alignItems="center">
            <ValueViewer value={line.code} grid={2} />
            <ValueViewer value={line.product} grid={4} />
            <ValueViewer value={line.value / 100} />
            <ValueViewer value={Math.abs(line.quantity)} />
            <ValueViewer value={Math.abs(line.total_value / 100)} />
            <ValueViewer value={line.serial} />
            <ValueViewer
              value={
                line.mfg_date ? dayjs(line.mfg_date).format("YYYY-MM-DD") : ""
              }
            />
            <ValueViewer
              value={
                line.exp_date ? dayjs(line.exp_date).format("YYYY-MM-DD") : ""
              }
            />
            {/* <ValueViewer value={line.notice} /> */}
          </Grid>
        ))}
      </Box>
    </Grid>
  );
}

function ValueViewer({ value, grid = 1 }) {
  return (
    <Grid item sm={grid}>
      <Typography variant="subtitle1" align="center">
        {value}
      </Typography>
    </Grid>
  );
}

function StockLinesManager({
  value = [],
  onChange,
  source = null,
  allowNew = false,
}) {
  const { t, language } = useTranslate();
  const listRef = useRef(null);
  const [codeFilter, setCodeFilter] = useState("");
  const [nameFilter, setNameFilter] = useState("");
  const [showChangesOnly, setShowChangesOnly] = useState(false);
  const [showHasQuantityOnly, setShowHasQuantityOnly] = useState(false);
  const [showHasNoQuantityOnly, setShowHasNoQuantityOnly] = useState(false);
  const [openedLines, setOpenedLines] = useState([]);
  const [allOpened, setAllOpened] = useState(false);
  const [products, sendProducts] = useResourcesByQuery(
    "facility-product",
    false
  );
  const [levels, sendLevels] = useResourcesByQuery("stock-levels", false);

  const levelsByKey = useMemo(() => groupBy(prop("product_id"), levels), [
    levels,
  ]);
  const changesByKey = useMemo(() => groupBy(prop("product_id"), value), [
    value,
  ]);

  const onRowChange = (product_id) => (changes) => {
    onChange(Object.values({ ...changesByKey, [product_id]: changes }).flat());
  };

  const getItemSize = (index) => {
    const product = products[index];
    const levels = levelsByKey[product.product_id] || [];
    const changes = changesByKey[product.product_id] || [];
    // return 60 + levels.length * 60 + changes.length * 60 + 60;
    if (isOpened(product.product_id)) {
      return (
        60 + levels.length * 60 + changes.length * 60 + (allowNew ? 60 : 0)
      );
    }
    return 60;
  };

  useEffect(() => {
    if (listRef && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [products, levelsByKey, changesByKey]);

  useEffect(() => {
    if (source) {
      sendLevels("SET_QUERY", {
        query: {
          facility_id: source,
        },
      });
    }
  }, [sendLevels, source]);

  useEffect(() => {
    if (source) {
      sendProducts("SET_QUERY", {
        query: {
          facility_id: source,
          stockable: true,
        },
      });
    }
  }, [sendProducts, source]);

  const toggleLineOpened = (product_id) => {
    setOpenedLines(toggle(Number(product_id), openedLines));
    listRef.current.resetAfterIndex(0);
  };

  const isOpened = (product_id) =>
    allOpened || openedLines.includes(product_id);

  const filteredProducts = useMemo(
    () =>
      products.filter((product) => {
        const hasCode = product.code.startsWith(codeFilter);
        const hasName = product.name.includes(nameFilter);
        const hasChange = showChangesOnly
          ? Boolean(changesByKey[product.product_id])
          : true;
        const hasQuantity = showHasQuantityOnly
          ? Boolean(levelsByKey[product.product_id])
          : true;
        const hasNoQuantity = showHasNoQuantityOnly
          ? !Boolean(levelsByKey[product.product_id])
          : true;
        return hasCode && hasName && hasChange && hasQuantity && hasNoQuantity;
      }),
    [
      products,
      changesByKey,
      levelsByKey,
      codeFilter,
      nameFilter,
      showChangesOnly,
      showHasQuantityOnly,
      showHasNoQuantityOnly,
    ]
  );

  return (
    <Grid item sm={12}>
      <CardSection>
        <Grid container spacing={2}>
          <FormTextField
            grid={3}
            label="searchByCode"
            value={codeFilter}
            onChange={setCodeFilter}
          />
          <FormTextField
            grid={3}
            label="searchByName"
            value={nameFilter}
            onChange={setNameFilter}
          />
          <FormSwitch
            grid={2}
            label="showHasQuantityOnly"
            value={showHasQuantityOnly}
            onChange={setShowHasQuantityOnly}
          />
          <FormSwitch
            grid={2}
            label="showHasNoQuantityOnly"
            value={showHasNoQuantityOnly}
            onChange={setShowHasNoQuantityOnly}
          />
          <FormSwitch
            grid={2}
            label="showChangesOnly"
            value={showChangesOnly}
            onChange={setShowChangesOnly}
          />
        </Grid>
      </CardSection>
      <CardSection p={0}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs sm="auto">
            <IconButton size="small" onClick={() => setAllOpened(!allOpened)}>
              {allOpened ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Grid>
          <Grid item container sm spacing={1} alignItems="center">
            <ValueViewer value={t("code")} grid={2} />
            <ValueViewer value={t("product")} grid={4} />
            {/* <ValueViewer value={t("unit_value")} /> */}
            <ValueViewer value={t("current_qty")} grid={2} />
            <ValueViewer value={t("quantity")} grid={2} />
            {/* <ValueViewer value={t("after_qty")} /> */}
            <ValueViewer value={t("total_value")} grid={2} />
            {/* <ValueViewer value={t("notice")} grid={3} /> */}
          </Grid>
        </Grid>
      </CardSection>
      <Box minHeight="50vh">
        <AutoSizer disableWidth>
          {({ height }) => (
            <VariableSizeList
              ref={listRef}
              direction={language === "ar" ? "rtl" : "ltr"}
              height={height}
              itemCount={filteredProducts.length}
              itemSize={getItemSize}
              width="100%"
              overscanCount={5}
              useIsScrolling
              itemData={{
                products: filteredProducts,
                levelsByKey,
                changesByKey,
                onRowChange,
                toggleLineOpened,
                isOpened,
                allowNew,
              }}
            >
              {Row}
            </VariableSizeList>
          )}
        </AutoSizer>
      </Box>
    </Grid>
  );
}

// Can Duplicate a record with same data.
// on Each change * amount with 1 or -1 based on type
// if Saved Show all with nothing to change

function Row({ data, index, style, isScrolling }) {
  const {
    products,
    levelsByKey,
    changesByKey,
    onRowChange,
    toggleLineOpened,
    isOpened,
    allowNew,
  } = data;
  const product = products[index];
  const classes = useStyles();
  const levels = levelsByKey[product.product_id] || [];
  const changes = changesByKey[product.product_id] || [];

  const total_value = sumField("total_value")(levels);
  const total_quantity = sumField("quantity")(levels);
  const change_value = sumField("total_value")(changes);
  const change_qty = sumField("quantity")(changes);

  const total_value_after = total_value + change_value;

  const onTotalChange = (value) => {
    if (allowNew) {
      if (changes.length > 0) {
        onRowChange(product.product_id)(
          adjust(0, assoc("quantity", value), changes)
        );
      } else if (levels.length > 0) {
        const record = {
          ...levels[0],
          id: uuidv4(),
          quantity: value,
          name: product.name,
        };
        onRowChange(product.product_id)([record]);
      } else {
        addRecord(value);
      }
    } else {
      const max = sumField("quantity")(levels);
      let amount = Math.min(max, Number(value));
      let i = 0;
      const updates = [];
      while (i < levels.length && amount > 0) {
        const level = levels[i];
        const quantity = Math.min(amount, level.quantity);
        amount = amount - level.quantity;
        i++;
        updates.push({
          ...level,
          id: uuidv4(),
          quantity,
          name: product.name,
        });
      }
      onRowChange(product.product_id)(updates);
    }
  };

  const addRecord = (qty = 0) => {
    onRowChange(product.product_id)([
      ...changes,
      {
        id: uuidv4(),
        product_id: product.product_id,
        name: product.name,
        quantity: qty,
        value: 0,
        serial: null,
        mfg_date: null,
        exp_date: null,
      },
    ]);
  };

  const sameStock = (fst) => (snd) =>
    fst.serial === snd.serial &&
    fst.mfg_date === snd.mfg_date &&
    fst.exp_date === snd.exp_date &&
    fst.value === snd.value;

  const duplicateRecord = (level) => {
    const exists = changes.find(sameStock(level));
    if (exists) return;
    onRowChange(product.product_id)([
      ...changes,
      {
        ...level,
        id: uuidv4(),
        name: product.name,
        quantity: 0,
        total_value: 0,
      },
    ]);
  };

  const deleteRecord = (index) => {
    onRowChange(product.product_id)(remove(index, 1, changes));
  };

  const updateField = (index, field) => (value) => {
    onRowChange(product.product_id)(
      adjust(index, assoc(field, value), changes)
    );
  };

  const updateQuantity = (index) => (quantity) => {
    if (!allowNew) {
      const line = changes[index];
      const myLevels = levels.filter(sameStock(line));
      const max = sumField("quantity")(myLevels);
      updateField(index, "quantity")(Math.min(Number(quantity), Number(max)));
    } else {
      updateField(index, "quantity")(quantity);
    }
  };

  return (
    <div style={style} className={index % 2 ? classes.strip : ""}>
      {isScrolling ? (
        <p>Calculating...</p>
      ) : (
        <>
          <Grid container alignItems="center">
            <Grid item xs sm="auto">
              <IconButton
                size="small"
                onClick={() => toggleLineOpened(product.product_id)}
              >
                {isOpened(product.product_id) ? (
                  <KeyboardArrowUp />
                ) : (
                  <KeyboardArrowDown />
                )}
              </IconButton>
            </Grid>
            <Grid item container sm spacing={1} alignItems="center">
              <ValueViewer value={product.code} grid={2} />
              <ValueViewer value={product.name} grid={4} />
              <ValueViewer value={total_quantity} grid={2} />
              <FormQuantityField
                grid={2}
                size="small"
                margin="dense"
                value={change_qty}
                onChange={onTotalChange}
              />
              <ValueViewer value={Math.abs(total_value_after / 100)} grid={2} />
            </Grid>
          </Grid>
          {isOpened(product.product_id) && (
            <div>
              {levels.map((level, index) => (
                <Grid container alignItems="center" key={index}>
                  <Grid item xs sm="auto">
                    <IconButton
                      size="small"
                      onClick={() => duplicateRecord(level)}
                    >
                      <FileCopyOutlined />
                    </IconButton>
                  </Grid>
                  <Grid item container sm spacing={1} alignItems="center">
                    <FormTextField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="serial"
                      value={level.serial}
                      disabled
                    />
                    <FormDateField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="mfg_date"
                      value={level.mfg_date}
                      disabled
                    />
                    <FormDateField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="exp_date"
                      value={level.exp_date}
                      disabled
                    />
                    <FormTextField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="unit_value"
                      value={level.value / 100}
                      disabled
                    />
                    <FormTextField
                      grid={2}
                      size="small"
                      margin="dense"
                      value={level.quantity}
                      disabled
                    />
                    <ValueViewer
                      value={Math.abs(level.total_value / 100)}
                      grid={2}
                    />
                  </Grid>
                </Grid>
              ))}
              {changes.map((change, index) => (
                <Grid container alignItems="center" key={change.id}>
                  <Grid item xs sm="auto">
                    <IconButton
                      size="small"
                      onClick={() => deleteRecord(index)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                  <Grid item container sm spacing={1} alignItems="center">
                    <FormTextField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="serial"
                      value={change.serial}
                      onChange={updateField(index, "serial")}
                      disabled={!allowNew}
                    />
                    <FormDateField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="mfg_date"
                      value={change.mfg_date}
                      onChange={updateField(index, "mfg_date")}
                      disabled={!allowNew}
                    />
                    <FormDateField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="exp_date"
                      value={change.exp_date}
                      onChange={updateField(index, "exp_date")}
                      disabled={!allowNew}
                    />
                    <FormMoneyField
                      grid={2}
                      size="small"
                      margin="dense"
                      label="unit_value"
                      value={change.value}
                      onChange={updateField(index, "value")}
                      disabled={!allowNew}
                    />
                    <FormQuantityField
                      grid={2}
                      size="small"
                      margin="dense"
                      value={change.quantity}
                      onChange={updateQuantity(index)}
                    />
                    <ValueViewer value={change.total_value / 100} grid={2} />
                  </Grid>
                </Grid>
              ))}
              {allowNew && (
                <Grid container alignItems="center">
                  <Grid item xs sm="auto">
                    <IconButton size="small" onClick={() => addRecord()}>
                      <Add />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
