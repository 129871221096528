import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import { Importer } from "commons/components/Importer";

const columns = [
  {
    name: "id",
    type: "number",
    sortable: true,
    searchable: true,
  },
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "type",
    type: "translate",
    sortable: true,
    searchable: true,
    searchList: ["sales", "purchases"],
  },
  {
    name: "credit_limit",
    type: "money",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return (
    <ResourceListPage
      url="policies"
      columns={columns}
      actions={
        <>
          <Importer
            base="policy-product"
            columns={["policy_id", "product_id", "price"]}
            beforeSave={(records) =>
              records.map((rec) => ({
                ...rec,
                price: Math.floor(rec.price * 100),
              }))
            }
          />
        </>
      }
    />
  );
}
