import React, { useEffect } from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import {
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";
import { Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AccountBalanceWalletOutlined } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import { Balance } from "../customers/Controller";

const base = "suppliers";

export default function Standalone() {
  const { t } = useTranslate();
  const { current, send, model, updateModel, id } =
    useControlledResourcePage(base);
  const hasResource = Boolean(model.id);
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <PageCard>
        <ResourceToolbar
          title={base}
          current={current}
          send={send}
          onPrintRequest={onPrintRequest}
          accessGroup
          extras={
            <Tooltip title={t("balance-statement")}>
              <IconButton
                component={RouterLink}
                to={`/s/suppliers/${id}/balance-statement`}
              >
                <AccountBalanceWalletOutlined />
              </IconButton>
            </Tooltip>
          }
        />
        <LinkTabs
          show={hasResource}
          tabs={[
            {
              name: "editor",
              path: `/s/suppliers/${id}`,
              component: (
                <Editor
                  current={current}
                  send={send}
                  updateModel={updateModel}
                />
              ),
            },
            {
              name: "balance",
              path: `/s/suppliers/${id}/balance`,
              component: (
                <Balance
                  id={id}
                  policy_id={model.policy_id}
                  operations="purchases"
                  entity="suppliers"
                  payments="supplier-payments"
                  field="supplier_id"
                />
              ),
            },
          ]}
        />
      </PageCard>
    </PrintTemplate>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [policies] = useResourcesByQuery("policies", true, {
    type: "PURCHASES",
  });

  useEffect(() => {
    if (!model.policy_id) {
      const defaultPolicy = Number(
        window.localStorage.getItem("defaultSupplierPolicy")
      );
      const exists = policies.find((pol) => pol.id === defaultPolicy);
      if (defaultPolicy && exists) {
        updateModel("policy_id")(defaultPolicy);
      }
    }
  }, [policies, model.policy_id, updateModel]);

  useEffect(() => {
    if (model.policy_id) {
      window.localStorage.setItem("defaultSupplierPolicy", model.policy_id);
    }
  }, [model.policy_id]);

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        grid={4}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={4}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormSelectField
        grid={4}
        label="policy_id"
        options={policies}
        value={model.policy_id}
        onChange={updateModel("policy_id")}
        error={rules.policy_id}
      />
      <FormTextField
        grid={4}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={4}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={4}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}
