import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import PageCard from "commons/components/PageCard";
import Stack from "commons/components/Stack";
import { Typography, Grid } from "@material-ui/core";
import {
  ReportCard,
  salesReports,
  misc,
} from "commons/pages/reports/ReportsIndex";

export const entities = [
  { path: "customers", title: "customers" },
  { path: "teachers", title: "teachers" },
  { path: "employees", title: "employees" },
];

export default function ReportsIndex() {
  const { t } = useTranslate();
  return (
    <PageCard>
      <Stack>
        <Typography variant="h4">{t("reports")}</Typography>
        <Grid container spacing={2}>
          <ReportCard reports={salesReports} title={"salesReports"} />
          <ReportCard reports={entities} title={"entities"} />
          <ReportCard reports={misc} title={"misc"} />
        </Grid>
      </Stack>
    </PageCard>
  );
}
