import { Machine, assign } from "xstate";
import api from "commons/helpers/api";
import { rulesReducer } from "commons/helpers/utils";
import { omit } from "ramda";

const hasOnlyID = ({ model }) =>
  Boolean(model.id) && Object.keys(model).length === 1;

const isAutoSave = ({ autosave }) => autosave;

const isValidationError = (error) => {
  console.log(error);
  return error.code === 422 && Array.isArray(error.data);
};

// assigners
const update = assign({
  model: (context, event) => {
    // console.log(event.data);
    return { ...context.model, ...event.data };
  },
});
const reset = assign({
  model: ({ baseModel }, event) => {
    // console.log(baseModel, event);
    return omit(["id"], {
      ...(baseModel instanceof Function ? baseModel() : baseModel),
      ...event.data,
    });
  },
});
const override = assign({
  model: (context, event) => {
    // USED in HISTORY, and ACCOUNT EDIT SENDING ONLY ID
    return event.data;
    // return { ...context.model, ...event.data };
  },
});
const success = assign({
  model: (_, event) => {
    return event.data;
  },
  rules: {},
  error: null,
});
const ResetSuccess = assign({
  model: ({ baseModel }, event) => {
    return baseModel instanceof Function ? baseModel() : baseModel;
  },
  rules: {},
  error: null,
});
const onError = assign({
  error: (_, { data: error }) => {
    console.log(error);
    return isValidationError(error) ? null : error;
  },
  rules: (_, { data: error }) =>
    isValidationError(error) ? error.data.reduce(rulesReducer, {}) : {},
});

// invokes
const invokeSave = ({ url, model }) =>
  Boolean(model.id)
    ? api.service(url).update(model.id, model)
    : api.service(url).create(model);
const invokeFetch = ({ url, model }) => api.service(url).get(model.id);

export const createResourceMachine = (
  url,
  model,
  autosave = true,
  baseModel
) => {
  return Machine({
    id: url,
    context: {
      autosave: autosave,
      url: url,
      model: model instanceof Function ? model() : model,
      baseModel: baseModel instanceof Function ? baseModel() : baseModel,
      rules: {},
      error: null,
    },
    initial: "start",
    states: {
      start: {
        id: "start",
        always: [{ target: "fetching", cond: hasOnlyID }, { target: "idle" }],
      },
      idle: {
        id: "idle",
        on: {
          UPDATE: {
            target: "#dirty",
            actions: update,
          },
        },
      },
      invalid: {
        id: "invalid",
        on: {
          UPDATE: {
            target: "#dirty",
            actions: update,
          },
        },
      },
      dirty: {
        id: "dirty",
        on: {
          SAVE: "saving",
          SAVEnRESET: "SaveAndReset",
          UPDATE: {
            target: "#dirty",
            actions: update,
          },
        },
        after: {
          20000: { target: "saving", cond: isAutoSave },
        },
      },
      saving: {
        invoke: {
          id: "saving",
          src: invokeSave,
          onDone: {
            target: "#idle",
            actions: success,
          },
          onError: {
            target: "#invalid",
            actions: onError,
          },
        },
      },
      SaveAndReset: {
        invoke: {
          id: "saveandreset",
          src: invokeSave,
          onDone: {
            target: "#idle",
            actions: ResetSuccess,
          },
          onError: {
            target: "#invalid",
            actions: onError,
          },
        },
      },
      fetching: {
        invoke: {
          id: "fetching",
          src: invokeFetch,
          onDone: {
            target: "#idle",
            actions: success,
          },
          onError: {
            target: "#idle",
            actions: onError,
          },
        },
      },
      localFetch: {},
    },
    on: {
      RESET: {
        target: ".start",
        actions: reset,
      },
      OVERRIDE: {
        target: ".start",
        actions: override,
      },
    },
  });
};
