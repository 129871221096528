import React from "react";
import PrescriptionBody from "commons/components/PrescriptionBody";

export default function Prescription({ item, onChange }) {
  return (
    <PrescriptionBody
      label={item.label}
      value={item.value}
      onChange={(e) => onChange({ ...item, value: e })}
    />
  );
}
