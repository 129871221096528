import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";

const base = "service-locations";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);
  const [facilities] = useResourcesByQuery("facilities", true);

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        grid={6}
        options={facilities}
        label="facility_id"
        value={model.facility_id}
        onChange={updateModel("facility_id")}
        error={rules.facility_id}
      />
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
    </ResourceSinglePage>
  );
}
