import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import FormSwitch from "commons/components/FormSwitch";
import { FormDateTimeField } from "commons/components/FormDateField";
import {
  FacilityManager,
  LocationManager,
} from "commons/pages/services/Editor";

export default function Editor({ current, updateModel }) {
  const { model, rules } = current.context;
  const [categories] = useResourcesByQuery("categories", true);

  return (
    <>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="code"
        value={model.code}
        onChange={updateModel("code")}
        error={rules.code}
      />
      <FormMoneyField
        grid={3}
        label="sale_price"
        value={model.sale_price}
        onChange={updateModel("sale_price")}
        error={rules.sale_price}
      />
      <FormSelectField
        grid={3}
        options={categories}
        label="category_id"
        value={model.category_id}
        onChange={updateModel("category_id")}
        error={rules.category_id}
      />
      <FormDateTimeField
        grid={3}
        label="duration_start"
        value={model.duration_start}
        onChange={updateModel("duration_start")}
        error={rules.duration_start}
      />
      <FormDateTimeField
        grid={3}
        label="duration_end"
        value={model.duration_end}
        onChange={updateModel("duration_end")}
        error={rules.duration_end}
      />
      <FormTextField
        multiline
        label="description"
        value={model.description}
        onChange={updateModel("description")}
      />
      <FormSwitch
        label="active"
        value={model.active}
        onChange={updateModel("active")}
      />
      <FacilityManager
        value={model.facilities}
        onChange={updateModel("facilities")}
      />
      <LocationManager
        value={model.locations}
        onChange={updateModel("locations")}
      />
    </>
  );
}
