import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceRelations from "commons/components/ResourceRelations";
import FormSelectField from "commons/components/FormSelectField";

const base = "access-groups";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <UserManager value={model.users} onChange={updateModel("users")} />
    </ResourceSinglePage>
  );
}

function UserManager({ value = [], onChange }) {
  const [users] = useResourcesByQuery("users", true);
  const [roles] = useResourcesByQuery("roles", true);

  return (
    <ResourceRelations
      title="users"
      value={value}
      onChange={onChange}
      model={{
        user_id: null,
        role_id: null
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            label="user"
            grid={6}
            options={users}
            optionLabel="username"
            value={record.user_id}
            onChange={onItemChange("user_id")}
          />
          <FormSelectField
            grid={6}
            label="role"
            options={roles}
            value={record.role_id}
            onChange={onItemChange("role_id")}
          />
        </>
      )}
    />
  );
}
