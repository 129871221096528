import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { Grid } from "@material-ui/core";
import FormFileField from "commons/components/FormFileField";
import FormTextField from "commons/components/FormTextField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import FormSwitch from "commons/components/FormSwitch";
import useTranslate from "commons/hooks/useTranslate";
import ResourceRelations from "commons/components/ResourceRelations";
import { FormNumberField } from "commons/components/FormNumberField";
import usePermissions from "commons/hooks/usePermissions";

export default function Editor({ current, send, updateModel }) {
  const { model, rules } = current.context;
  const { t } = useTranslate();
  const [categories] = useResourcesByQuery("categories", true);
  const { settings } = usePermissions();
  const liteMode = settings["liteMode"];

  return (
    <>
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="image"
          value={model.image}
          onChange={updateModel("image")}
          error={rules.image}
          preview
        />
        <FormSwitch
          label="saleable"
          value={model.saleable}
          onChange={updateModel("saleable")}
        />
        <FormSwitch
          label="purchasable"
          value={model.purchasable}
          onChange={updateModel("purchasable")}
        />
        <FormSwitch
          label="stockable"
          value={model.stockable}
          onChange={updateModel("stockable")}
        />
        <FormSwitch
          label="active"
          value={model.active}
          onChange={updateModel("active")}
        />
        {model.stockable && (
          <FormTextField
            label="storage_tags"
            value={model.storage_tags}
            onChange={updateModel("storage_tags")}
            error={rules.storage_tags}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={9}>
        <Grid container spacing={2}>
          <FormTextField
            grid={liteMode ? 9 : 6}
            label="name"
            value={model.name}
            onChange={updateModel("name")}
            error={rules.name}
          />
          {!liteMode && (
            <FormTextField
              grid={3}
              label="code"
              value={model.code}
              onChange={updateModel("code")}
              error={rules.code}
            />
          )}
          <FormTextField
            grid={3}
            label="supplier_code"
            value={model.supplier_code}
            onChange={updateModel("supplier_code")}
            error={rules.supplier_code}
          />
          <FormMoneyField
            grid={2}
            label="sale_price"
            value={model.sale_price}
            onChange={updateModel("sale_price")}
            error={rules.sale_price}
          />
          <FormMoneyField
            grid={2}
            label="purchase_price"
            value={model.purchase_price}
            onChange={updateModel("purchase_price")}
            error={rules.purchase_price}
          />
          <FormMoneyField
            grid={2}
            label="cost_price"
            value={model.cost_price}
            onChange={updateModel("cost_price")}
            error={rules.cost_price}
          />
          <FormSelectField
            grid={3}
            options={categories}
            label="category_id"
            value={model.category_id}
            onChange={updateModel("category_id")}
            error={rules.category_id}
          />
          <FormSelectField
            grid={3}
            options={[
              { id: "PIECE", name: t("PIECE") },
              { id: "WEIGHT", name: t("WEIGHT") },
              { id: "AREA", name: t("AREA") },
              { id: "VOLUME", name: t("VOLUME") },
            ]}
            label="type"
            value={model.type}
            onChange={updateModel("type")}
            error={rules.type}
          />
          <FormTextField
            multiline
            label="description"
            value={model.description}
            onChange={updateModel("description")}
          />
          <FacilityManager
            value={model.facilities}
            onChange={updateModel("facilities")}
          />
          <PolicyManager
            value={model.policies}
            onChange={updateModel("policies")}
          />
          {/* <VariantManager
            product={model.id}
            value={model.variants}
            onChange={updateModel("variants")}
          /> */}
          {!liteMode && (
            <ComponentManager
              product={model.id}
              value={model.components}
              onChange={updateModel("components")}
              ratio_label="product_ratio"
              rules={rules}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

function FacilityManager({ value, onChange }) {
  const [facilities] = useResourcesByQuery("facilities", true);

  return (
    <ResourceRelations
      title="facilities"
      value={value}
      onChange={onChange}
      model={{
        facility_id: null,
        reorder_level: 0,
        max_level: 0,
        min_level: 0,
        max_in_sale: 0,
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={4}
            options={facilities}
            label="facility_id"
            value={record.facility_id}
            onChange={onItemChange("facility_id")}
          />
          <FormNumberField
            grid={2}
            label="reorder_level"
            value={record.reorder_level}
            onChange={onItemChange("reorder_level")}
          />
          <FormNumberField
            grid={2}
            label="max_level"
            value={record.max_level}
            onChange={onItemChange("max_level")}
          />
          <FormNumberField
            grid={2}
            label="min_level"
            value={record.min_level}
            onChange={onItemChange("min_level")}
          />
          <FormNumberField
            grid={2}
            label="max_in_sale"
            value={record.max_in_sale}
            onChange={onItemChange("max_in_sale")}
          />
        </>
      )}
    />
  );
}

function PolicyManager({ value, onChange }) {
  const [policies] = useResourcesByQuery("policies", true);

  return (
    <ResourceRelations
      title="policies"
      value={value}
      onChange={onChange}
      model={{
        policy_id: null,
        price: 0,
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={6}
            options={policies}
            label="policy_id"
            value={record.policy_id}
            onChange={onItemChange("policy_id")}
          />
          <FormMoneyField
            grid={6}
            label="price"
            value={record.price}
            onChange={onItemChange("price")}
          />
        </>
      )}
    />
  );
}

// function VariantManager({ product, value, onChange }) {
//   const [products] = useResourcesByQuery("products", true, {
//     "products.id": {
//       $ne: product,
//     },
//   });

//   return (
//     <ResourceRelations
//       title="variants"
//       value={value}
//       onChange={onChange}
//       model={{
//         variant_id: null,
//         product_ratio: 1,
//         variant_ratio: 1,
//       }}
//       rowRenderer={(record, onItemChange) => (
//         <>
//           <FormSelectField
//             grid={6}
//             options={products}
//             label="variant"
//             value={record.variant_id}
//             onChange={onItemChange("variant_id")}
//           />
//           <FormNumberField
//             grid={3}
//             label="product_ratio"
//             value={record.product_ratio}
//             onChange={onItemChange("product_ratio")}
//           />
//           <FormNumberField
//             grid={3}
//             label="variant_ratio"
//             value={record.variant_ratio}
//             onChange={onItemChange("variant_ratio")}
//           />
//         </>
//       )}
//     />
//   );
// }

const keepRatioAtOne = (field, record, value) => {
  const other = field === "product_ratio" ? "component_ratio" : "product_ratio";
  if (Number(value) !== 1 && record[other] !== 1) {
    return {
      [field]: Number(value),
      [other]: 1,
    };
  }
  return {
    [field]: Number(value),
    [other]: record[other],
  };
};

export function ComponentManager({
  rules,
  ratio_label,
  product,
  value,
  onChange,
}) {
  const [products] = useResourcesByQuery("products", true, {
    "products.id": { $ne: product },
    "products.type": { $in: ["PIECE", "WEIGHT", "VOLUME", "AREA"] },
  });
  return (
    <ResourceRelations
      title="components"
      value={value}
      onChange={onChange}
      model={{
        component_id: null,
        product_ratio: 1,
        component_ratio: 1,
      }}
      rowRenderer={(record, onItemChange, onItemMerge, index) => (
        <>
          <FormSelectField
            grid={6}
            options={products}
            label="component"
            value={record.component_id}
            onChange={onItemChange("component_id")}
            error={rules[`components.${index}.component_id`]}
          />
          <FormNumberField
            grid={3}
            label={ratio_label}
            value={record.product_ratio}
            onChange={(value) => {
              onItemMerge(keepRatioAtOne("product_ratio", record, value));
            }}
            error={rules[`components.${index}.product_ratio`]}
          />
          <FormNumberField
            grid={3}
            label="component_ratio"
            value={record.component_ratio}
            onChange={(value) => {
              onItemMerge(keepRatioAtOne("component_ratio", record, value));
            }}
            error={rules[`components.${index}.component_ratio`]}
          />
        </>
      )}
    />
  );
}
