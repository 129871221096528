import React from "react";
import { Radio, FormControlLabel } from "@material-ui/core";
// import Radio from "/commons/components/Radio";

export default function SingleChoose({ item, onChange }) {
  return (
    <div>
      <h3>{item.label}</h3>
      <div style={{ display: "flex" }}>
        {item.options.map((option) => (
          <div key={option} style={{ margin: "0.5em 0" }}>
            <FormControlLabel
              control={<Radio color="secondary" />}
              label={option}
              value={option}
              labelPlacement="end"
              checked={item.value === option}
              onChange={(e) => onChange({ ...item, value: e.target.value })}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
