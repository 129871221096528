import React from "react";
import { TextField } from "@material-ui/core";

export default function Textarea({ item, onChange }) {
  return (
    <TextField
      multiline
      label={item.label}
      value={item.value}
      onChange={(e) => onChange({ ...item, value: e.target.value })}
    />
  );
}
