import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, IconButton, Toolbar, Typography } from "@material-ui/core";
import { Add, Print, Save } from "@material-ui/icons";
import CardSection from "commons/components/CardSection";
import { FormNumberField } from "commons/components/FormNumberField";
import FormSelectField from "commons/components/FormSelectField";
import PageCard from "commons/components/PageCard";
import Stack from "commons/components/Stack";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import useTranslate from "commons/hooks/useTranslate";
import { adjust, assoc, mergeLeft } from "ramda";
import JsBarcode from "jsbarcode";
import { useReactToPrint } from "react-to-print";
import FormSwitch from "commons/components/FormSwitch";
import FormTextField from "commons/components/FormTextField";
import useProductDiscounts from "commons/hooks/useProductDiscounts";
import { calcDiscount } from "../sales2/utils/calculators";

const getRecord = () => ({
  id: Date.now(),
  product_id: null,
  code: null,
  price: 0,
  name: "",
  count: 1,
});

const settingTemplate = {
  title: "",
  width: 80,
  height: 40,
  barcodeWidth: 2,
  barcodeHeight: 100,
  fontSize: 20,
  showCode: true,
  showName: false,
  showPrice: false,
  showPriceAfterDiscount: false,
  seperateLines: false,
  marginTop: 1,
  marginBottom: 1,
  marginLeft: 1,
  marginRight: 1,
};

export default function BarcodePrinting() {
  const { t } = useTranslate();
  const [options] = useResourcesByQuery("products", true);
  const [products, setProducts] = useState([getRecord()]);
  const { productDiscounts } = useProductDiscounts();
  const baseSettings = () =>
    JSON.parse(window.localStorage.getItem("barcodeSettingsList")) || [
      {
        ...settingTemplate,
      },
    ];
  const [settings, setSettings] = useState(baseSettings);
  const [template, setTemplate] = useState(0);
  const printContainerRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printContainerRef.current,
  });

  const updateProductId = (index) => (id) => {
    const prod = options.find((p) => p.id === id);
    if (prod) {
      const price = prod.sale_price;
      const discountValue =
        id in productDiscounts
          ? calcDiscount(price, 1, productDiscounts[id])
          : 0;
      const priceAfterDiscount = price - discountValue;
      setProducts(
        adjust(
          index,
          mergeLeft({
            product_id: id,
            code: prod.code,
            name: prod.name,
            price,
            priceAfterDiscount,
          }),
          products
        )
      );
    }
  };

  const updateProductCount = (index) => (count) => {
    setProducts(adjust(index, assoc("count", Number(count)), products));
  };

  const addNewSetting = () => {
    const i = settings.length;
    setSettings((old) => [...old, { ...settingTemplate }]);
    setTemplate(i);
  };

  const updateSettings = (index) => (field) => (value) => {
    setSettings(adjust(index, assoc(field, value)));
  };

  const saveSettings = () =>
    window.localStorage.setItem(
      "barcodeSettingsList",
      JSON.stringify(settings)
    );

  return (
    <PageCard>
      <Stack>
        <Toolbar disableGutters>
          <Typography style={{ flex: "1 1 100%" }} variant="h4">
            {t("barcode-printing")}
          </Typography>
          <IconButton onClick={handlePrint}>
            <Print />
          </IconButton>
          <IconButton onClick={saveSettings}>
            <Save />
          </IconButton>
        </Toolbar>
        <Grid container spacing={2}>
          <Grid item xs sm={6}>
            <CardSection>
              <h2 style={{ marginTop: 0 }}>{t("products")}</h2>
              {products.map((product, i) => (
                <Grid container spacing={2} key={product.id}>
                  <FormSelectField
                    grid={3}
                    label="code"
                    value={product.product_id}
                    options={options}
                    optionLabel="code"
                    onChange={updateProductId(i)}
                  />
                  <FormSelectField
                    grid={6}
                    label="name"
                    value={product.product_id}
                    options={options}
                    onChange={updateProductId(i)}
                  />
                  <FormNumberField
                    grid={3}
                    label="count"
                    value={product.count}
                    options={options}
                    onChange={updateProductCount(i)}
                  />
                </Grid>
              ))}
              <IconButton
                onClick={() => setProducts((old) => [...old, getRecord()])}
              >
                <Add />
              </IconButton>
            </CardSection>
          </Grid>
          <Grid item xs sm={6}>
            <CardSection>
              <div style={{ display: "flex" }}>
                <h2 style={{ flex: "1 1 100%", marginTop: 0 }}>
                  {t("settings")}
                </h2>
                <IconButton onClick={addNewSetting}>
                  <Add />
                </IconButton>
              </div>
              <Stack>
                <Grid container spacing={2}>
                  <FormSelectField
                    grid={4}
                    label="size"
                    value={template}
                    options={settings.map((setting, index) => ({
                      id: index,
                      name: `${setting.width}mm*${setting.height}mm`,
                    }))}
                    onChange={setTemplate}
                  />
                  <FormNumberField
                    grid={2}
                    label="width"
                    value={settings[template].width}
                    onChange={updateSettings(template)("width")}
                  />
                  <FormNumberField
                    grid={2}
                    label="height"
                    value={settings[template].height}
                    onChange={updateSettings(template)("height")}
                  />
                  <FormNumberField
                    grid={4}
                    label="fontSize"
                    value={settings[template].fontSize}
                    onChange={updateSettings(template)("fontSize")}
                  />
                  <FormNumberField
                    grid={2}
                    label="width"
                    value={settings[template].barcodeWidth}
                    onChange={updateSettings(template)("barcodeWidth")}
                  />
                  <FormNumberField
                    grid={2}
                    label="height"
                    value={settings[template].barcodeHeight}
                    onChange={updateSettings(template)("barcodeHeight")}
                  />
                  <FormNumberField
                    grid={2}
                    label="top"
                    value={settings[template].marginTop}
                    onChange={updateSettings(template)("marginTop")}
                  />
                  <FormNumberField
                    grid={2}
                    label="bottom"
                    value={settings[template].marginBottom}
                    onChange={updateSettings(template)("marginBottom")}
                  />
                  <FormNumberField
                    grid={2}
                    label="left"
                    value={settings[template].marginLeft}
                    onChange={updateSettings(template)("marginLeft")}
                  />
                  <FormNumberField
                    grid={2}
                    label="right"
                    value={settings[template].marginRight}
                    onChange={updateSettings(template)("marginRight")}
                  />
                  <FormTextField
                    grid={12}
                    label="title"
                    value={settings[template].title}
                    onChange={updateSettings(template)("title")}
                  />
                  <FormSwitch
                    label="name"
                    grid={4}
                    value={settings[template].showName}
                    onChange={updateSettings(template)("showName")}
                  />
                  <FormSwitch
                    label="code"
                    grid={4}
                    value={settings[template].showCode}
                    onChange={updateSettings(template)("showCode")}
                  />
                  <FormSwitch
                    label="price"
                    grid={4}
                    value={settings[template].showPrice}
                    onChange={updateSettings(template)("showPrice")}
                  />
                  <FormSwitch
                    label="priceAfterDiscount"
                    grid={4}
                    value={settings[template].showPriceAfterDiscount}
                    onChange={updateSettings(template)(
                      "showPriceAfterDiscount"
                    )}
                  />
                  <FormSwitch
                    label="seperateLines"
                    grid={4}
                    value={settings[template].seperateLines}
                    onChange={updateSettings(template)("seperateLines")}
                  />
                </Grid>
                <Box display="flex" flexWrap="wrap" ref={printContainerRef}>
                  {products
                    .filter((prod) => prod.code !== null)
                    .flatMap((prod) =>
                      Array(prod.count)
                        .fill()
                        .map((elem, i) => {
                          const isSeparate = settings[template].seperateLines;
                          return (
                            <Box
                              key={`${prod.id}-${i}`}
                              width={`${settings[template].width}mm`}
                              height={`${settings[template].height}mm`}
                              bgcolor="white"
                              marginTop={`${settings[template].marginTop}mm`}
                              marginBottom={`${settings[template].marginBottom}mm`}
                              marginLeft={`${settings[template].marginLeft}mm`}
                              marginRight={`${settings[template].marginRight}mm`}
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <p
                                style={{
                                  fontSize: `${settings[template].fontSize}px`,
                                  margin: 0,
                                }}
                              >
                                <strong>
                                  {settings[template].title &&
                                    settings[template].title}
                                </strong>
                              </p>
                              <Box
                                display={isSeparate ? "block" : "flex"}
                                justifyContent="center"
                                textAlign="center"
                                width="100%"
                              >
                                <p
                                  style={{
                                    fontSize: `${settings[template].fontSize}px`,
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                  }}
                                >
                                  {settings[template].showName && prod.name}
                                </p>
                                <p
                                  style={{
                                    fontSize: `${settings[template].fontSize}px`,
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {settings[template].showPrice &&
                                    `${isSeparate ? t("price") : ""} - ${(
                                      prod.price / 100
                                    ).toFixed(2)}`}
                                </p>
                                <p
                                  style={{
                                    fontSize: `${settings[template].fontSize}px`,
                                    margin: 0,
                                    whiteSpace: "nowrap",
                                  }}
                                >
                                  {settings[template].showPriceAfterDiscount &&
                                    `${
                                      isSeparate ? t("discount_price") : ""
                                    } - ${(
                                      prod.priceAfterDiscount / 100
                                    ).toFixed(2)}`}
                                </p>
                              </Box>
                              <BarcodeViewer
                                width={settings[template].barcodeWidth}
                                height={settings[template].barcodeHeight}
                                fontSize={settings[template].fontSize}
                                value={prod.code}
                                showCode={settings[template].showCode}
                              />
                            </Box>
                          );
                        })
                    )}
                </Box>
              </Stack>
            </CardSection>
          </Grid>
        </Grid>
      </Stack>
    </PageCard>
  );
}

function BarcodeViewer({ value, showCode, height, width, fontSize }) {
  const ref = useRef(null);

  useEffect(() => {
    const validValue =
      value.length >= 12 ? value : "20" + value.padStart(5, "0") + "00000";
    JsBarcode(ref.current, validValue, {
      format: "EAN13",
      displayValue: showCode,
      width: width,
      height: height,
      fontSize: fontSize,
      flat: true,
    });
  }, [ref, value, width, height, fontSize, showCode]);

  return <svg ref={ref}></svg>;
}
