import React from "react";
import ReportManager from "commons/components/ReportManager";

const columns = [
  {
    name: "type",
    type: "translate",
  },
  {
    name: "count",
    type: "number",
  },
];

export default function OverviewReport() {
  return (
    <ReportManager url="overview" title="overviewReport" columns={columns} />
  );
}
