import React, { useState, useEffect, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import { SimpleResourceToolbar } from "../attendances/Manager";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";
import {
  Grid,
  makeStyles,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  Box,
} from "@material-ui/core";
import FormSelectField from "commons/components/FormSelectField";
import { eqProps, uniqWith, prop, update } from "ramda";
import Stack from "commons/components/Stack";
import useTranslate from "commons/hooks/useTranslate";
import dayjs from "dayjs";
import { FormNumberField } from "commons/components/FormNumberField";
import api from "commons/helpers/api";
import { SaveButton } from "../book-deliveries/Manager";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function Manager() {
  const { t } = useTranslate();
  const [school, setSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();
  const {
    schools,
    students,
    uniqCourses,
    studentsByKey,
    sessions,
    attendances,
  } = useSchoolCourseManger(school, course);
  const [evaluations, sendEvaluations] = useResourcesByQuery(
    "course-evaluations"
  );
  const [sessionGrades, setSessionGrades] = useState([]);
  const [evaluationChanges, setEvaluationChanges] = useState([]);

  useEffect(() => {
    if (course) {
      sendEvaluations("SET_QUERY", {
        query: {
          product_id: course,
        },
      });
    }
  }, [course, sendEvaluations]);

  useEffect(() => {
    setSessionGrades([]);
    setEvaluationChanges([]);
  }, [course]);

  const getAttendance = (student_id, session_id) =>
    allScores.find(
      (rec) =>
        rec.session_id === session_id &&
        rec.customer_id === student_id &&
        rec.status === "PRESENT"
    );

  const onChangeGrade = (attendance) => {
    const index = sessionGrades.findIndex((rec) => rec.id === attendance.id);
    if (index > -1) {
      setSessionGrades(update(index, attendance, sessionGrades));
    } else {
      setSessionGrades((old) => [...old, attendance]);
    }
  };

  const onChangeEvaluation = (evaluation) => {
    const index = evaluationChanges.findIndex(
      (rec) => rec.customer_id === evaluation.customer_id
    );
    if (index > -1) {
      setEvaluationChanges(update(index, evaluation, evaluationChanges));
    } else {
      console.log("evaluation", evaluation);
      setEvaluationChanges((old) => [...old, evaluation]);
    }
  };

  const allScores = useMemo(() => {
    return uniqWith(eqProps("id"), [...sessionGrades, ...attendances]);
  }, [attendances, sessionGrades]);

  const allEvaluations = useMemo(() => {
    return uniqWith(eqProps("customer_id"), [
      ...evaluationChanges,
      ...evaluations,
    ]);
  }, [evaluations, evaluationChanges]);

  const onSave = () => {
    setLoading(true);
    const creates = evaluationChanges.filter((rec) => !Boolean(rec.id));
    const updates = evaluationChanges.filter((rec) => Boolean(rec.id));
    const createOp = api.service("course-evaluations").create(creates);
    const updateOps = updates.map((rec) =>
      api.service("course-evaluations").update(rec.id, rec)
    );
    const sessionUpdatesOp = sessionGrades.map((rec) =>
      api.service("session-attendances").update(rec.id, rec)
    );
    Promise.all([createOp, ...updateOps, ...sessionUpdatesOp])
      .then(() => {
        setSessionGrades([]);
        setEvaluationChanges([]);
      })
      .catch(setError)
      .finally((_) => setLoading(false));
  };

  return (
    <PageCard>
      <LoadingIndicator show={loading} />
      <ErrorAlert error={error} />
      <Stack>
        <SimpleResourceToolbar label="customer-evaluations" />
        <Grid container spacing={2}>
          <FormSelectField
            grid={6}
            label="facility"
            options={schools}
            value={school}
            onChange={setSchool}
          />
          <FormSelectField
            grid={6}
            label="group"
            options={uniqCourses}
            optionKey="product_id"
            value={course}
            onChange={setCourse}
          />
        </Grid>
        {students.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead className={classes.highlight}>
                <TableRow>
                  <TableCell>{t("customer")}</TableCell>
                  {sessions.map((session) => (
                    <TableCell key={session.id}>
                      <strong>
                        {dayjs(session.starts).format("YYYY-MM-DD")}
                      </strong>
                    </TableCell>
                  ))}
                  <TableCell>
                    <strong>{t("final_grade")}</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{t("final_rate")}</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map(({ customer_id }, index) => {
                  const evaluation = allEvaluations.find(
                    (evaluation) => evaluation.customer_id === customer_id
                  ) || {
                    customer_id,
                    date: dayjs(),
                    product_id: course,
                  };

                  return (
                    <TableRow
                      key={customer_id}
                      className={index % 2 ? classes.strip : ""}
                      hover
                    >
                      <TableCell>{studentsByKey[customer_id]}</TableCell>
                      {sessions.map((session) => {
                        const attendance = getAttendance(
                          customer_id,
                          session.id
                        );
                        return (
                          <TableCell key={session.id}>
                            <Box maxWidth="200px">
                              {attendance ? (
                                <FormNumberField
                                  value={attendance.grade}
                                  onChange={(val) =>
                                    onChangeGrade({ ...attendance, grade: val })
                                  }
                                />
                              ) : (
                                ""
                              )}
                            </Box>
                          </TableCell>
                        );
                      })}
                      <TableCell>
                        <FormNumberField
                          value={evaluation.grade}
                          onChange={(val) =>
                            onChangeEvaluation({ ...evaluation, grade: val })
                          }
                        />
                      </TableCell>
                      <TableCell>
                        <FormSelectField
                          value={evaluation.rate}
                          onChange={(val) =>
                            onChangeEvaluation({ ...evaluation, rate: val })
                          }
                          options={[
                            { id: "poor", name: t("poor") },
                            { id: "good", name: t("good") },
                            { id: "verygood", name: t("verygood") },
                            { id: "excellent", name: t("excellent") },
                          ]}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      <SaveButton
        onSave={onSave}
        disabled={sessionGrades.length === 0 && evaluationChanges.length === 0}
      />
    </PageCard>
  );
}

function useSchoolCourseManger(school, course) {
  const [customers] = useResourcesByQuery("customers", true);
  const [schools] = useResourcesByQuery("facilities", true);
  const [courses, sendCourses] = useResourcesByQuery("sale-lines");
  const [sessions, sendSessions] = useResourcesByQuery("sessions");
  const [attendances, sendAttendances] = useResourcesByQuery(
    "session-attendances"
  );

  useEffect(() => {
    if (school) {
      sendCourses("SET_QUERY", {
        query: {
          "sales.facility_id": school,
          "products.type": "COURSE",
        },
      });
    }
  }, [school, sendCourses]);

  useEffect(() => {
    if (course) {
      sendSessions("SET_QUERY", {
        query: {
          product_id: course,
        },
      });
    }
  }, [course, sendSessions]);

  useEffect(() => {
    if (sessions.length > 0) {
      const ids = sessions.map(prop("id"));
      sendAttendances("SET_QUERY", {
        query: {
          session_id: {
            $in: ids,
          },
        },
      });
    }
  }, [sessions, sendAttendances]);

  const uniqCourses = useMemo(() => {
    return uniqWith(eqProps("product_id"), courses);
  }, [courses]);

  const students = useMemo(() => {
    return uniqWith(
      eqProps("customer_id"),
      courses.filter((curr) => curr.product_id === course)
    );
  }, [course, courses]);

  const studentsByKey = useMemo(() => {
    return customers.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {}
    );
  }, [customers]);

  return {
    schools,
    uniqCourses,
    students,
    studentsByKey,
    sessions,
    attendances,
  };
}
