import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "customer",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "facility",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "facilities.name",
  },
  {
    name: "date",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "status",
    type: "translate",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="enrollments" columns={columns} />;
}
