import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { Grid } from "@material-ui/core";
import FormFileField from "commons/components/FormFileField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import ResourceRelations from "commons/components/ResourceRelations";

const base = "users";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <Grid item xs sm={3}>
        <FormFileField
          label="avatar"
          value={model.avatar}
          onChange={updateModel("avatar")}
          error={rules.avatar}
          preview
        />
      </Grid>
      <Grid item xs container sm={9} spacing={2}>
        <FormTextField
          grid={12}
          label="username"
          value={model.username}
          onChange={updateModel("username")}
          error={rules.username}
        />
        <FormTextField
          grid={6}
          type="password"
          label="password"
          value={model.password}
          onChange={updateModel("password")}
          error={rules.password}
        />
        <FormTextField
          grid={6}
          label="password_confirmation"
          type="password"
          value={model.password_confirmation}
          onChange={updateModel("password_confirmation")}
        />
        <AccessGroupManager
          value={model.groups}
          onChange={updateModel("groups")}
        />
      </Grid>
    </ResourceSinglePage>
  );
}

function AccessGroupManager({ value = [], onChange }) {
  const [groups] = useResourcesByQuery("access-groups", true);
  const [roles] = useResourcesByQuery("roles", true);

  return (
    <ResourceRelations
      title="access-groups"
      value={value}
      onChange={onChange}
      model={{
        access_group_id: null,
        role_id: null,
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            label="access-group"
            grid={6}
            options={groups}
            value={record.access_group_id}
            onChange={onItemChange("access_group_id")}
          />
          <FormSelectField
            grid={6}
            label="role"
            options={roles}
            value={record.role_id}
            onChange={onItemChange("role_id")}
          />
        </>
      )}
    />
  );
}
