import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { FormMoneyField } from "commons/components/FormMoneyField";

const base = "currencies";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        grid={3}
        label="code"
        value={model.code}
        onChange={updateModel("code")}
        error={rules.code}
      />
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormMoneyField
        grid={3}
        label="rate"
        value={model.rate}
        onChange={updateModel("rate")}
        error={rules.rate}
      />
    </ResourceSinglePage>
  );
}
