import React, { useMemo } from "react";
import {
  extractCurrencyName,
  ReportViewer,
  useReportController,
  useReportQuery,
} from "commons/components/ReportManager";
import useTranslate from "commons/hooks/useTranslate";
import { groupBy, omit, prop } from "ramda";

const columns = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "operations_count",
    type: "number",
  },
  {
    name: "operations_avg",
    type: "money",
  },
  {
    name: "operations_min",
    type: "money",
  },
  {
    name: "operations_max",
    type: "money",
  },
  {
    name: "operations_total",
    type: "money",
    highlight: true,
  },
  {
    name: "payments_count",
    type: "number",
  },
  {
    name: "positive_payments",
    type: "money",
  },
  {
    name: "negative_payments",
    type: "money",
  },
  {
    name: "payments_total",
    type: "money",
    highlight: true,
  },
  {
    name: "balance",
    type: "balance",
    highlight: true,
  },
];

export default function CustomersReport({
  title = "customersReport",
  base = "customers",
  name = "customer",
  field = "customer_id",
  operationService = "sales",
  paymentService = "customer-payments",
}) {
  const { t } = useTranslate();
  const [serverQuery, query, duration, setQuery, setDuration] =
    useReportQuery();
  const [operations, operationsError, operationsIsLoading, operationsApply] =
    useReportController(operationService, duration, "date");
  const [payments, paymentsError, paymentsIsLoading, paymentsApply] =
    useReportController(paymentService, duration, "date");

  const filters = [
    {
      type: "filter",
      name: base,
      key: field,
    },
    {
      type: "filter",
      name: "currencies",
      key: "currency_id",
    },
    {
      type: "filter",
      name: "access-groups",
      key: "access_group_id",
    },
    {
      type: "select",
      name: "method",
      key: "method",
      options: [
        {
          id: "CASH",
          name: t("CASH"),
        },
        {
          id: "CREDIT_CARD",
          name: t("CREDIT_CARD"),
        },
        {
          id: "WIRE_TRANSFER",
          name: t("WIRE_TRANSFER"),
        },
        {
          id: "CHEQUE",
          name: t("CHEQUE"),
        },
      ],
    },
    {
      type: "select",
      name: "balance",
      key: "balance",
      multiple: false,
      options: [
        {
          id: "BALANCED",
          name: t("BALANCED"),
        },
        {
          id: "NEGATIVE",
          name: t("NEGATIVE_BALANCE"),
        },
        {
          id: "POSITIVE",
          name: t("POSITIVE_BALANCE"),
        },
      ],
    },
  ];

  const apply = () => {
    let operationsQuery = {
      ...serverQuery,
    };
    let paymentsQuery = {
      ...serverQuery,
    };
    if ("currency_id" in serverQuery) {
      operationsQuery[`${operationService}.currency_id`] =
        serverQuery["currency_id"];
      operationsQuery = omit(["currency_id"], operationsQuery);
    }

    if ("method" in serverQuery) {
      operationsQuery = omit(["method"], operationsQuery);
    }

    if ("access_group_id" in serverQuery) {
      paymentsQuery[`${base}.access_group_id`] = serverQuery["access_group_id"];
      paymentsQuery = omit(["access_group_id"], paymentsQuery);
      operationsQuery[`${operationService}.access_group_id`] =
        serverQuery["access_group_id"];
      operationsQuery = omit(["access_group_id"], operationsQuery);
    }
    if ("balance" in serverQuery) {
      paymentsQuery = omit(["balance"], paymentsQuery);
      operationsQuery = omit(["balance"], operationsQuery);
    }
    operationsApply(operationsQuery);
    paymentsApply(paymentsQuery);
  };

  const accSale = (acc, curr) => {
    const {
      operations_count,
      operations_min,
      operations_max,
      operations_total,
    } = acc;
    const count = operations_count + 1;
    const total = operations_total + curr.total;
    const avg = total / count;
    const balance = acc.balance - curr.total;
    return {
      ...acc,
      operations_count: count,
      operations_min: Math.min(curr.total, operations_min),
      operations_max: Math.max(curr.total, operations_max),
      operations_avg: avg,
      operations_total: total,
      balance: balance,
    };
  };

  const accPayment = (acc, curr) => {
    const {
      payments_count,
      positive_payments,
      negative_payments,
      payments_total,
    } = acc;
    const { amount_in_currency } = curr;
    const count = payments_count + 1;
    const total = payments_total + curr.amount_in_currency;
    const balance = acc.balance + curr.amount_in_currency;
    return {
      ...acc,
      payments_count: count,
      positive_payments:
        amount_in_currency > 0
          ? positive_payments + amount_in_currency
          : positive_payments,
      negative_payments:
        amount_in_currency < 0
          ? negative_payments + amount_in_currency
          : negative_payments,
      payments_total: total,
      balance: balance,
    };
  };

  const records = useMemo(() => {
    // group each by currency;
    const all = [...operations, ...payments];
    const byCurrency = Object.values(groupBy(prop("currency_id"), all));
    // combine each currency by customer adding any extras;
    // Calculate the totals splitting payments by sign
    const totals = byCurrency.map((group) => {
      return Object.values(
        group.reduce((acc, curr) => {
          const isPayment = "sale_id" in curr || "purchase_id" in curr;
          if (curr[field] in acc) {
            const rec = acc[curr[field]];
            return {
              ...acc,
              [curr[field]]: {
                ...rec,
                ...(isPayment ? accPayment(rec, curr) : accSale(rec, curr)),
              },
            };
          } else {
            return {
              ...acc,
              [curr[field]]: {
                name: curr[name],
                currency_id: curr.currency_id,
                currency: curr.currency,
                operations_count: isPayment ? 0 : 1,
                operations_min: isPayment ? 0 : curr.total,
                operations_max: isPayment ? 0 : curr.total,
                operations_avg: isPayment ? 0 : curr.total,
                operations_total: isPayment ? 0 : curr.total,
                payments_count: isPayment ? 1 : 0,
                positive_payments: isPayment
                  ? curr.amount_in_currency > 0
                    ? curr.amount_in_currency
                    : 0
                  : 0,
                negative_payments: isPayment
                  ? curr.amount_in_currency < 0
                    ? curr.amount_in_currency
                    : 0
                  : 0,
                payments_total: isPayment ? curr.amount_in_currency : 0,
                balance: isPayment ? curr.amount_in_currency : curr.total * -1,
              },
            };
          }
        }, {})
      );
    });
    const balance = serverQuery["balance"];
    const comparator =
      balance === "BALANCED"
        ? (num) => num === 0
        : balance === "NEGATIVE"
        ? (num) => num < 0
        : (num) => num > 0;

    // inline filter
    if (!balance) return totals;

    return totals
      .map((group) => {
        return group.filter((rec) => comparator(rec.balance));
      })
      .filter((group) => group.length > 0);
  }, [operations, payments, serverQuery, field, name]);

  const groupsTitles = useMemo(() => {
    return extractCurrencyName(records);
  }, [records]);

  const errors = [operationsError, paymentsError].filter((er) => Boolean(er));
  const isLoading = [operationsIsLoading, paymentsIsLoading].every((l) => l);

  return (
    <ReportViewer
      title={title}
      filterByDate
      filters={filters}
      groupsTitles={groupsTitles}
      columns={columns}
      records={records}
      error={errors.length > 0 ? errors[0] : null}
      isLoading={isLoading}
      duration={duration}
      query={query}
      setQuery={setQuery}
      setDuration={setDuration}
      totals={[
        "operations_count",
        "operations_total",
        "payments_count",
        "positive_payments",
        "negative_payments",
        "payments_total",
        "balance",
      ]}
      onApply={apply}
    />
  );
}
