import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import Editor from "./Editor";

const base = "products";
const initialModel = {
  active: true,
  stockable: false,
  purchasable: false,
  saleable: true,
  is_course: false,
  type: "ONCE",
  image: "service.png",
};

export default function Single() {
  const { current, send, updateModel } = useControlledResourcePage(
    base,
    initialModel,
    true,
    "services"
  );
  return (
    <ResourceSinglePage
      title="services"
      current={current}
      send={send}
      accessGroup
    >
      <Editor current={current} send={send} updateModel={updateModel} />
    </ResourceSinglePage>
  );
}
