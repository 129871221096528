import { TableCell, TableRow } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import React from "react";

export default function TableReceiptRow({
  model,
  tables = [],
  getItemName,
  language,
}) {
  const { t } = useTranslate();
  const getTableName = (id) => {
    const table = tables.find((t) => parseInt(t.id) === parseInt(id));
    if (table) return table.name;
    return "";
  };

  if (!model.table_id) return null;

  return (
    <TableRow>
      <TableCell colSpan="2">
        {getItemName(language, "labels", 1, t("table"))}:{" "}
        {getTableName(model.table_id)}
      </TableCell>
    </TableRow>
  );
}
