import React, { useState } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateField } from "commons/components/FormDateField";
import useTranslate from "commons/hooks/useTranslate";
import FormTextField from "commons/components/FormTextField";
import FormFileField from "commons/components/FormFileField";
import { Grid } from "@material-ui/core";

export default function Editor({
  current,
  send,
  updateModel,
  disableCustomer = false,
}) {
  const { t } = useTranslate();
  const { model, rules } = current.context;
  const [facilities] = useResourcesByQuery("facilities", true);
  const [customers] = useResourcesByQuery("customers", true);
  const [disableFacility, setDisableFacility] = useState(false);

  const updateStatus = (value) => {
    if (value === "EXPELLED" || value === "GRADUATED") {
      updateModel("facility_id")(null);
      setDisableFacility(true);
    } else {
      setDisableFacility(false);
    }
    updateModel("status")(value);
  };

  return (
    <>
      <FormSelectField
        grid={3}
        options={[
          { id: "REGISTERED", name: t("REGISTERED") },
          { id: "TRANSFERRED", name: t("TRANSFERRED") },
          { id: "EXPELLED", name: t("EXPELLED") },
          { id: "GRADUATED", name: t("GRADUATED") },
        ]}
        label="status"
        value={model.status}
        onChange={updateStatus}
        error={rules.status}
      />
      <FormSelectField
        grid={3}
        options={customers}
        label="customer"
        value={model.customer_id}
        onChange={updateModel("customer_id")}
        error={rules.customer_id}
        disabled={disableCustomer}
      />
      <FormSelectField
        grid={3}
        options={facilities}
        label="facility"
        value={model.facility_id}
        onChange={updateModel("facility_id")}
        error={rules.facility_id}
        disabled={disableFacility}
      />
      <FormDateField
        grid={3}
        label="date"
        value={model.date}
        onChange={updateModel("date")}
        error={rules.date}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
      <Grid item xs={12} sm={4}>
        <FormFileField
          label="scan"
          value={model.scan}
          onChange={updateModel("scan")}
          error={rules.scan}
          preview
        />
      </Grid>
    </>
  );
}
