import { useCallback, useEffect } from "react";
import { useInfiniteQuery, useQueryClient } from "react-query";
import api from "commons/helpers/api";

const fetchResources = ({ queryKey, pageParam = 0 }) => {
  const [key, query] = queryKey;
  return api
    .service(key)
    .find({ query: { $sort: { id: 1 }, ...query, $skip: pageParam } });
};

export default function useQueryAllResources(
  base,
  { enabled, query } = { enabled: true, query: {} }
) {
  const { data, error, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useInfiniteQuery([base, query], fetchResources, {
      enabled,
      getNextPageParam: (lastPage, pages) => {
        // covers 2 states
        // 1 the service is paginated and sends back the data in pages
        // 2 service not paginated and send all data in one page

        const count = lastPage.limit
          ? pages.reduce((acc, page) => acc + page.data.length, 0)
          : lastPage.length;
        return lastPage.limit && lastPage.limit === lastPage.data.length
          ? count
          : undefined;
      },
    });
  const client = useQueryClient();

  useEffect(() => {
    if (hasNextPage && !isFetchingNextPage) fetchNextPage();
  }, [hasNextPage, fetchNextPage, isFetchingNextPage]);

  const onDataChange = useCallback(() => {
    client.invalidateQueries(base);
  }, [client, base]);

  useEffect(() => {
    api.service(base).on("created", onDataChange);
    api.service(base).on("updated", onDataChange);
    api.service(base).on("patched", onDataChange);
    api.service(base).on("removed", onDataChange);
    return () => {
      api.service(base).removeListener("created", onDataChange);
      api.service(base).removeListener("updated", onDataChange);
      api.service(base).removeListener("patched", onDataChange);
      api.service(base).removeListener("removed", onDataChange);
    };
  }, [base, onDataChange]);

  return [
    data ? data.pages.flatMap((page) => (page.data ? page.data : page)) : [],
    hasNextPage,
    error,
  ];
}
