import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import AttendanceRow from "./AttendanceRow";

const base = "attendances";

export default function Single({
  title = "attendances",
  query,
  label = "employee",
}) {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, {}, true, title);
  const [employees] = useResourcesByQuery("employees", true, query);

  return (
    <ResourceSinglePage title={title} current={current} send={send}>
      <AttendanceRow
        label={label}
        model={model}
        rules={rules}
        employees={employees}
        updateModel={updateModel}
      />
    </ResourceSinglePage>
  );
}
