import React, { useState } from "react";
import PageCard from "commons/components/PageCard";
import Stack from "commons/components/Stack";
import useTranslate from "commons/hooks/useTranslate";
import { Typography, Toolbar, Tabs, Tab } from "@material-ui/core";
import { Inbox, Send } from "@material-ui/icons";
import ResourceListPage from "commons/components/ResourceListPage";
import CardSection from "commons/components/CardSection";

export default function Manager() {
  const { t } = useTranslate();
  const [active, setActive] = useState("inbox");

  const handleChange = (e, newValue) => {
    setActive(newValue);
  };

  return (
    <PageCard>
      <Toolbar disableGutters>
        <Typography style={{ flex: "1 1 100%" }} variant="h4">
          {t("messages")}
        </Typography>
      </Toolbar>
      <Stack>
        <Tabs
          value={active}
          onChange={handleChange}
          variant="fullWidth"
          indicatorColor="secondary"
          textColor="secondary"
        >
          <Tab value="inbox" icon={<Inbox />} label={t("inbox")} />
          <Tab value="outbox" icon={<Send />} label={t("outbox")} />
        </Tabs>
        {active === "inbox" && <InboxList />}
        {active === "outbox" && <OutboxList />}
      </Stack>
    </PageCard>
  );
}

const getColumns = (other) => [
  {
    name: "date",
    type: "datetime",
    sortable: true,
  },
  {
    name: other,
    type: "number",
    sortable: true,
    searchable: true,
  },
  {
    name: "read",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
];

const inboxColumns = getColumns("sender");
const outboxColumns = getColumns("recipient");

function InboxList() {
  return (
    <ResourceListPage
      Wrapper={CardSection}
      hideTitle
      url="messages"
      columns={inboxColumns}
      baseQuery={{ $inbox: true }}
    />
  );
}

function OutboxList() {
  return (
    <ResourceListPage
      Wrapper={CardSection}
      hideTitle
      url="messages"
      columns={outboxColumns}
      baseQuery={{ $inbox: false }}
    />
  );
}
