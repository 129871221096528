import React, { useState, useEffect, useMemo } from "react";
import CardSection from "commons/components/CardSection";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import {
  Tabs,
  Tab,
  Button,
  Box,
  ButtonGroup,
  Menu,
  MenuItem,
  ListItemText,
} from "@material-ui/core";
import { assoc, compose, groupBy, prop, __ } from "ramda";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Link as RouterLink } from "react-router-dom";

export default function TableManager({ model, onChange, tables = [] }) {
  const [active, setActive] = useState(null);
  const [sections] = useResourcesByQuery("sections", true);
  // const [tables] = useResourcesByQuery("tables", true);
  const [sales] = useResourcesByQuery("sales", true, { closed: false });

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };

  useEffect(() => {
    if (sections.length > 0 && active === null) {
      setActive(sections[0].id);
    }
  }, [active, sections]);

  const sectionTables = useMemo(
    () => tables.filter((table) => table.section_id === active),
    [tables, active]
  );

  const onTableClick = compose(onChange, assoc("table_id", __, model));

  const salesByTable = useMemo(() => {
    return groupBy(prop("table_id"), sales);
  }, [sales]);

  const getTableColor = (id) => {
    if (model.table_id === id) return "secondary";
    if (salesByTable[id]) return "primary";
    return "default";
  };

  return (
    <CardSection>
      {active && (
        <Tabs value={active} variant="fullWidth" onChange={handleChange}>
          {sections.map((section) => (
            <Tab key={section.id} label={section.name} value={section.id} />
          ))}
        </Tabs>
      )}
      <Box display="flex" flexWrap="wrap">
        {sectionTables.map((table) => (
          <Box p={1} key={table.id}>
            <TableButton
              color={getTableColor(table.id)}
              onClick={() => onTableClick(table.id)}
              name={table.name}
              sales={salesByTable[table.id]}
            />
          </Box>
        ))}
      </Box>
    </CardSection>
  );
}

function TableButton({ name, color, onClick, sales = [] }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonGroup variant="contained" color={color} disableElevation>
        <Button onClick={onClick}>{name}</Button>
        {sales.length > 0 && (
          <Button size="small" onClick={handleMenu}>
            <ArrowDropDownIcon fontSize="small" />
          </Button>
        )}
      </ButtonGroup>
      {sales.length > 0 && (
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          {sales.map((sale) => (
            <MenuItem
              component={RouterLink}
              to={`/s/sales/${sale.id}`}
              key={sale.id}
              onClick={() => {}}
            >
              <ListItemText primary={sale.reference} />
            </MenuItem>
          ))}
        </Menu>
      )}
    </>
  );
}
