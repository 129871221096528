import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "username",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "avatar",
    type: "image",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="users" columns={columns} />;
}
