import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  section: {
    background: theme.palette.background.default,
    // padding: theme.spacing(1),
  },
}));

export default function CardSection({ children, p = 2, ...props }) {
  const classes = useStyles();

  return (
    <Box p={p} width={1} className={classes.section} {...props}>
      {children}
    </Box>
  );
}
