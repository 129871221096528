import React from "react";
import { Switch, Route } from "react-router-dom";
import SoftwareLogo from "../../logo.png";
import MainDashboard from "commons/components/MainDashboard";

import Home from "commons/pages/base/Home";
import ChangePassword from "commons/pages/base/ChangePassword";
import BaseSettings from "commons/pages/base/BaseSettings";
import BarcodePrinting from "commons/pages/base/BarcodePrinting";
import LanguageList from "commons/pages/languages/List";
import LanguageSingle from "commons/pages/languages/Single";
import PrintTemplateList from "commons/pages/print-templates/List";
import PrintTemplateSingle from "commons/pages/print-templates/Single";
import Translations from "commons/pages/translations/List";
import CurrencyList from "commons/pages/currencies/List";
import CurrencySingle from "commons/pages/currencies/Single";
// import PurchaseList from "commons/pages/purchases/List";
// import PurchaseSingle from "commons/pages/purchases/Single";
import TransferList from "commons/pages/transfers/List";
import TransferSingle from "commons/pages/transfers/Single";
import SupplierList from "commons/pages/suppliers/List";
import SupplierSingle from "commons/pages/suppliers/Controller";

import ProductList from "commons/pages/products/List";
import ProductSingle from "commons/pages/products/Single";
import PolicyList from "commons/pages/policies/List";
import PolicySingle from "commons/pages/policies/Controller";
import ProjectList from "commons/pages/projects/List";
import ProjectSingle from "commons/pages/projects/Single";
import ServiceLocationList from "commons/pages/service-locations/List";
import ServiceLocationSingle from "commons/pages/service-locations/Single";

import AccountsManager from "commons/pages/accounts/AccountsManager";
import TransactionList from "commons/pages/transactions/List";
import TransactionSingle from "commons/pages/transactions/Single";
import AssetGroupList from "commons/pages/asset-groups/List";
import AssetGroupSingle from "commons/pages/asset-groups/Single";
import AssetList from "commons/pages/assets/List";
import AssetSingle from "commons/pages/assets/Single";
import DiscountList from "commons/pages/discounts/List";
import DiscountSingle from "commons/pages/discounts/Single";
import TaxList from "commons/pages/taxes/List";
import TaxSingle from "commons/pages/taxes/Single";

import ContactList from "commons/pages/contacts/List";
import ContactSingle from "commons/pages/contacts/Single";
import FormTemplateList from "commons/pages/form-templates/List";
import TemplateSingle from "commons/pages/form-templates/Single";
import FormList from "commons/pages/forms/List";
import FormSingle from "commons/pages/forms/Single";
import AttachmentList from "commons/pages/attachments/List";
import AttachmentSingle from "commons/pages/attachments/Single";
import MessageManager from "commons/pages/messages/Manager";
import MessageSingle from "commons/pages/messages/Single";
import TaskList from "commons/pages/tasks/List";
import TaskSingle from "commons/pages/tasks/Single";

import DepartmentList from "commons/pages/departments/List";
import DepartmentSingle from "commons/pages/departments/Single";
import EvaluationList from "commons/pages/evaluations/List";
import EvaluationSingle from "commons/pages/evaluations/Single";
import RewardList from "commons/pages/rewards/List";
import RewardSingle from "commons/pages/rewards/Single";
import PenaltyList from "commons/pages/penalties/List";
import PenaltySingle from "commons/pages/penalties/Single";
import AttendanceList from "commons/pages/attendances/List";
import AttendanceSingle from "commons/pages/attendances/Single";
import AttendanceByShift from "commons/pages/attendances/ManageByShift";
import SalaryList from "commons/pages/salaries/List";
import SalarySingle from "commons/pages/salaries/Single";
import ShiftList from "commons/pages/shifts/List";
import ShiftSingle from "commons/pages/shifts/Single";
import ScheduleList from "commons/pages/schedules/List";
import ScheduleSingle from "commons/pages/schedules/Single";
import GradeList from "commons/pages/grades/List";
import GradeSingle from "commons/pages/grades/Single";
import LeaveTypeList from "commons/pages/leave-types/List";
import LeaveTypeSingle from "commons/pages/leave-types/Single";
import LeaveList from "commons/pages/leaves/List";
import LeaveSingle from "commons/pages/leaves/Single";
import VacationDayList from "commons/pages/vacation-days/List";
import VacationDaySingle from "commons/pages/vacation-days/Single";

import AccessGroupList from "commons/pages/access-groups/List";
import AccessGroupSingle from "commons/pages/access-groups/Single";
import UserList from "commons/pages/users/List";
import UserSingle from "commons/pages/users/Single";
import RoleList from "commons/pages/roles/List";
import RoleSingle from "commons/pages/roles/Single";

import ReportsIndex from "../reports/ReportsIndex";
import SalesByOperation from "commons/pages/reports/sales/SalesByOperation";
import SalesByProduct from "commons/pages/reports/sales/SalesByProduct";
import SalesByCustomer from "commons/pages/reports/sales/SalesByCustomer";
import SalesByCategory from "commons/pages/reports/sales/SalesByCategory";
import SalesByUser from "commons/pages/reports/sales/SalesByUser";

import CustomersReport from "commons/pages/reports/persons/CustomersReport";

import OverviewReport from "commons/pages/reports/misc/OverviewReport";
import FormsReport from "commons/pages/reports/misc/FormsReport";
import TasksReport from "commons/pages/reports/misc/TasksReport";
import AuditReport from "commons/pages/reports/misc/AuditReport";
import OngoingServicesReport from "commons/pages/reports/misc/OngoingServices";
import EndingServicesReport from "commons/pages/reports/misc/EndingServices";
import EmployeesReport from "commons/pages/reports/persons/EmployeesReport";

import FacilityList from "../facilities/List";
import FacilitySingle from "../facilities/Controller";
import BookList from "../books/List";
import BookSingle from "../books/Single";
import ServiceList from "../services/List";
import Serviceingle from "../services/Single";
import GroupList from "../groups/List";
import GroupSingle from "../groups/Single";
import SessionList from "../sessions/List";
import SessionSingle from "../sessions/Single";
import CategoryList from "../categories/List";
import CategorySingle from "../categories/Single";
import EmployeeList from "../employees/List";
import EmployeeSingle from "../employees/Controller";
import TeacherList from "../teachers/List";
import TeacherSingle from "../teachers/Controller";
import CustomerList from "../customers/List";
import CustomerSingle from "../customers/Controller";
import EnrollmentList from "../enrollments/List";
import EnrollmentSingle from "../enrollments/Single";
import SaleList from "../sales/List";
import SaleSingle from "../sales/Single";
import CustomerPaymentList from "../customer-payments/List";
import CustomerPaymentSingle from "../customer-payments/Single";
import BookDeliveries from "../book-deliveries/Manager";
import AttendanceManager from "../attendances/Manager";
import EvaluationManager from "../customer-evaluations/Manager";

const empQuery = { permanent: true };
const empQualifiedQuery = { "employees.permanent": true };
const teacherQuery = { permanent: false };
// const teacherQualifiedQuery = { "employees.permanent": false };

export default function Dashboard({ companyName = "", logo, ...props }) {
  return (
    <MainDashboard
      SoftwareLogo={SoftwareLogo}
      companyName={companyName}
      messages
      notifications
      {...props}
    >
      <Switch>
        <Route path="/s/groups/:id" component={GroupSingle} />
        <Route path="/s/groups" component={GroupList} />
        <Route path="/s/books/:id" component={BookSingle} />
        <Route path="/s/books" component={BookList} />
        <Route path="/s/enrollments/:id" component={EnrollmentSingle} />
        <Route path="/s/enrollments" component={EnrollmentList} />
        <Route
          path="/s/customer-payments/:id"
          component={CustomerPaymentSingle}
        />
        <Route path="/s/customer-payments" component={CustomerPaymentList} />
        <Route path="/s/book-delivery" component={BookDeliveries} />
        <Route path="/s/session-attendances" component={AttendanceManager} />
        <Route path="/s/customer-evaluations" component={EvaluationManager} />

        <Route path="/s/sales/:id" component={SaleSingle} />
        <Route path="/s/sales" component={SaleList} />
        {/* <Route path="/s/purchases/:id" component={PurchaseSingle} />
        <Route path="/s/purchases" component={PurchaseList} /> */}
        <Route path="/s/transfers/:id" component={TransferSingle} />
        <Route path="/s/transfers" component={TransferList} />
        <Route path="/s/customers/:id" component={CustomerSingle} />
        <Route path="/s/customers" component={CustomerList} />
        <Route path="/s/suppliers/:id" component={SupplierSingle} />
        <Route path="/s/suppliers" component={SupplierList} />
        <Route path="/s/sessions/:id" component={SessionSingle} />
        <Route path="/s/sessions" component={SessionList} />

        <Route path="/s/products/:id" component={ProductSingle} />
        <Route path="/s/products" component={ProductList} />
        <Route path="/s/services/:id" component={Serviceingle} />
        <Route path="/s/services" component={ServiceList} />
        <Route path="/s/categories/:id" component={CategorySingle} />
        <Route path="/s/categories" component={CategoryList} />
        <Route path="/s/policies/:id" component={PolicySingle} />
        <Route path="/s/policies" component={PolicyList} />
        <Route path="/s/projects/:id" component={ProjectSingle} />
        <Route path="/s/projects" component={ProjectList} />
        <Route
          path="/s/service-locations/:id"
          component={ServiceLocationSingle}
        />
        <Route path="/s/service-locations" component={ServiceLocationList} />

        <Route path="/s/accounts" component={AccountsManager} />
        <Route path="/s/transactions/:id" component={TransactionSingle} />
        <Route path="/s/transactions" component={TransactionList} />
        <Route path="/s/asset-groups/:id" component={AssetGroupSingle} />
        <Route path="/s/asset-groups" component={AssetGroupList} />
        <Route path="/s/assets/:id" component={AssetSingle} />
        <Route path="/s/assets" component={AssetList} />
        <Route path="/s/discounts/:id" component={DiscountSingle} />
        <Route path="/s/discounts" component={DiscountList} />
        <Route path="/s/taxes/:id" component={TaxSingle} />
        <Route path="/s/taxes" component={TaxList} />

        <Route path="/s/contacts/:id" component={ContactSingle} />
        <Route path="/s/contacts" component={ContactList} />
        <Route path="/s/form-templates/:id" component={TemplateSingle} />
        <Route path="/s/form-templates" component={FormTemplateList} />
        <Route path="/s/forms/:id" component={FormSingle} />
        <Route path="/s/forms" component={FormList} />
        <Route path="/s/attachments/:id" component={AttachmentSingle} />
        <Route path="/s/attachments" component={AttachmentList} />
        <Route path="/s/messages/:id" component={MessageSingle} />
        <Route path="/s/messages" component={MessageManager} />
        <Route path="/s/tasks/:id" component={TaskSingle} />
        <Route path="/s/tasks" component={TaskList} />
        <Route path="/s/my-tasks/:id">
          <TaskSingle base="my-tasks" />
        </Route>
        <Route path="/s/my-tasks">
          <TaskList url="my-tasks" />
        </Route>

        {/* TEACHERS */}
        <Route path="/s/teachers/:id" component={TeacherSingle} />
        <Route path="/s/teachers" component={TeacherList} />
        <Route path="/s/teacher-attendances/:id">
          <AttendanceSingle
            label="teacher"
            title="teacher-attendances"
            query={teacherQuery}
          />
        </Route>
        <Route path="/s/teacher-attendances">
          <AttendanceList title="teacher-attendances" query={teacherQuery} />
        </Route>
        <Route path="/s/teacher-leaves/:id">
          <LeaveSingle
            label="teacher"
            title="teacher-leaves"
            query={teacherQuery}
          />
        </Route>
        <Route path="/s/teacher-leaves">
          <LeaveList title="teacher-leaves" query={teacherQuery} />
        </Route>
        <Route path="/s/teacher-rewards/:id">
          <RewardSingle
            label="teacher"
            title="teacher-rewards"
            query={teacherQuery}
          />
        </Route>
        <Route path="/s/teacher-rewards">
          <RewardList title="teacher-rewards" query={teacherQuery} />
        </Route>
        <Route path="/s/teacher-penalties/:id">
          <PenaltySingle
            label="teacher"
            title="teacher-penalties"
            query={teacherQuery}
          />
        </Route>
        <Route path="/s/teacher-penalties">
          <PenaltyList title="teacher-penalties" query={teacherQuery} />
        </Route>
        <Route path="/s/teacher-evaluations/:id">
          <EvaluationSingle
            label="teacher"
            title="teacher-evaluations"
            query={teacherQuery}
          />
        </Route>
        <Route path="/s/teacher-evaluations">
          <EvaluationList title="teacher-evaluations" query={teacherQuery} />
        </Route>
        <Route path="/s/teacher-salaries/:id">
          <SalarySingle
            label="teacher"
            title="teacher-salaries"
            query={teacherQuery}
          />
        </Route>
        <Route path="/s/teacher-salaries">
          <SalaryList title="teacher-salaries" query={teacherQuery} />
        </Route>

        {/* EMPLOYEES */}
        <Route path="/s/employees/:id" component={EmployeeSingle} />
        <Route path="/s/employees">
          <EmployeeList query={empQuery} />
        </Route>
        <Route path="/s/employee-attendances/manage-by-shift">
          <AttendanceByShift query={empQuery} />
        </Route>
        <Route path="/s/employee-attendances/:id">
          <AttendanceSingle
            label="employee"
            title="employee-attendances"
            query={empQuery}
          />
        </Route>
        <Route path="/s/employee-attendances">
          <AttendanceList title="employee-attendances" query={empQuery} />
        </Route>
        <Route path="/s/employee-leaves/:id">
          <LeaveSingle
            label="employee"
            title="employee-leaves"
            query={empQuery}
          />
        </Route>
        <Route path="/s/employee-leaves">
          <LeaveList title="employee-leaves" query={empQuery} />
        </Route>
        <Route path="/s/employee-rewards/:id">
          <RewardSingle
            label="employee"
            title="employee-rewards"
            query={empQuery}
          />
        </Route>
        <Route path="/s/employee-rewards">
          <RewardList title="employee-rewards" query={empQuery} />
        </Route>
        <Route path="/s/employee-penalties/:id">
          <PenaltySingle
            label="employee"
            title="employee-penalties"
            query={empQuery}
          />
        </Route>
        <Route path="/s/employee-penalties">
          <PenaltyList title="employee-penalties" query={empQuery} />
        </Route>
        <Route path="/s/employee-evaluations/:id">
          <EvaluationSingle
            label="employee"
            title="employee-evaluations"
            query={empQuery}
          />
        </Route>
        <Route path="/s/employee-evaluations">
          <EvaluationList title="employee-evaluations" query={empQuery} />
        </Route>
        <Route path="/s/employee-salaries/:id">
          <SalarySingle
            label="employee"
            title="employee-salaries"
            query={empQuery}
          />
        </Route>
        <Route path="/s/employee-salaries">
          <SalaryList title="employee-salaries" query={empQuery} />
        </Route>

        <Route path="/s/departments/:id" component={DepartmentSingle} />
        <Route path="/s/departments" component={DepartmentList} />
        <Route path="/s/shifts/:id" component={ShiftSingle} />
        <Route path="/s/shifts" component={ShiftList} />
        <Route path="/s/schedules/:id">
          <ScheduleSingle query={empQuery} />
        </Route>
        <Route path="/s/schedules">
          <ScheduleList query={empQualifiedQuery} />
        </Route>
        <Route path="/s/grades/:id" component={GradeSingle} />
        <Route path="/s/grades" component={GradeList} />
        <Route path="/s/leave-types/:id" component={LeaveTypeSingle} />
        <Route path="/s/leave-types" component={LeaveTypeList} />
        <Route path="/s/vacation-days/:id" component={VacationDaySingle} />
        <Route path="/s/vacation-days" component={VacationDayList} />

        <Route
          path="/s/reports/sales-by-operation"
          component={SalesByOperation}
        />
        <Route path="/s/reports/sales-by-product" component={SalesByProduct} />
        <Route
          path="/s/reports/sales-by-category"
          component={SalesByCategory}
        />
        <Route
          path="/s/reports/sales-by-customer"
          component={SalesByCustomer}
        />
        <Route path="/s/reports/sales-by-user" component={SalesByUser} />
        <Route path="/s/reports/customers" component={CustomersReport} />
        <Route path="/s/reports/teachers">
          <EmployeesReport title="teachersReport" permanent={false} />
        </Route>
        <Route path="/s/reports/employees" component={EmployeesReport} />

        <Route path="/s/reports/overview" component={OverviewReport} />
        <Route path="/s/reports/tasks" component={TasksReport} />
        <Route path="/s/reports/forms" component={FormsReport} />
        <Route path="/s/reports/audit" component={AuditReport} />
        <Route
          path="/s/reports/ongoing-services"
          component={OngoingServicesReport}
        />
        <Route
          path="/s/reports/ending-services"
          component={EndingServicesReport}
        />
        <Route path="/s/reports" component={ReportsIndex} />

        <Route path="/s/facilities/:id" component={FacilitySingle} />
        <Route path="/s/facilities" component={FacilityList} />
        <Route path="/s/access-groups/:id" component={AccessGroupSingle} />
        <Route path="/s/access-groups" component={AccessGroupList} />
        <Route path="/s/users/:id" component={UserSingle} />
        <Route path="/s/users" component={UserList} />
        <Route path="/s/roles/:id" component={RoleSingle} />
        <Route path="/s/roles" component={RoleList} />
        <Route path="/s/app-settings" component={BaseSettings} />
        <Route path="/s/print-templates/:id" component={PrintTemplateSingle} />
        <Route path="/s/print-templates" component={PrintTemplateList} />
        <Route path="/s/barcode-printing" component={BarcodePrinting} />
        <Route path="/s/languages/:id" component={LanguageSingle} />
        <Route path="/s/languages" component={LanguageList} />
        <Route path="/s/translations" component={Translations} />
        <Route path="/s/currencies/:id" component={CurrencySingle} />
        <Route path="/s/currencies" component={CurrencyList} />
        <Route path="/s/change-password" component={ChangePassword} />
        <Route exact path="/">
          <Home logo={logo} companyName={companyName} />
        </Route>
      </Switch>
    </MainDashboard>
  );
}
