import React from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import FormSwitch from "commons/components/FormSwitch";
import useTranslate from "commons/hooks/useTranslate";
import { FormNumberField } from "commons/components/FormNumberField";
import { FormDateTimeField } from "commons/components/FormDateField";

export default function Editor({ current, send, updateModel }) {
  const { model, rules } = current.context;
  const { t } = useTranslate();
  const [categories] = useResourcesByQuery("categories", true);

  return (
    <>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="code"
        value={model.code}
        onChange={updateModel("code")}
        error={rules.code}
      />
      <FormMoneyField
        grid={3}
        label="sale_price"
        value={model.sale_price}
        onChange={updateModel("sale_price")}
        error={rules.sale_price}
      />
      <FormSelectField
        grid={3}
        options={categories}
        label="category_id"
        value={model.category_id}
        onChange={updateModel("category_id")}
        error={rules.category_id}
      />
      <FormSelectField
        grid={3}
        options={[
          { id: "ONCE", name: t("ONCE") },
          { id: "HOURLY", name: t("HOURLY") },
          { id: "DAILY", name: t("DAILY") },
          { id: "MONTHLY", name: t("MONTHLY") },
          { id: "YEARLY", name: t("YEARLY") },
        ]}
        label="type"
        value={model.type}
        onChange={updateModel("type")}
        error={rules.type}
      />
      <FormNumberField
        grid={3}
        label="threshold"
        value={model.threshold}
        onChange={updateModel("threshold")}
        error={rules.threshold}
        disabled={model.type === "ONCE"}
      />
      <FormDateTimeField
        grid={3}
        label="duration_start"
        value={model.duration_start}
        onChange={updateModel("duration_start")}
        error={rules.duration_start}
        disabled={model.type !== "ONCE"}
      />
      <FormDateTimeField
        grid={3}
        label="duration_end"
        value={model.duration_end}
        onChange={updateModel("duration_end")}
        error={rules.duration_end}
        disabled={model.type !== "ONCE"}
      />
      <FormTextField
        multiline
        label="description"
        value={model.description}
        onChange={updateModel("description")}
      />
      <FormSwitch
        label="active"
        value={model.active}
        onChange={updateModel("active")}
      />
      {/* <LocationManager
        value={model.locations}
        onChange={updateModel("locations")}
      />
      <SessionManager
        locations={model.locations}
        value={model.sessions}
        onChange={updateModel("sessions")}
      /> */}
    </>
  );
}

// function LocationManager({ value, onChange }) {
//   const [locations] = useResourcesByQuery("service-locations", true);

//   return (
//     <ResourceRelations
//       title="service-locations"
//       value={value}
//       onChange={onChange}
//       model={{
//         service_location_id: null,
//         max_allowed: 0,
//       }}
//       rowRenderer={(record, onItemChange) => (
//         <>
//           <FormSelectField
//             grid={6}
//             options={locations}
//             label="service-location"
//             value={record.service_location_id}
//             onChange={onItemChange("service_location_id")}
//           />
//           <FormNumberField
//             grid={6}
//             label="max_allowed"
//             value={record.max_allowed}
//             onChange={onItemChange("max_allowed")}
//           />
//         </>
//       )}
//     />
//   );
// }

// function SessionManager({ locations = [], value, onChange }) {
//   const [allLocations] = useResourcesByQuery("service-locations", true);

//   const allowedLocations = useMemo(() => {
//     const ids = locations.map(prop("service_location_id"));
//     return allLocations.filter((loc) => ids.includes(loc.id));
//   }, [allLocations, locations]);

//   return (
//     <ResourceRelations
//       title="sessions"
//       value={value}
//       onChange={onChange}
//       model={{
//         service_location_id: null,
//         starts: null,
//         ends: null,
//       }}
//       rowRenderer={(record, onItemChange) => (
//         <>
//           <FormSelectField
//             grid={6}
//             options={allowedLocations}
//             label="service-location"
//             value={record.service_location_id}
//             onChange={onItemChange("service_location_id")}
//           />
//           <FormDateTimeField
//             grid={3}
//             label="starts"
//             value={record.starts}
//             onChange={onItemChange("starts")}
//           />
//           <FormDateTimeField
//             grid={3}
//             label="ends"
//             value={record.ends}
//             onChange={onItemChange("ends")}
//           />
//         </>
//       )}
//     />
//   );
// }
