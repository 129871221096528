import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "employee",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "employees.name",
  },
  {
    name: "period_start_date",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "period_end_date",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "due_date",
    type: "date",
    sortable: true,
    searchable: true,
  },
  // {
  //   name: "base_salary",
  //   type: "money",
  //   sortable: true,
  //   searchable: true,
  // },
  {
    name: "net_salary",
    type: "money",
    sortable: true,
    searchable: true,
  },
];

export default function List({ title, query }) {
  return (
    <ResourceListPage
      url="salaries"
      columns={columns}
      title={title}
      baseQuery={query}
    />
  );
}
