import React, { useMemo } from "react";
import {
  extractCurrencyName,
  ReportViewer,
  useReportController,
  useReportQuery,
} from "commons/components/ReportManager";
import useTranslate from "commons/hooks/useTranslate";
import { groupBy, omit, prop } from "ramda";

const columns = [
  {
    name: "name",
    type: "text",
  },
  {
    name: "sales_total",
    type: "money",
    highlight: true,
  },
  {
    name: "purchases_total",
    type: "money",
  },
  {
    name: "positive_payments",
    type: "money",
  },
  {
    name: "negative_payments",
    type: "money",
  },
  {
    name: "payments_total",
    type: "money",
    highlight: true,
  },
  {
    name: "balance",
    type: "balance",
    highlight: true,
  },
];

export default function EmployeesReport({
  title = "employeesReport",
  permanent = true,
}) {
  const { t } = useTranslate();
  const [serverQuery, query, duration, setQuery, setDuration] =
    useReportQuery();
  const [sales, salesError, salesIsLoading, salesApply] = useReportController(
    "employee-sale",
    duration,
    "date"
  );
  const [purchases, purchasesError, purchasesIsLoading, purchasesApply] =
    useReportController("employee-purchase", duration, "date");
  const [payments, paymentsError, paymentsIsLoading, paymentsApply] =
    useReportController("employee-payments", duration, "date");

  const filters = [
    {
      type: "filter",
      name: "employees",
      key: "employee_id",
      filters: { permanent },
      extras: {},
    },
    {
      type: "filter",
      name: "currencies",
      key: "currency_id",
    },
    {
      type: "filter",
      name: "access-groups",
      key: "access_group_id",
    },
    {
      type: "select",
      name: "method",
      key: "method",
      options: [
        {
          id: "CASH",
          name: t("CASH"),
        },
        {
          id: "CREDIT_CARD",
          name: t("CREDIT_CARD"),
        },
        {
          id: "WIRE_TRANSFER",
          name: t("WIRE_TRANSFER"),
        },
        {
          id: "CHEQUE",
          name: t("CHEQUE"),
        },
      ],
    },
    {
      type: "select",
      name: "balance",
      key: "balance",
      multiple: false,
      options: [
        {
          id: "BALANCED",
          name: t("BALANCED"),
        },
        {
          id: "NEGATIVE",
          name: t("NEGATIVE_BALANCE"),
        },
        {
          id: "POSITIVE",
          name: t("POSITIVE_BALANCE"),
        },
      ],
    },
  ];

  const records = useMemo(() => {
    const all = [...sales, ...purchases, ...payments];
    const byCurrency = Object.values(groupBy(prop("currency_id"), all));
    const byEmployee = byCurrency.map((c) =>
      Object.values(groupBy(prop("employee_id"), c))
    );
    const totals = byEmployee.map((currency) => {
      return currency.map((employee) => {
        return employee.reduce((acc, curr) => {
          const isPayment = !("sale_id" in curr) && !("purchase_id" in curr);
          const sales_total =
            (acc.sales_total || 0) +
            ("sale_id" in curr ? curr.applied_value : 0);
          const purchases_total =
            (acc.purchases_total || 0) +
            ("purchase_id" in curr ? curr.applied_value : 0);
          const payments_total =
            (acc.payments_total || 0) +
            (isPayment ? curr.amount_in_currency : 0);
          return {
            name: curr.name,
            currency: curr.currency,
            sales_total,
            purchases_total,
            positive_payments:
              (acc.positive_payments || 0) +
              (isPayment && curr.amount_in_currency > 0
                ? curr.amount_in_currency
                : 0),
            negative_payments:
              (acc.negative_payments || 0) +
              (isPayment && curr.amount_in_currency < 0
                ? curr.amount_in_currency
                : 0),
            payments_total,
            balance: sales_total + purchases_total - payments_total,
          };
        }, {});
      });
    });

    const balance = serverQuery["balance"];
    const comparator =
      balance === "BALANCED"
        ? (num) => num === 0
        : balance === "NEGATIVE"
        ? (num) => num < 0
        : (num) => num > 0;

    // inline filter
    if (!balance) return totals;

    return totals
      .map((group) => {
        return group.filter((rec) => comparator(rec.balance));
      })
      .filter((group) => group.length > 0);
  }, [sales, purchases, payments, serverQuery]);

  const apply = () => {
    let salesQuery = {
      ...serverQuery,
      permanent,
    };
    let purchasesQuery = {
      ...serverQuery,
      permanent,
    };
    let paymentsQuery = {
      ...serverQuery,
      permanent,
    };

    if ("method" in serverQuery) {
      salesQuery = omit(["method"], salesQuery);
      purchasesQuery = omit(["method"], purchasesQuery);
    }

    if ("access_group_id" in serverQuery) {
      paymentsQuery["employees.access_group_id"] =
        serverQuery["access_group_id"];
      paymentsQuery = omit(["access_group_id"], paymentsQuery);
      salesQuery["sales.access_group_id"] = serverQuery["access_group_id"];
      salesQuery = omit(["access_group_id"], salesQuery);
      purchasesQuery["purchases.access_group_id"] =
        serverQuery["access_group_id"];
      purchasesQuery = omit(["access_group_id"], purchasesQuery);
    }
    if ("balance" in serverQuery) {
      paymentsQuery = omit(["balance"], paymentsQuery);
      salesQuery = omit(["balance"], salesQuery);
      purchasesQuery = omit(["balance"], purchasesQuery);
    }
    salesApply(salesQuery);
    purchasesApply(purchasesQuery);
    paymentsApply(paymentsQuery);
  };

  const groupsTitles = useMemo(() => {
    return extractCurrencyName(records);
  }, [records]);

  const errors = [salesError, purchasesError, paymentsError].filter((er) =>
    Boolean(er)
  );
  const isLoading = [
    salesIsLoading,
    purchasesIsLoading,
    paymentsIsLoading,
  ].every((l) => l);

  return (
    <ReportViewer
      title={title}
      filterByDate
      filters={filters}
      groupsTitles={groupsTitles}
      columns={columns}
      records={records}
      error={errors.length > 0 ? errors[0] : null}
      isLoading={isLoading}
      duration={duration}
      query={query}
      setQuery={setQuery}
      setDuration={setDuration}
      totals={[
        "sales_total",
        "purchases_total",
        "positive_payments",
        "negative_payments",
        "payments_total",
        "balance",
      ]}
      onApply={apply}
    />
  );
}
