import Text from "./Text";
import Image from "./Image";
import Line from "./Line";

const ComponentsMap = {
  text: Text,
  image: Image,
  line: Line,
  date: Text,
  username: Text,
  counter: Text,
  daily: Text,
};

export { ComponentsMap, Text, Image, Line };
