import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateField } from "commons/components/FormDateField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";

const base = "leaves";

export default function Single({ query = {}, title, label }) {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, {}, true, title);
  const [employees] = useResourcesByQuery("employees", true, query);
  const [types] = useResourcesByQuery("leave-types", true);

  return (
    <ResourceSinglePage title={title} current={current} send={send}>
      <FormSelectField
        grid={4}
        options={employees}
        label={label}
        value={model.employee_id}
        onChange={updateModel("employee_id")}
        error={rules.employee_id}
      />
      <FormSelectField
        grid={4}
        options={types}
        label="leave_type_id"
        value={model.leave_type_id}
        onChange={updateModel("leave_type_id")}
        error={rules.leave_type_id}
      />
      <FormDateField
        grid={4}
        label="date"
        value={model.date}
        onChange={updateModel("date")}
        error={rules.date}
      />
    </ResourceSinglePage>
  );
}
