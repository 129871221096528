import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "leave_type",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "leave_types.name",
  },
  {
    name: "employee",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "employees.name",
  },
  {
    name: "date",
    type: "date",
    sortable: true,
    searchable: true,
  },
];

export default function List({ title, query }) {
  return (
    <ResourceListPage
      url="leaves"
      columns={columns}
      title={title}
      baseQuery={query}
    />
  );
}
