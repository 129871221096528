import React, { useEffect, useState } from "react";
import FormTextField from "./FormTextField";
import dayjs from "dayjs";
import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { Close } from "@material-ui/icons";

export function FormDateField({
  grid = 12,
  label,
  value,
  onChange,
  error,
  format = "YYYY-MM-DD",
  internalFormat = format,
  onClear,
  disabled,
  ...props
}) {
  const { t } = useTranslate();
  const [date, setDate] = useState("");
  useEffect(() => {
    if (value && dayjs(value).isValid()) {
      setDate(dayjs(value).format(format));
    }
    if (value === null || value === undefined) {
      setDate("");
    }
  }, [value, format]);

  return (
    <Grid item sm={grid} xs={12}>
      <TextField
        type="date"
        label={t(label)}
        value={date}
        onChange={(e) => setDate(e.target.value)}
        error={Boolean(error)}
        helperText={Boolean(error) && t("rules." + error)}
        onBlur={(e) => {
          const val = e.target.value;
          const formatted =
            val && dayjs(val).isValid()
              ? dayjs(val).format(internalFormat)
              : "";
          onChange(formatted);
        }}
        InputLabelProps={{
          shrink: true,
        }}
        disabled={disabled}
        InputProps={{
          ...(onClear && !disabled
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    <Box mt={2}>
                      <IconButton size="small" onClick={onClear}>
                        <Close size="small" />
                      </IconButton>
                    </Box>
                  </InputAdornment>
                ),
              }
            : {}),
        }}
        {...props}
      />
    </Grid>
    // <FormTextField
    //   type="date"
    //   value={dayjs(value).isValid() ? dayjs(value).format("YYYY-MM-DD") : value}
    //   InputLabelProps={{
    //     shrink: true,
    //   }}
    //   {...props}
    // />
  );
}

export function FormTimeField({ ...props }) {
  return (
    <FormTextField
      type="time"
      // value={value ? dayjs(value).format("YYYY-MM-DD") : ""}
      InputLabelProps={{
        shrink: true,
      }}
      {...props}
    />
  );
}

export function FormDateTimeField({ value, ...props }) {
  const showVal =
    value && dayjs(value).isValid()
      ? dayjs(value).format("YYYY-MM-DDTHH:mm")
      : "";

  return (
    <FormDateField
      value={showVal}
      type="datetime-local"
      format="YYYY-MM-DDTHH:mm"
      internalFormat="YYYY-MM-DDTHH:mm:ssZZ"
      {...props}
    />
  );
}
