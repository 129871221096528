import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import Editor from "./Editor";

const base = "taxes";

export default function Single() {
  const { current, send, updateModel } = useControlledResourcePage(base);
  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <Editor current={current} send={send} updateModel={updateModel} />
    </ResourceSinglePage>
  );
}
