import React, { useState } from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateTimeField } from "commons/components/FormDateField";
import ResourceRelations from "commons/components/ResourceRelations";
import { FormMoneyField } from "commons/components/FormMoneyField";
import { Grid, IconButton, Box, Button } from "@material-ui/core";
import { sumField } from "commons/helpers/utils";
import {
  Cancel,
  CheckCircle,
  UnfoldLess,
  UnfoldMore,
  VerifiedUser,
} from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";
import dayjs from "dayjs";
import TransactionPrinter from "./TransactionPrinter";

const base = "transactions";

export default function Single({ currency = false }) {
  const { t } = useTranslate();
  const { current, send, model, updateModel, rules } =
    useControlledResourcePage(base, { created: dayjs() }, false);
  const isSaved = Boolean(model.id);
  const isDirty = current.matches("dirty") || current.matches("invalid");

  const [accounts] = useResourcesByQuery("accounts", true, {
    "accounts.active": true,
  });
  const [currencies] = useResourcesByQuery("currencies", true);

  const onApprove = () => {
    api.service("transactions").patch(model.id, {});
  };

  return (
    <>
      <ResourceSinglePage
        title={base}
        current={current}
        send={send}
        printer={<TransactionPrinter model={model} />}
        accessGroup
      >
        <FormDateTimeField
          grid={6}
          label="created"
          value={model.created}
          onChange={updateModel("created")}
          error={rules.created}
          // InputProps={{
          //   readOnly: isSaved,
          // }}
        />
        <FormTextField
          grid={6}
          label="reference"
          value={model.reference}
          onChange={updateModel("reference")}
          error={rules.reference}
          // InputProps={{
          //   readOnly: isSaved,
          // }}
        />
        {currency ? (
          <CurrencyEntriesManager
            value={model.entries}
            onChange={updateModel("entries")}
            // isSaved={isSaved}
            currency={currency}
            accounts={accounts}
            currencies={currencies}
            isDirty={isDirty}
          />
        ) : (
          <EntriesManager
            value={model.entries}
            onChange={updateModel("entries")}
            // isSaved={isSaved}
            currency={currency}
            accounts={accounts}
            currencies={currencies}
            isDirty={isDirty}
          />
        )}
        <FormTextField
          multiline
          label="statement"
          value={model.notes}
          onChange={updateModel("notes")}
          error={rules.notes}
          // InputProps={{
          //   readOnly: isSaved,
          // }}
        />
        {isSaved && !model.approved && (
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center">
              <Button
                size="large"
                variant="contained"
                color="primary"
                startIcon={<VerifiedUser />}
                onClick={onApprove}
              >
                {t("approve")}
              </Button>
            </Box>
          </Grid>
        )}
      </ResourceSinglePage>
    </>
  );
}

const isEntryFull = (entry) =>
  entry.account_id !== null && (entry.debit > 0 || entry.credit > 0);

function EntriesManager({ value = [], onChange, accounts = [] }) {
  const [firstAddClicked, setFirstAddClicked] = useState(false);

  return (
    <ResourceRelations
      title="entries"
      value={value}
      onChange={onChange}
      isRecordFull={isEntryFull}
      autoAddOnFull={firstAddClicked}
      onItemAddClicked={() => {
        setFirstAddClicked(true);
      }}
      model={{
        account_id: null,
        debit: 0,
        credit: 0,
      }}
      // disableRemove={isSaved}
      footer={value.length > 0 ? <EntriesManagerFooter value={value} /> : null}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormSelectField
            grid={2}
            options={accounts}
            label="code"
            value={record.account_id}
            optionLabel="code"
            autoHighlight
            onChange={
              // !isSaved
              onItemChange("account_id")
              // : onItemChange(record.account_id)
            }
          />
          <FormSelectField
            grid={4}
            options={accounts}
            label="account"
            value={record.account_id}
            autoHighlight
            onChange={
              // !isSaved
              onItemChange("account_id")
              // : onItemChange(record.account_id)
            }
          />
          <FormMoneyField
            grid={3}
            label="debit"
            value={record.debit}
            onChange={onItemChange("debit")}
            disabled={Boolean(record.credit) || !record.account_id}
            // InputProps={{
            //   readOnly: isSaved,
            // }}
          />
          <FormMoneyField
            grid={3}
            label="credit"
            value={record.credit}
            onChange={onItemChange("credit")}
            disabled={Boolean(record.debit) || !record.account_id}
            // InputProps={{
            //   readOnly: isSaved,
            // }}
          />
        </>
      )}
    />
  );
}

function CurrencyEntriesManager({
  value = [],
  onChange,
  // isSaved,
  accounts = [],
  currencies = [],
  isDirty,
}) {
  return (
    <ResourceRelations
      title="entries"
      value={value}
      onChange={onChange}
      isRecordFull={isEntryFull}
      autoAddOnFull={isDirty}
      // onItemAddClicked={() => {
      //   setFirstAddClicked(true);
      // }}
      model={() => {
        const currency_id =
          window.localStorage.getItem("transactionDefaultCurrency") || null;
        const currency = currencies.find(
          (cur) => Number(cur.id) === Number(currency_id)
        );
        return {
          account_id: null,
          currency_id: currency ? currency.id : null,
          rate: currency ? currency.rate : null,
          debit_in_currency: 0,
          credit_in_currency: 0,
          debit: 0,
          credit: 0,
        };
      }}
      disableRemove={false}
      footer={
        value.length > 0 ? (
          <EntriesManagerFooter value={value} currencies={currencies} />
        ) : null
      }
      rowRenderer={(record, onItemChange, onItemMerge) => (
        <EntryRow
          record={record}
          onItemChange={onItemChange}
          onItemMerge={onItemMerge}
          accounts={accounts}
          currencies={currencies}
          // isSaved={isSaved}
        />
      )}
    />
  );
}

function EntryRow({
  record,
  onItemChange,
  onItemMerge,
  // isSaved,
  accounts = [],
  currencies = [],
}) {
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <Grid xs="auto" item>
        <IconButton
          size="small"
          color={record.note && !expanded ? "primary" : "default"}
          onClick={(_) => setExpanded((o) => !o)}
        >
          {expanded ? <UnfoldLess /> : <UnfoldMore />}
        </IconButton>
      </Grid>
      <Grid xs item>
        <Grid container spacing={2}>
          <FormSelectField
            grid={3}
            options={accounts}
            label="code"
            value={record.account_id}
            optionLabel="code"
            autoHighlight
            onChange={
              // !isSaved
              onItemChange("account_id")
              // : onItemChange(record.account_id)
            }
          />
          <FormSelectField
            grid={5}
            options={accounts}
            label="account"
            value={record.account_id}
            autoHighlight
            onChange={
              // !isSaved
              onItemChange("account_id")
              // : onItemChange(record.account_id)
            }
          />
          <FormSelectField
            grid={2}
            options={currencies}
            label="currency"
            optionLabel="code"
            value={record.currency_id}
            autoHighlight
            onChange={(value) => {
              const currency = currencies.find((cur) => cur.id === value);
              if (currency) {
                onItemMerge({
                  currency_id: currency.id,
                  rate: currency.rate,
                  debit_in_currency: 0,
                  credit_in_currency: 0,
                  debit: 0,
                  credit: 0,
                });
                window.localStorage.setItem(
                  "transactionDefaultCurrency",
                  currency.id
                );
              }
            }}
          />
          <FormMoneyField
            grid={2}
            label="rate"
            value={record.rate}
            disabled={true}
          />
        </Grid>
      </Grid>
      <Grid xs={6} item>
        <Grid container spacing={2}>
          <FormMoneyField
            grid={3}
            label="debit_in_currency"
            value={record.debit_in_currency}
            onChange={(value) => {
              // console.log("debit", value, record.rate);
              onItemMerge({
                debit_in_currency: value,
                debit: Math.floor((value * record.rate) / 100),
              });
            }}
            disabled={
              Boolean(Number(record.credit_in_currency)) ||
              !record.account_id ||
              !record.rate
            }
            // InputProps={{
            //   readOnly: isSaved,
            // }}
          />
          <FormMoneyField
            grid={3}
            label="credit_in_currency"
            value={record.credit_in_currency}
            onChange={(value) => {
              // console.log("credit", value, record.rate);
              onItemMerge({
                credit_in_currency: value,
                credit: Math.floor((value * record.rate) / 100),
              });
            }}
            disabled={
              Boolean(Number(record.debit_in_currency)) ||
              !record.account_id ||
              !record.rate
            }
            // InputProps={{
            //   readOnly: isSaved,
            // }}
          />
          <FormMoneyField
            grid={3}
            label="debit"
            value={record.debit}
            disabled={true}
            // InputProps={{
            //   readOnly: isSaved,
            // }}
          />
          <FormMoneyField
            grid={3}
            label="credit"
            value={record.credit}
            disabled={true}
            // InputProps={{
            //   readOnly: isSaved,
            // }}
          />
        </Grid>
      </Grid>
      {expanded && (
        <FormTextField
          grid={6}
          label="notes"
          value={record.note}
          onChange={(value) => {
            onItemMerge({
              note: value,
            });
          }}
        />
      )}
    </>
  );
}

function EntriesManagerFooter({ value, currencies = [] }) {
  const debit_total = sumField("debit")(value);
  const credit_total = sumField("credit")(value);
  const difference = debit_total - credit_total;

  return (
    <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item sm>
          <Grid container spacing={2}>
            <FormMoneyField
              grid={6}
              label="difference"
              value={difference}
              error={difference !== 0 ? "debit_credit_match" : false}
              InputProps={{
                readOnly: true,
              }}
            />
            <FormMoneyField
              grid={3}
              label="debit"
              value={debit_total}
              InputProps={{
                readOnly: true,
              }}
            />
            <FormMoneyField
              grid={3}
              label="credit"
              value={credit_total}
              InputProps={{
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid item xs sm="auto">
          <IconButton disabled size="small">
            {difference !== 0 ? <Cancel /> : <CheckCircle />}
          </IconButton>
        </Grid>
      </Grid>
      {currencies.map((currency) => (
        <Grid key={currency.id} container spacing={2}>
          <FormSelectField
            grid={2}
            options={currencies}
            optionLabel="code"
            value={currency.id}
            autoHighlight
            disabled
          />
          <FormMoneyField
            grid={2}
            value={(difference / currency.rate) * 100}
            disabled
          />
        </Grid>
      ))}
    </Box>
  );
}
