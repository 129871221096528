import React, { useMemo, useState, useEffect } from "react";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { Grid } from "@material-ui/core";

export default function ServiceLocationSelect({ value, onChange }) {
  const [facilities] = useResourcesByQuery("facilities", true);
  const [facility, setFacility] = useState(null);
  const [locations] = useResourcesByQuery("service-locations", true);

  const onFacilityChange = (value) => {
    setFacility(value);
    onChange(null);
  };

  const filteredLocations = useMemo(() => {
    if (facility) {
      return locations.filter((loc) => loc.facility_id === facility);
    }
    return [];
  }, [facility, locations]);

  useEffect(() => {
    if (value && facility === null) {
      const location = locations.find((loc) => loc.id === value);
      if (location) {
        setFacility(location.facility_id);
      }
    }
  }, [value, facility, locations]);

  return (
    <Grid container spacing={2}>
      <FormSelectField
        grid={6}
        options={facilities}
        label="facilities"
        value={facility}
        onChange={onFacilityChange}
      />
      <FormSelectField
        grid={6}
        options={filteredLocations}
        label="service-location"
        value={value}
        onChange={onChange}
      />
    </Grid>
  );
}
