import React from "react";
import { Snackbar } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";

export default function ErrorAlert({ error }) {
  return error ? (
    <Snackbar open>
      <Alert elevation={6} variant="filled" severity="error">
        <AlertTitle>{error.message}</AlertTitle>
      </Alert>
    </Snackbar>
  ) : null;
}
