import React, { useState, useEffect } from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { Balance } from "commons/pages/customers/Controller";
import Forms from "commons/pages/customers/Forms";
import FormFileField from "commons/components/FormFileField";
import {
  Grid,
  Box,
  Zoom,
  Fab,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
} from "@material-ui/core";
import ResourceListPage from "commons/components/ResourceListPage";
import CardSection from "commons/components/CardSection";
import { Add, Close } from "@material-ui/icons";
import useResourceMachine from "commons/hooks/useResourceMachine";
import EnrollmentEditor from "../enrollments/Editor";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import useTranslate from "commons/hooks/useTranslate";
import { prop, eqProps } from "ramda";
import { sumField } from "commons/helpers/utils";
import FormSelectField from "commons/components/FormSelectField";
import useQueryAllResources from "commons/hooks/useQueryAllResources";

const base = "customers";

export default function Standalone() {
  const { current, send, model, updateModel, id } =
    useControlledResourcePage(base);
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/customers/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "facilities",
            path: `/s/customers/${id}/facilities`,
            component: <Schools id={model.id} />,
          },
          {
            name: "groups",
            path: `/s/customers/${id}/groups`,
            component: <Groups id={model.id} />,
          },
          {
            name: "balance",
            path: `/s/customers/${id}/balance`,
            component: <Balance id={id} />,
          },
          {
            name: "forms",
            path: `/s/customers/${id}/forms`,
            component: <Forms id={id} />,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [policies] = useQueryAllResources("policies", true, { type: "SALES" });

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        grid={4}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={4}
        label="parent_name"
        value={model.parent_name}
        onChange={updateModel("parent_name")}
        error={rules.parent_name}
      />
      <FormSelectField
        grid={4}
        label="policy_id"
        options={policies}
        value={model.policy_id}
        onChange={updateModel("policy_id")}
        error={rules.policy_id}
      />
      <FormTextField
        grid={4}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormDateField
        grid={4}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormTextField
        grid={4}
        label="nid"
        value={model.nid}
        onChange={updateModel("nid")}
        error={rules.nid}
      />
      <FormTextField
        grid={4}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={4}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={4}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="dob_certificate"
          value={model.dob_certificate}
          onChange={updateModel("dob_certificate")}
          error={rules.dob_certificate}
          preview
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="personal_photo"
          value={model.personal_photo}
          onChange={updateModel("personal_photo")}
          error={rules.personal_photo}
          preview
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="father_nid_scan"
          value={model.father_nid_scan}
          onChange={updateModel("father_nid_scan")}
          error={rules.father_nid_scan}
          preview
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <FormFileField
          label="mother_nid_scan"
          value={model.mother_nid_scan}
          onChange={updateModel("mother_nid_scan")}
          error={rules.mother_nid_scan}
          preview
        />
      </Grid>
    </ResourceEditor>
  );
}

const columns = [
  {
    name: "facility",
    type: "text",
    sortable: true,
  },
  {
    name: "date",
    type: "date",
    sortable: true,
  },
  {
    name: "status",
    type: "translate",
    sortable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
  },
];

export function Schools({ id }) {
  // const [enrollments] = useResourcesByQuery("enrollments", true, {
  //   customer_id: student_id,
  // });
  const [open, setOpen] = useState(false);

  const onOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <ResourceListPage
        Wrapper={CardSection}
        hideToolbar
        url="enrollments"
        columns={columns}
        baseQuery={{ customer_id: id }}
        createButton={
          <Box position="fixed" bottom={24} right={24}>
            <Zoom in style={{ transitionDelay: "500ms" }}>
              <Fab color="primary" onClick={onOpen}>
                <Add />
              </Fab>
            </Zoom>
          </Box>
        }
      />
      <Dialog fullWidth maxWidth="lg" onClose={handleClose} open={open}>
        <DialogTitle disableTypography>
          <Box p={1}>
            <IconButton
              aria-label="close"
              style={{ position: "absolute", top: "4px", right: "4px" }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <EmbededForm id={parseInt(id)} />
        </DialogContent>
        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            <Save />
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

function EmbededForm({ id }) {
  const { current, send, updateModel } = useResourceMachine(
    "enrollments",
    "create",
    {
      customer_id: id,
    }
  );

  return (
    <ResourceEditor current={current} send={send} embeded>
      <EnrollmentEditor
        current={current}
        send={send}
        updateModel={updateModel}
        disableCustomer
      />
    </ResourceEditor>
  );
}

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

function Groups({ id }) {
  const { t } = useTranslate();
  const classes = useStyles();
  const [courses] = useResourcesByQuery("sale-lines", true, {
    "products.type": "COURSE",
    "sales.customer_id": id,
  });
  const [attendances] = useResourcesByQuery("session-attendances", true, {
    customer_id: id,
  });
  const [evaluations] = useResourcesByQuery("course-evaluations", true, {
    customer_id: id,
  });
  const [sessions, sendSessions] = useResourcesByQuery("sessions");

  useEffect(() => {
    if (courses.length > 0) {
      const ids = courses.map(prop("product_id"));
      sendSessions("SET_QUERY", {
        query: {
          product_id: {
            $in: ids,
          },
        },
      });
    }
  }, [courses, sendSessions]);

  const getCourseAnalytics = (course) => {
    const courseSessions = sessions.filter(eqProps("product_id", course));
    const sessionIds = courseSessions.map(prop("id"));
    const present = attendances.filter(
      (att) => sessionIds.includes(att.session_id) && att.status === "PRESENT"
    );
    const absent = attendances.filter(
      (att) => sessionIds.includes(att.session_id) && att.status === "ABSENT"
    );
    const execused = attendances.filter(
      (att) => sessionIds.includes(att.session_id) && att.status === "EXECUSED"
    );
    const grades = sumField("grade")(present) / courseSessions.length;

    return {
      sessions: courseSessions.length,
      present: present.length,
      present_percent: percentage(present.length, courseSessions.length),
      absent: absent.length,
      absent_percent: percentage(absent.length, courseSessions.length),
      execused: execused.length,
      execused_percent: percentage(execused.length, courseSessions.length),
      grades,
    };
  };

  const percentage = (fst, snd) => {
    const ratio = fst / snd;
    return isNaN(ratio) ? 0 : ratio * 100;
  };

  return (
    <TableContainer>
      <Table>
        <TableHead className={classes.highlight}>
          <TableRow>
            <TableCell>
              <strong>{t("group")}</strong>
            </TableCell>
            <TableCell>
              <strong>{t("sessions")}</strong>
            </TableCell>
            <TableCell>
              <strong>{t("PRESENT")}</strong>
            </TableCell>
            <TableCell>
              <strong>%</strong>
            </TableCell>
            <TableCell>
              <strong>{t("ABSENT")}</strong>
            </TableCell>
            <TableCell>
              <strong>%</strong>
            </TableCell>
            <TableCell>
              <strong>{t("EXECUSED")}</strong>
            </TableCell>
            <TableCell>
              <strong>%</strong>
            </TableCell>
            <TableCell>
              <strong>{t("average_grade")}</strong>
            </TableCell>
            <TableCell>
              <strong>{t("final_grade")}</strong>
            </TableCell>
            <TableCell>
              <strong>{t("final_rate")}</strong>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {courses.map((course) => {
            const data = getCourseAnalytics(course);
            const evaluation =
              evaluations.find(eqProps("product_id", course)) || {};
            return (
              <TableRow key={course.id}>
                <TableCell>
                  <Box maxWidth="300px">{course.name}</Box>
                </TableCell>
                <TableCell>{data.sessions}</TableCell>
                <TableCell>{data.present}</TableCell>
                <TableCell>{data.present_percent}</TableCell>
                <TableCell>{data.absent}</TableCell>
                <TableCell>{data.absent_percent}</TableCell>
                <TableCell>{data.execused}</TableCell>
                <TableCell>{data.execused_percent}</TableCell>
                <TableCell>{data.grades || ""}</TableCell>
                <TableCell>{evaluation.grade}</TableCell>
                <TableCell>{t(evaluation.rate)}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
