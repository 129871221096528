import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { FormDateField, FormTimeField } from "commons/components/FormDateField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";

const base = "shifts";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base);
  const [facilities] = useResourcesByQuery("facilities", true);

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        label="facility"
        grid={6}
        options={facilities}
        value={model.facility_id}
        onChange={updateModel("facility_id")}
      />
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTimeField
        grid={3}
        label="starts"
        value={model.starts}
        onChange={updateModel("starts")}
        error={rules.starts}
      />
      <FormTextField
        grid={3}
        type="number"
        min="1"
        label="duration_in_hours"
        value={model.duration_in_hours}
        onChange={updateModel("duration_in_hours")}
        error={rules.duration_in_hours}
      />
      <FormDateField
        grid={3}
        label="active_from"
        value={model.active_from}
        onChange={updateModel("active_from")}
        error={rules.active_from}
      />
      <FormDateField
        grid={3}
        label="active_to"
        value={model.active_to}
        onChange={updateModel("active_to")}
        error={rules.active_to}
      />
    </ResourceSinglePage>
  );
}
