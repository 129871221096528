import React from "react";
import ReportManager from "commons/components/ReportManager";

const filters = [
  {
    type: "filter",
    name: "service-locations",
    key: "service_location_id",
  },
];

const columns = [
  { name: "service_location", type: "text" },
  { name: "code", type: "text" },
  { name: "name", type: "text" },
  { name: "customer", type: "text" },
  { name: "phone", type: "text" },
  { name: "duration_start", type: "datetime" },
  { name: "duration_end", type: "datetime" },
];

export default function EndingServicesReport() {
  return (
    <ReportManager
      url="sale-lines"
      title="ending-services"
      columns={columns}
      filterByDate
      dateField="sale_lines.duration_end"
      filters={filters}
      //   initialQuery={{
      //     "sale_lines.type": "ONCE",
      //   }}
    />
  );
}
