import React, { useContext, useState } from "react";
import {
  Typography,
  Hidden,
  Grow,
  Button,
  Grid,
  IconButton,
  Popover,
  TextField,
} from "@material-ui/core";
import { PermissionsContext } from "commons/helpers/contexts";
import { Delete, Save, Settings } from "@material-ui/icons";
import { Link as RouterLink } from "react-router-dom";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";

export default function Home({ logo, companyName }) {
  const { shortcuts } = useContext(PermissionsContext);
  const [showSettings, setShowSettings] = useState(false);
  const { t } = useTranslate();

  return (
    <Grow in>
      <div
        style={{
          height: "100%",
          textAlign: "center",
          paddingTop: "20vh",
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ maxWidth: "50%", maxHeight: "50vh" }}
        />
        <Hidden xsDown>
          <Typography style={{ fontWeight: "bold" }} variant="h1">
            {companyName}
          </Typography>
        </Hidden>
        <Hidden smUp>
          <Typography style={{ fontWeight: "bold" }} variant="h4">
            {companyName}
          </Typography>
        </Hidden>
        <br />
        <br />
        <div
          style={{
            // background: "rgba(0,0,0,0.05)",
            borderRadius: "4px",
            width: "100%",
            padding: "8px",
            minHeight: "30vh",
          }}
        >
          {shortcuts.length > 0 && (
            <div style={{ textAlign: "start" }}>
              <Button
                onClick={() => setShowSettings(!showSettings)}
                startIcon={<Settings />}
              >
                {t("settings")}
              </Button>
            </div>
          )}
          <br />
          <Grid container spacing={2}>
            {shortcuts.map((shortcut) => (
              <Grid item xs sm={2} key={shortcut.id}>
                <ShortcutButton
                  shortcut={shortcut}
                  showSettings={showSettings}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </Grow>
  );
}

function ShortcutButton({ shortcut, showSettings }) {
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [title, setTitle] = useState(shortcut.title || "");
  const [order, setOrder] = useState(shortcut.order || "0");

  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onSave = () => {
    api.service("dashboard-shortcuts").patch(shortcut.id, { title, order });
  };
  const onRemove = () => {
    api.service("dashboard-shortcuts").remove(shortcut.id);
  };

  return (
    <div style={{ position: "relative" }}>
      {showSettings && (
        <div style={{ position: "absolute", top: "-12px", left: "-12px" }}>
          <IconButton onClick={handleMenu} size="small">
            <Settings size="small" />
          </IconButton>
          <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            keepMounted
            open={open}
            onClose={handleClose}
          >
            <div style={{ padding: "0.5em" }}>
              <TextField
                name="title"
                label={t("title")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <TextField
                name="order"
                label={t("order")}
                type="number"
                min="1"
                max="12"
                value={order}
                onChange={(e) => setOrder(parseInt(e.target.value) || 1)}
              />
              <div
                className="settings-buttons"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <IconButton onClick={onSave}>
                  <Save />
                </IconButton>
                <IconButton onClick={onRemove}>
                  <Delete />
                </IconButton>
              </div>
            </div>
          </Popover>
        </div>
      )}
      <Button
        variant="outlined"
        color="secondary"
        disableElevation
        fullWidth
        size="large"
        component={RouterLink}
        to={shortcut.route}
        disabled={showSettings}
      >
        {shortcut.title}
      </Button>
    </div>
  );
}
