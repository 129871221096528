import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";
import { Importer } from "commons/components/Importer";
import useTranslate from "commons/hooks/useTranslate";

const columns = [
  {
    name: "date",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "type",
    sortable: true,
    searchable: true,
    type: "translate",
    searchList: ["add", "deduct", "move"],
  },
  {
    name: "reference",
    sortable: true,
    searchable: true,
    type: "text",
  },
  {
    name: "notes",
    sortable: true,
    searchable: true,
    type: "text",
  },
  {
    name: "summary",
    sortable: true,
    searchable: true,
    type: "text",
  },
];

export default function List() {
  const { t } = useTranslate();

  return (
    <ResourceListPage
      url="transfers"
      columns={columns}
      actions={
        <>
          <Importer
            base="transfers"
            columns={[
              "facility_id",
              "product_id",
              "serial",
              "mfg_date",
              "exp_date",
              "value",
              "quantity",
            ]}
            beforeSave={(records) => {
              const date = new Date();
              return [
                {
                  date,
                  type: "add",
                  summary: t("import"),
                  lines: records.map((rec) => ({
                    ...rec,
                    requested: date,
                    fulfilled: date,
                    mfg_date: rec.mfg_date || null,
                    exp_date: rec.exp_date || null,
                    quantity: Math.abs(rec.quantity),
                    value: Math.floor(rec.value * 100),
                    total_value: Math.floor(
                      rec.value * 100 * Math.abs(rec.quantity)
                    ),
                  })),
                },
              ];
            }}
          />
        </>
      }
    />
  );
}
