import React from "react";
import { Grid, TextField } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";

export default function FormTextField({
  grid = 12,
  xs = 12,
  label,
  value,
  onChange,
  error,
  ...props
}) {
  const { t } = useTranslate();
  return (
    <Grid item sm={grid} xs={xs}>
      <TextField
        label={t(label)}
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        error={Boolean(error)}
        helperText={Boolean(error) && t("rules." + error)}
        {...props}
      />
    </Grid>
  );
}
