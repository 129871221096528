import { createContext } from "react";

const TranslateContext = createContext(null);
const PrintTemplatesContext = createContext(null);
const PermissionsContext = createContext(null);
const FrontDeskContext = createContext(null);

export {
  TranslateContext,
  PrintTemplatesContext,
  PermissionsContext,
  FrontDeskContext,
};
