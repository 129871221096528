import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateTimeField } from "commons/components/FormDateField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import useTranslate from "commons/hooks/useTranslate";
import dayjs from "dayjs";

const base = "customer-payments";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, { method: "CASH", date: dayjs() });
  const [customers] = useResourcesByQuery("customers", true);
  const { t } = useTranslate();

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        options={customers}
        label="customer"
        value={model.customer_id}
        onChange={updateModel("customer_id")}
        error={rules.customer_id}
      />
      <FormMoneyField
        grid={3}
        label="amount"
        value={Math.abs(model.amount)}
        onChange={updateModel("amount")}
        error={rules.amount}
      />
      <FormDateTimeField
        grid={3}
        label="date"
        value={model.date}
        onChange={updateModel("date")}
        error={rules.date}
      />
      <FormSelectField
        grid={3}
        label="payment_method"
        options={[
          {
            id: "CASH",
            name: t("CASH"),
          },
          {
            id: "CREDIT_CARD",
            name: t("CREDIT_CARD"),
          },
          {
            id: "WIRE_TRANSFER",
            name: t("WIRE_TRANSFER"),
          },
          {
            id: "CHEQUE",
            name: t("CHEQUE"),
          },
        ]}
        value={model.method}
        onChange={updateModel("method")}
        error={rules.method}
      />
      <FormTextField
        grid={3}
        label="reference"
        value={model.reference}
        onChange={updateModel("reference")}
        error={rules.reference}
      />
      <FormTextField
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceSinglePage>
  );
}
