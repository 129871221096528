import React, { useState, useEffect, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import Forms from "./Forms";
import {
  useBalance,
  TotalsGroup,
  TabbedSection,
  OperationsBalance,
  PaymentsBalance,
} from "commons/components/Balance";
import Stack from "commons/components/Stack";
import {
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";
import { Tooltip } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { AccountBalanceWalletOutlined } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";

const base = "customers";

export default function Standalone({ nationality = false }) {
  const { t } = useTranslate();
  const { current, send, model, updateModel, id } =
    useControlledResourcePage(base);
  const hasResource = Boolean(model.id);
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <PageCard>
        <ResourceToolbar
          title={base}
          current={current}
          send={send}
          onPrintRequest={onPrintRequest}
          accessGroup
          extras={
            <Tooltip title={t("balance-statement")}>
              <IconButton
                component={RouterLink}
                to={`/s/customers/${id}/balance-statement`}
              >
                <AccountBalanceWalletOutlined />
              </IconButton>
            </Tooltip>
          }
        />
        <LinkTabs
          show={hasResource}
          tabs={[
            {
              name: "editor",
              path: `/s/customers/${id}`,
              component: (
                <Editor
                  current={current}
                  send={send}
                  updateModel={updateModel}
                  nationality={nationality}
                />
              ),
            },
            {
              name: "balance",
              path: `/s/customers/${id}/balance`,
              component: <Balance id={id} policy_id={model.policy_id} />,
            },
            {
              name: "forms",
              path: `/s/customers/${id}/forms`,
              component: <Forms id={id} />,
            },
          ]}
        />
      </PageCard>
    </PrintTemplate>
  );
}

export function Editor({
  current,
  nationality = false,
  send,
  updateModel,
  ...props
}) {
  const { model, rules } = current.context;
  const [policies] = useResourcesByQuery("policies", true, { type: "SALES" });

  useEffect(() => {
    if (!model.policy_id) {
      const defaultPolicy = Number(
        window.localStorage.getItem("defaultCustomerPolicy")
      );
      const exists = policies.find((pol) => pol.id === defaultPolicy);
      if (defaultPolicy && exists) {
        updateModel("policy_id")(defaultPolicy);
      }
    }
  }, [policies, model.policy_id, updateModel]);

  useEffect(() => {
    if (model.policy_id) {
      window.localStorage.setItem("defaultCustomerPolicy", model.policy_id);
    }
  }, [model.policy_id]);

  useEffect(() => {
    if (model.id && model.policy_id) {
      window.parent.postMessage(
        { customer_id: model.id, policy_id: model.policy_id },
        "*"
      );
    }
  }, [model.id, model.policy_id]);

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={4}
        label="policy_id"
        options={policies}
        value={model.policy_id}
        onChange={updateModel("policy_id")}
        error={rules.policy_id}
      />
      {nationality ? (
        <FormTextField
          grid={4}
          label="nationality"
          value={model.nationality}
          onChange={updateModel("nationality")}
          error={rules.nationality}
        />
      ) : (
        <FormTextField
          grid={4}
          label="address"
          value={model.address}
          onChange={updateModel("address")}
          error={rules.address}
        />
      )}
      <FormDateField
        grid={4}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormTextField
        grid={4}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={4}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={4}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}

export function Balance({
  id,
  policy_id,
  operations = "sales",
  entity = "customers",
  payments = "customer-payments",
  field = "customer_id",
}) {
  const { balance } = useBalance(id, "balance", entity, [operations, payments]);
  const [policy, setPolicy] = useState(null);

  useEffect(() => {
    if (policy_id) {
      api.service("policies").get(policy_id).then(setPolicy);
    }
  }, [policy_id]);

  const values = useMemo(() => {
    if (!policy) return {};
    const rate = policy.currency.rate;
    return Object.keys(balance).reduce((acc, k) => {
      return { ...acc, [k]: Math.round((balance[k] / rate) * 100) / 100 };
    }, {});
  }, [balance, policy]);

  return (
    <Stack>
      <TotalsGroup
        labels={[operations, "payments", "refunds", "balance"]}
        values={values}
      />
      <TabbedSection
        labels={[operations, "payments", "refunds"]}
        sections={[
          <OperationsBalance url={operations} filter={{ [field]: id }} />,
          <PaymentsBalance id={id} url={payments} field={field} />,
          <PaymentsBalance id={id} url={payments} field={field} refund />,
        ]}
      />
    </Stack>
  );
}
