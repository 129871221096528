import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import { Card, Grid, CardContent, Typography } from "@material-ui/core";

export default function DataCard({ title, value, grid }) {
  const { t } = useTranslate();

  return (
    <Grid item xs sm={grid}>
      <Card variant="outlined">
        <CardContent>
          <Typography variant="h4" component="p">
            <span style={{ direction: "ltr", display: "inline-block" }}>
              {value}
            </span>
          </Typography>
          <Typography variant="h6" component="h2">
            {t(title)}
          </Typography>
        </CardContent>
      </Card>
    </Grid>
  );
}
