export const prepareDiscount = (discount) => ({
  discount_id: discount.id,
  name: discount.name,
  value: discount.value,
  min_quantity: discount.min_quantity,
  min_price: discount.min_price,
  max_value: discount.max_value,
  applied_value: 0,
});

export const prepareTax = (tax) => ({
  tax_id: tax.id,
  name: tax.name,
  value: tax.value,
  type: tax.type,
  min_price: tax.min_price,
  max_value: tax.max_value,
  applied_value: 0,
});

export const prepareCommission = (commission) =>
  commission.product_id
    ? prepareProductCommission(commission)
    : prepareEmployeeCommission(commission);

const prepareProductCommission = (commission) => {
  return {
    employee_id: commission.employee_id,
    name: commission.employee,
    value: commission.value,
    min_price: commission.min_price,
    max_value: commission.max_value,
    applied_value: 0,
  };
};

const prepareEmployeeCommission = (employee) => ({
  employee_id: employee.id,
  name: employee.name,
  // commission: employee.sale_commission || "0",
  value: employee.sale_commission || "0",
  min_price: null,
  max_value: null,
  applied_value: 0,
});
