import React, { useContext, useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { Dashboard } from "@material-ui/icons";
import { useLocation } from "react-router-dom";
import api from "commons/helpers/api";
import useTranslate from "commons/hooks/useTranslate";
import ErrorAlert from "./ErrorAlert";
import { PermissionsContext } from "commons/helpers/contexts";

export function DashboardShortcutPin({ title }) {
  const location = useLocation();
  const { t } = useTranslate();
  const [error, setError] = useState(null);
  const { shortcuts } = useContext(PermissionsContext);

  const disabled = shortcuts.some((s) => s.route === location.pathname);

  const onClick = () => {
    api
      .service("dashboard-shortcuts")
      .create({
        title: t(title),
        route: location.pathname,
        order: 0,
      })
      .catch(setError);
  };

  return (
    <>
      <ErrorAlert error={error} />
      <Tooltip title={t("addToDashboard")}>
        <span>
          <IconButton onClick={onClick} disabled={disabled}>
            <Dashboard />
          </IconButton>
        </span>
      </Tooltip>
    </>
  );
}
