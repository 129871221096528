import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import { prop } from "ramda";
import {
  Grid,
  Tooltip,
  IconButton,
  TextField,
  Box,
  Popover,
} from "@material-ui/core";
import {
  Title,
  Delete,
  ArrowDownward,
  ArrowUpward,
  Settings,
  FileCopy,
  Image,
  Remove,
  FormatAlignCenter,
  FormatAlignLeft,
  FormatAlignRight,
  FormatBold,
  FormatItalic,
  FormatUnderlined,
  CalendarToday,
  Timer,
  Person,
  CalendarTodayOutlined,
} from "@material-ui/icons";
import { ComponentsMap } from "./BuilderComponents";
import CardSection from "commons/components/CardSection";
import { Alert } from "@material-ui/lab";
import Stack from "commons/components/Stack";
import FormFileField from "commons/components/FormFileField";
import ColorPicker from "material-ui-color-picker";

const addFieldProps = (type) => {
  switch (type) {
    case "text":
      return {
        fontSize: 24,
        fontColor: "inherit",
        padding: 0,
        bold: false,
        italic: false,
        underlined: false,
      };
    case "image":
      return { width: 32, height: 32 };
    case "username":
      return {
        value: "username",
        fontSize: 24,
        fontColor: "inherit",
        padding: 0,
        bold: false,
        italic: false,
        underlined: false,
      };
    case "date":
      return {
        value: "date",
        fontSize: 24,
        fontColor: "inherit",
        padding: 0,
        bold: false,
        italic: false,
        underlined: false,
      };
    case "counter":
      return {
        value: "counter",
        fontSize: 24,
        fontColor: "inherit",
        padding: 0,
        bold: false,
        italic: false,
        underlined: false,
      };
    case "daily":
      return {
        value: "daily",
        fontSize: 24,
        fontColor: "inherit",
        padding: 0,
        bold: false,
        italic: false,
        underlined: false,
      };
    default:
      return {};
  }
};

const getAlignValue = (align) => {
  if (align === "end") return "flex-end";
  if (align === "start") return "flex-start";
  return "center";
};

export default function FormBuilder({ value = [], onChange, error }) {
  const { t } = useTranslate();

  const generateID = () =>
    value.length > 0 ? Math.max(...value.map(prop("id"))) + 1 : 1;

  const onRemove = (item) => onChange(value.filter((i) => i.id !== item.id));
  const onDuplicate = (item) => {
    onChange([...value, { ...item, id: generateID() }]);
  };

  const switchItemsByIndex = (fst, snd) => {
    const item = value[fst];
    const replaced = value[snd];
    const newValue = value.map((original, i) => {
      if (i === fst) return replaced;
      if (i === snd) return item;
      return original;
    });
    onChange(newValue);
  };

  const onMoveUp = (item) => {
    const index = value.findIndex((i) => i.id === item.id);
    if (index - 1 < 0) return;
    switchItemsByIndex(index, index - 1);
  };

  const onMoveDown = (item) => {
    const index = value.findIndex((i) => i.id === item.id);
    console.log("index", index, value.length);
    if (index + 1 >= value.length) return;
    switchItemsByIndex(index, index + 1);
  };

  const addItem = (item) => onChange([...value, { id: generateID(), ...item }]);
  const onItemChange = (item) =>
    onChange(value.map((i) => (i.id === item.id ? item : i)));

  const addField = (type) => () => {
    addItem({
      type,
      value: "",
      size: 12,
      bgColor: "none",
      align: "center",
      ...addFieldProps(type),
    });
  };

  return (
    <Grid item xs={12}>
      {error && <Alert severity="error">{t("rules." + error)}</Alert>}
      <div>
        <Tooltip title={t("addText")}>
          <IconButton color="secondary" onClick={addField("text")}>
            <Title />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addImage")}>
          <IconButton color="secondary" onClick={addField("image")}>
            <Image />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("addLine")}>
          <IconButton color="secondary" onClick={addField("line")}>
            <Remove />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("date")}>
          <IconButton color="secondary" onClick={addField("date")}>
            <CalendarToday />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("username")}>
          <IconButton color="secondary" onClick={addField("username")}>
            <Person />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("counter")}>
          <IconButton color="secondary" onClick={addField("counter")}>
            <Timer />
          </IconButton>
        </Tooltip>
        <Tooltip title={t("DAILY")}>
          <IconButton color="secondary" onClick={addField("daily")}>
            <CalendarTodayOutlined />
          </IconButton>
        </Tooltip>
      </div>
      <CardSection>
        <Grid container spacing={2} alignItems="flex-start">
          {value.map((item) => {
            const CurrentComponent = ComponentsMap[item.type];
            return (
              <Grid item sm={parseInt(item.size)} key={item.id}>
                <Box
                  display="flex"
                  alignItems="flex-start"
                  justifyContent={getAlignValue(item.align)}
                  textAlign={item.align}
                  bgcolor={item.bgColor || "none"}
                  color={item.fontColor || "inherit"}
                  p={parseInt(item.padding) || 0}
                >
                  <CurrentComponent item={item} onChange={onItemChange} />
                  <SettingsComponent
                    item={item}
                    onChange={onItemChange}
                    onRemove={onRemove}
                    onDuplicate={onDuplicate}
                    onMoveUp={onMoveUp}
                    onMoveDown={onMoveDown}
                  />
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </CardSection>
    </Grid>
  );
}

const SettingsComponents = {
  text: TextSettings,
  image: ImageSettings,
  line: LineSettings,
  date: TextSettings,
  username: TextSettings,
  counter: TextSettings,
  daily: TextSettings,
};

function SettingsComponent({
  item,
  onChange,
  onRemove,
  onDuplicate,
  onMoveUp,
  onMoveDown,
}) {
  const Comp = SettingsComponents[item.type];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton onClick={handleMenu} color="inherit">
        <Settings />
      </IconButton>
      <Popover
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        <div style={{ padding: "0.5em" }}>
          <Comp item={item} onChange={onChange} />
          <div className="settings-buttons">
            <IconButton onClick={() => onRemove(item)}>
              <Delete />
            </IconButton>
            <IconButton onClick={() => onDuplicate(item)}>
              <FileCopy />
            </IconButton>
            <IconButton onClick={() => onMoveUp(item)}>
              <ArrowUpward />
            </IconButton>
            <IconButton onClick={() => onMoveDown(item)}>
              <ArrowDownward />
            </IconButton>
          </div>
        </div>
      </Popover>
    </>
  );
}

function TextSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Stack>
      <Grid container spacing={1}>
        <Grid item xs sm={12}>
          <TextField
            multiline
            name="title"
            label={t("content")}
            value={item.value}
            onChange={(e) => onChange({ ...item, value: e.target.value })}
          />
        </Grid>
        <Grid item xs sm={2}>
          <TextField
            name="size"
            label={t("size")}
            type="number"
            min="1"
            max="12"
            value={item.size}
            onChange={(e) => onChange({ ...item, size: e.target.value })}
          />
        </Grid>
        <Grid item xs sm={2}>
          <TextField
            name="fontSize"
            label={t("fontSize")}
            type="number"
            min="6"
            value={item.fontSize}
            onChange={(e) => onChange({ ...item, fontSize: e.target.value })}
          />
        </Grid>
        <Grid item xs sm={2}>
          <TextField
            name="padding"
            label={t("padding")}
            type="number"
            min="0"
            value={item.padding}
            onChange={(e) => onChange({ ...item, padding: e.target.value })}
          />
        </Grid>

        <Grid item xs sm={3}>
          <ColorPicker
            label={t("bgColor")}
            value={item.bgColor}
            onChange={(value) => onChange({ ...item, bgColor: value })}
          />
        </Grid>
        <Grid item xs sm={3}>
          <ColorPicker
            label={t("fontColor")}
            value={item.fontColor}
            onChange={(value) => onChange({ ...item, fontColor: value })}
          />
        </Grid>
      </Grid>
      <div className="settings-buttons">
        <IconButton
          color={item.align === "end" ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, align: "end" })}
        >
          <FormatAlignRight />
        </IconButton>
        <IconButton
          color={item.align === "center" ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, align: "center" })}
        >
          <FormatAlignCenter />
        </IconButton>
        <IconButton
          color={item.align === "start" ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, align: "start" })}
        >
          <FormatAlignLeft />
        </IconButton>
        <IconButton
          color={item.bold ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, bold: !item.bold })}
        >
          <FormatBold />
        </IconButton>
        <IconButton
          color={item.italic ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, italic: !item.italic })}
        >
          <FormatItalic />
        </IconButton>
        <IconButton
          color={item.underlined ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, underlined: !item.underlined })}
        >
          <FormatUnderlined />
        </IconButton>
      </div>
    </Stack>
  );
}

function ImageSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Stack>
      <FormFileField
        value={item.value}
        onChange={(e) => onChange({ ...item, value: e })}
      />
      <TextField
        name="size"
        label={t("size")}
        type="number"
        min="1"
        max="12"
        value={item.size}
        onChange={(e) => onChange({ ...item, size: e.target.value })}
      />
      <TextField
        name="width"
        label={t("width")}
        // type="number"
        // min="1"
        // max="12"
        value={item.width}
        onChange={(e) => onChange({ ...item, width: e.target.value })}
      />
      <TextField
        name="height"
        label={t("height")}
        // type="number"
        // min="1"
        // max="12"
        value={item.height}
        onChange={(e) => onChange({ ...item, height: e.target.value })}
      />
      <div className="settings-buttons">
        <IconButton
          color={item.align === "flex-end" ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, align: "flex-end" })}
        >
          <FormatAlignRight />
        </IconButton>
        <IconButton
          color={item.align === "center" ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, align: "center" })}
        >
          <FormatAlignCenter />
        </IconButton>
        <IconButton
          color={item.align === "flex-start" ? "primary" : "default"}
          onClick={(e) => onChange({ ...item, align: "flex-start" })}
        >
          <FormatAlignLeft />
        </IconButton>
      </div>
    </Stack>
  );
}

function LineSettings({ item, onChange }) {
  const { t } = useTranslate();
  return (
    <Stack>
      <TextField
        name="size"
        label={t("size")}
        type="number"
        min="1"
        max="12"
        value={item.size}
        onChange={(e) => onChange({ ...item, size: e.target.value })}
      />
    </Stack>
  );
}
