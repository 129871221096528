import { useCallback, useContext } from "react";
import { PrintTemplatesContext } from "commons/helpers/contexts";

export default function usePrintTemplates() {
  const { templates, translations } = useContext(PrintTemplatesContext);

  const translate = useCallback(
    (lang, type, id, fallback = "") => {
      const item = translations.find(
        (t) =>
          t.translatable_type === type &&
          t.translatable_id === id &&
          t.language_id === lang
      );
      return item ? item.translation : fallback;
    },
    [translations]
  );

  return {
    templates,
    translations,
    translate,
  };
}
