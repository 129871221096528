import React from "react";
import PrescriptionLines from "commons/components/PrescriptionLines";

export default function Prescription({ item, onChange }) {
  return (
    <PrescriptionLines
      label={item.label}
      value={item.value}
      onChange={(e) => onChange({ ...item, value: e })}
    />
  );
}
