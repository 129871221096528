import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";

const base = "contacts";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        grid={12}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={3}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormTextField
        grid={3}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={3}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={3}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceSinglePage>
  );
}
