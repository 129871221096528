import React from "react";
import useTranslate from "commons/hooks/useTranslate";
import {
  useLocation,
  Route,
  Switch,
  Link as RouterLink,
} from "react-router-dom";
import Stack from "./Stack";
import { Tabs, Tab } from "@material-ui/core";

export default function LinkTabs({ tabs, show = false }) {
  const { t } = useTranslate();
  const location = useLocation();

  return (
    <Stack>
      {show && (
        <Tabs value={location.pathname} variant="fullWidth">
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              label={t(tab.name)}
              value={tab.path}
              component={RouterLink}
              to={tab.path}
            />
          ))}
        </Tabs>
      )}
      <Switch>
        {tabs.map((tab) => (
          <Route key={tab.name} exact path={tab.path}>
            {tab.component}
          </Route>
        ))}
      </Switch>
    </Stack>
  );
}
