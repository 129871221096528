import React, { useState, useEffect, useMemo } from "react";
import PageCard from "commons/components/PageCard";
import Stack from "commons/components/Stack";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  makeStyles,
  TableBody,
  Checkbox,
  Portal,
  Zoom,
  Fab,
  Box,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import { Print, Save } from "@material-ui/icons";
import { ExcelExporterButton } from "commons/components/ResourceListPage";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import dayjs from "dayjs";
import { FormDateField } from "commons/components/FormDateField";
import { eqProps, uniqWith } from "ramda";
import api from "commons/helpers/api";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function Manager() {
  const { t } = useTranslate();
  const [customers] = useResourcesByQuery("customers", true);
  const [schools] = useResourcesByQuery("facilities", true);
  const [courses, sendCourses] = useResourcesByQuery("sale-lines");
  const [books, sendBooks] = useResourcesByQuery("book-course");
  const [deliveries, sendDeliveries] = useResourcesByQuery("book-deliveries");
  const [school, setSchool] = useState(null);
  const [course, setCourse] = useState(null);
  const [date, setDate] = useState(dayjs());
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (school) {
      sendCourses("SET_QUERY", {
        query: {
          "sales.facility_id": school,
          "products.type": "COURSE",
        },
      });
    }
  }, [school, sendCourses]);

  useEffect(() => {
    if (school && course) {
      sendBooks("SET_QUERY", {
        query: {
          product_id: course,
        },
      });
      sendDeliveries("SET_QUERY", {
        query: {
          product_id: course,
        },
      });
    }
  }, [school, course, sendBooks, sendDeliveries]);

  const uniqCourses = useMemo(() => {
    return uniqWith(eqProps("product_id"), courses);
  }, [courses]);

  const students = useMemo(() => {
    return uniqWith(
      eqProps("customer_id"),
      courses.filter((curr) => curr.product_id === course)
    );
  }, [course, courses]);

  const studentsByKey = useMemo(() => {
    return customers.reduce(
      (acc, curr) => ({ ...acc, [curr.id]: curr.name }),
      {}
    );
  }, [customers]);

  const addDelivery = (student, book) => {
    if (hasRecord(student, book)) {
      setRecords((old) =>
        old.filter(
          (rec) =>
            rec.customer_id !== student ||
            rec.book_id !== book ||
            rec.product_id !== course
        )
      );
    } else {
      setRecords((old) => [
        ...old,
        {
          customer_id: student,
          book_id: book,
          product_id: course,
          date: dayjs(),
        },
      ]);
    }
  };

  const hasSaved = (student, book) => {
    return Boolean(
      deliveries.find(
        (del) =>
          del.book_id === book &&
          del.customer_id === student &&
          del.product_id === course
      )
    );
  };

  const hasRecord = (student, book) => {
    return Boolean(
      records.find(
        (del) =>
          del.book_id === book &&
          del.customer_id === student &&
          del.product_id === course
      )
    );
  };

  const onSave = () => {
    setLoading(true);
    api
      .service("book-deliveries")
      .create(records)
      .then(() => {
        setRecords([]);
      })
      .catch(setError)
      .finally((_) => setLoading(false));
  };

  return (
    <PageCard>
      <LoadingIndicator show={loading} />
      <ErrorAlert error={error} />
      <Stack>
        <Toolbar disableGutters>
          <Typography style={{ flex: "1 1 100%" }} variant="h4">
            {t("book-delivery")}
          </Typography>
          <ExcelExporterButton
            // headers={visibleColumns}
            data={[]}
            filename="book_deliveries"
          />
          <Tooltip title={t("print")}>
            <IconButton>
              <Print />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Grid container spacing={2}>
          <FormDateField
            grid={3}
            label="date"
            value={date}
            onChange={setDate}
          />
          <FormSelectField
            grid={3}
            label="facility"
            options={schools}
            value={school}
            onChange={setSchool}
          />
          <FormSelectField
            grid={3}
            label="group"
            options={uniqCourses}
            optionKey="product_id"
            value={course}
            onChange={setCourse}
          />
        </Grid>
        {(students.length > 0 || books.length > 0) && (
          <TableContainer>
            <Table>
              <TableHead className={classes.highlight}>
                <TableRow>
                  <TableCell>{t("customer")}</TableCell>
                  {books.map((book) => (
                    <TableCell key={book.book_id}>{book.name}</TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map(({ customer_id }, index) => (
                  <TableRow
                    key={customer_id}
                    className={index % 2 ? classes.strip : ""}
                    hover
                  >
                    <TableCell>{studentsByKey[customer_id]}</TableCell>
                    {books.map(({ book_id }) => (
                      <TableCell key={book_id} padding="none">
                        <Checkbox
                          checked={
                            hasRecord(customer_id, book_id) ||
                            hasSaved(customer_id, book_id)
                          }
                          onChange={() => addDelivery(customer_id, book_id)}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Stack>
      <SaveButton onSave={onSave} disabled={records.length === 0} />
    </PageCard>
  );
}

export function SaveButton({ onSave, disabled }) {
  return (
    <Portal>
      <Box position="fixed" bottom={24} right={24}>
        <Zoom in style={{ transitionDelay: "250ms" }}>
          <Fab color="primary" onClick={onSave} disabled={disabled}>
            <Save />
          </Fab>
        </Zoom>
      </Box>
    </Portal>
  );
}
