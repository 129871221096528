import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { FormDateField } from "commons/components/FormDateField";

const base = "assets";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);
  const [groups] = useResourcesByQuery("asset-groups", true);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormSelectField
        grid={6}
        options={groups}
        label="asset_group_id"
        value={model.asset_group_id}
        onChange={updateModel("asset_group_id")}
        error={rules.asset_group_id}
      />
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormDateField
        grid={6}
        label="acquired"
        value={model.acquired}
        onChange={updateModel("acquired")}
        error={rules.acquired}
      />
      <FormDateField
        grid={6}
        label="disposed"
        value={model.disposed}
        onChange={updateModel("disposed")}
        error={rules.disposed}
      />
      <FormTextField
        multiline
        label="description"
        value={model.description}
        onChange={updateModel("description")}
        error={rules.description}
      />
    </ResourceSinglePage>
  );
}
