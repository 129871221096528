import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "customer",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "customers.name",
  },
  {
    name: "date",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "amount",
    type: "money",
    sortable: true,
    searchable: true,
  },
  {
    name: "method",
    type: "translate",
    sortable: true,
    searchable: true,
    searchList: ["CASH", "CREDIT_CARD", "WIRE_TRANSFER", "CHEQUE"],
  },
  {
    name: "reference",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="customer-payments" columns={columns} />;
}
