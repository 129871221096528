import React, { useState, useEffect, useCallback } from "react";
import PageCard from "./PageCard";
import useTranslate from "commons/hooks/useTranslate";
import {
  Typography,
  Box,
  Zoom,
  Fab,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  TableSortLabel,
  Toolbar,
  IconButton,
  Tooltip,
  TablePagination,
  Link,
  Portal,
  makeStyles,
  TextField,
  MenuItem,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import {
  Add,
  Search,
  ViewColumn,
  CloudDownload,
  CancelOutlined,
  CheckCircleOutline,
  Bookmark,
} from "@material-ui/icons";
import api from "commons/helpers/api";
import { useQuery, useQueryClient } from "react-query";
import Stack from "./Stack";
import { prop, omit, pick } from "ramda";
import { toggle } from "commons/helpers/utils";
import XLSX from "xlsx";
import ResourceRemoveButton from "./ResourceRemoveButton";
import ErrorAlert from "./ErrorAlert";
import LoadingIndicator from "./LoadingIndicator";
import dayjs from "dayjs";
import { PrintButton, PrintTemplate, usePrintManager } from "./PrintManager";
import { DashboardShortcutPin } from "./DashboardShortcut";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

function fetchResourceList({ queryKey }) {
  const [key, query] = queryKey;
  return api.service(key).find({ query });
}

export default function ResourceListPage({
  url = "",
  title = url,
  baseQuery,
  columns,
  actions = null,
  hideTitle = false,
  hideToolbar = false,
  Wrapper = PageCard,
  createButton = <CreateLinkButton resource={title} />,
  initialSortBy = "id",
  initialSortDirection = 1,
  rowClickBase,
  rowClickField,
  lockColumns = false,
}) {
  const { t } = useTranslate();
  const client = useQueryClient();
  const savedColumns = () => {
    if (lockColumns) return columns.map(prop("name"));
    return (
      JSON.parse(window.localStorage.getItem(title + "_list")) ||
      columns.map(prop("name"))
    );
  };
  const savedQuery = () =>
    JSON.parse(window.localStorage.getItem(title + "defaultListQuery")) || {};
  const [visibleColumns, setVisibleColumns] = useState(savedColumns);

  const [query, setQuery] = useState(savedQuery);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  // const [sort, setSort] = useState({ id: 1 });
  const [sortBy, setSortBy] = useState(initialSortBy);
  const [sortDirection, setSortDirection] = useState(initialSortDirection);
  const [selected, setSelected] = useState([]);

  const [showSearch, setShowSearch] = useState(
    Object.keys(query).length > 0 ? true : false
  );
  const [showSelectColumns, setShowSelectColumns] = useState(false);
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();

  useEffect(() => {
    if (lockColumns) return;
    window.localStorage.setItem(
      title + "_list",
      JSON.stringify(visibleColumns)
    );
  }, [title, visibleColumns, lockColumns]);

  const { status, data, error, isFetching } = useQuery(
    [
      url,
      {
        ...query,
        $skip: page * rowsPerPage,
        $limit: rowsPerPage,
        $sort: { [sortBy]: sortDirection },
        ...(baseQuery ? baseQuery : {}),
      },
    ],
    fetchResourceList,
    {
      keepPreviousData: true,
    }
  );

  const onDataChange = useCallback(() => {
    client.invalidateQueries(url);
  }, [client, url]);

  useEffect(() => {
    api.service(url).on("created", onDataChange);
    api.service(url).on("updated", onDataChange);
    api.service(url).on("patched", onDataChange);
    api.service(url).on("removed", onDataChange);
    return () => {
      api.service(url).removeListener("created", onDataChange);
      api.service(url).removeListener("updated", onDataChange);
      api.service(url).removeListener("patched", onDataChange);
      api.service(url).removeListener("removed", onDataChange);
    };
  }, [url, onDataChange]);

  const onSortChange = (col) => {
    if (sortBy === col) {
      setSortDirection((old) => old * -1);
    } else {
      setSortBy(col);
      setSortDirection(1);
    }
    setPage(0);
  };

  const onQueryChange = (col) => (val) => {
    const field = col.searchKey || col.name;
    if (val === null) {
      setQuery((old) => omit([field], old));
    } else {
      setQuery((old) => ({ ...old, [field]: val }));
    }
    setPage(0);
  };

  const onVisibleColumnsChange = (e) => {
    setVisibleColumns(e.target.value);
  };

  const isLoading = status === "loading" || isFetching;

  const saveCurrentQuery = () => {
    window.localStorage.setItem(
      url + "defaultListQuery",
      JSON.stringify(query)
    );
  };

  return (
    <PrintTemplate active={active} onPrintCompleted={onPrintCompleted}>
      <Wrapper>
        <Stack>
          {selected.length > 0 ? (
            <Toolbar disableGutters>
              <Typography
                style={{ flex: "1 1 100%" }}
                color="inherit"
                variant="subtitle1"
                component="div"
              >
                {selected.length} {t("selected")}
              </Typography>
              <ResourceRemoveButton
                url={url}
                resources={selected}
                onResourcesRemoved={() => setSelected([])}
              />
            </Toolbar>
          ) : (
            !hideToolbar && (
              <Toolbar disableGutters>
                <Typography style={{ flex: "1 1 100%" }} variant="h4">
                  {!hideTitle && t(title)}
                </Typography>
                <Box display="flex" alignItems="center" className="no-print">
                  {actions}
                  <ExcelExporterButton
                    headers={visibleColumns}
                    data={data || []}
                    filename={url}
                  />
                  <PrintButton onPrintRequest={onPrintRequest} />
                  <Tooltip title={t("search")}>
                    <IconButton onClick={() => setShowSearch((old) => !old)}>
                      <Search />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("bookmark")}>
                    <IconButton onClick={saveCurrentQuery}>
                      <Bookmark />
                    </IconButton>
                  </Tooltip>
                  <DashboardShortcutPin title={title} />
                  <Tooltip
                    title={t("columns")}
                    onClick={() => setShowSelectColumns((old) => !old)}
                  >
                    <IconButton>
                      <ViewColumn />
                    </IconButton>
                  </Tooltip>
                  {showSelectColumns && (
                    <Box width={400}>
                      <TextField
                        select
                        SelectProps={{
                          multiple: true,
                        }}
                        label={t("columns")}
                        value={visibleColumns}
                        onChange={onVisibleColumnsChange}
                      >
                        {columns.map((col) => (
                          <MenuItem key={col.name} value={col.name}>
                            {t(col.label || col.name)}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Box>
                  )}
                </Box>
              </Toolbar>
            )
          )}

          <LoadingIndicator show={isLoading} />
          <ErrorAlert error={error} />
          {data && (
            <PaginatedResourceTable
              url={url}
              route={title}
              source={data}
              columns={columns.filter((col) =>
                visibleColumns.includes(col.name)
              )}
              selected={selected}
              sortBy={sortBy}
              sortDirection={sortDirection}
              onSortChange={onSortChange}
              onSelectedChange={setSelected}
              showSearch={showSearch}
              onQueryChange={onQueryChange}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={setPage}
              onChangeRowsPerPage={setRowsPerPage}
              rowClickBase={rowClickBase}
              rowClickField={rowClickField}
            />
          )}
        </Stack>
        {createButton}
      </Wrapper>
    </PrintTemplate>
  );
}

function PaginatedResourceTable({
  url,
  source,
  route,
  columns = [],
  selected = [],
  sortBy,
  sortDirection,
  onSortChange,
  onSelectedChange,
  showSearch,
  onQueryChange,
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  rowClickBase,
  rowClickField,
}) {
  const { t } = useTranslate();
  const classes = useStyles();

  const data = source.data;

  const dataLength = data.length;
  const selectedLength = selected.length;
  const noData = data.length === 0;

  const allSelected = selectedLength === dataLength && !noData;

  const onRowSelect = (id) => onSelectedChange(toggle(Number(id), selected));
  // onSelectedChange(toggle(isNaN(Number(id)) ? id : Number(id), selected));

  const onSelectAllClick = () =>
    onSelectedChange(!allSelected ? data.map(prop("id")) : []);
  const getSortDirection = (col) => {
    if (sortBy === col && sortDirection === -1) return "desc";
    // if (sort[col] === 1) return "asc";
    return "asc";
  };

  const viewCol = (row, { name, type }) => {
    const value = row[name];
    switch (type) {
      case "date":
        return !!value ? dayjs(value).format("YYYY-MM-DD") : "";
      case "datetime":
        return !!value ? dayjs(value).format("YYYY-MM-DD HH:mm") : "";
      case "money":
        return !!value ? Math.abs(Math.floor(value) / 100) : 0;
      case "balance":
        return !!value ? Math.floor(value) / 100 : 0;
      case "translate":
        return !!value ? t(value) : "";
      case "boolean":
        return !!value ? <CheckCircleOutline /> : <CancelOutlined />;
      case "image":
        return !!value ? (
          <img
            width="auto"
            height="32px"
            src={`/uploads/${value}`}
            alt="Preview"
          />
        ) : (
          ""
        );
      default:
        return value;
    }
  };

  return (
    <div>
      {/* {JSON.stringify(data)} */}
      {columns.length > 0 && (
        <>
          <TableContainer>
            <Table>
              <TableHead className={classes.highlight}>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      indeterminate={
                        selectedLength > 0 && selectedLength < dataLength
                      }
                      checked={allSelected}
                      onChange={onSelectAllClick}
                    />
                  </TableCell>
                  {columns.map((col) => (
                    <TableCell
                      key={col.name}
                      sortDirection={getSortDirection(col.name)}
                    >
                      <TableSortLabel
                        active={sortBy === col.name}
                        direction={getSortDirection(col.name)}
                        onClick={() => onSortChange(col.name)}
                      >
                        {t(col.label || col.name)}
                        {/* {orderBy === headCell.id ? (
                    <span className={classes.visuallyHidden}>
                      {order === "desc"
                        ? "sorted descending"
                        : "sorted ascending"}
                    </span>
                  ) : null} */}
                      </TableSortLabel>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {showSearch && (
                  <TableRow>
                    <TableCell padding="checkbox" />
                    {columns.map((col) => (
                      <TableCell key={col.name} padding="none">
                        <Box p={1}>
                          <SearchField
                            col={col}
                            onQueryChange={onQueryChange(col)}
                          />
                        </Box>
                      </TableCell>
                    ))}
                  </TableRow>
                )}
                {dataLength === 0 && (
                  <TableRow>
                    <TableCell colSpan={columns.length + 1}>
                      {t("noResources")}
                    </TableCell>
                  </TableRow>
                )}
                {dataLength > 0 &&
                  data.map((row, index) => (
                    <TableRow
                      className={index % 2 ? classes.strip : ""}
                      hover
                      key={row.id}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={selected.includes(row.id)}
                          onChange={() => onRowSelect(row.id)}
                        />
                      </TableCell>
                      {columns.map((col) => (
                        <TableCell key={col.name} padding="none">
                          <Link
                            underline="none"
                            color="inherit"
                            component={RouterLink}
                            to={`/s/${rowClickBase || route}/${
                              row[rowClickField || "id"]
                            }`}
                          >
                            <Box p={2} display="block">
                              <Typography
                                style={{ maxWidth: "30vw" }}
                                variant="body1"
                                noWrap
                              >
                                {viewCol(row, col)}
                              </Typography>
                            </Box>
                          </Link>
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box
            display="flex"
            justifyContent="center"
            className={classes.highlight}
          >
            <TablePagination
              rowsPerPageOptions={[10, 20, 35, 50, 100]}
              component="div"
              count={source.total}
              rowsPerPage={rowsPerPage}
              page={page}
              onChangePage={(_, page) => onChangePage(page)}
              onChangeRowsPerPage={(e) => {
                onChangeRowsPerPage(parseInt(e.target.value, 10));
                onChangePage(0);
              }}
            />
          </Box>
        </>
      )}
    </div>
  );
}

function SearchField({ col, onQueryChange }) {
  const { type } = col;
  switch (type) {
    case "text":
      return <TextSearchField onQueryChange={onQueryChange} />;
    case "date":
      return (
        <DateTimeSearchField
          type="date"
          format="YYYY-MM-DD"
          onQueryChange={onQueryChange}
        />
      );
    case "time":
      return <DateTimeSearchField type="time" onQueryChange={onQueryChange} />;
    case "datetime":
      return (
        <DateTimeSearchField
          type="datetime-local"
          format="YYYY-MM-DDTHH:mm"
          onQueryChange={onQueryChange}
        />
      );
    case "number":
    case "money":
      return <NumberSearchField onQueryChange={onQueryChange} />;
    case "boolean":
      return <BooleanSearchField onQueryChange={onQueryChange} />;
    case "translate":
      return (
        <TranslateSearchField
          options={col.searchList}
          onQueryChange={onQueryChange}
        />
      );
    default:
      return null;
  }
}

function TextSearchField({ onQueryChange }) {
  const [value, setValue] = useState("");

  return (
    <TextField
      variant="outlined"
      size="small"
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        setValue(val);
        onQueryChange(val.trim() !== "" ? { $ilike: `%${val.trim()}%` } : null);
      }}
    />
  );
}

function DateTimeSearchField({ onQueryChange, format, type }) {
  const [start, setStart] = useState(dayjs().startOf("DAY").format(format));
  const [end, setEnd] = useState(dayjs().endOf("DAY").format(format));

  return (
    <>
      <TextField
        variant="outlined"
        size="small"
        type={type}
        value={start}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          const val = e.target.value;
          if (val && dayjs(val).isValid()) {
            const formatted = dayjs(val).format(format);
            setStart(formatted);
            onQueryChange({ $lte: end, $gte: formatted });
          }
        }}
      />
      <TextField
        variant="outlined"
        size="small"
        type={type}
        value={end}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={(e) => {
          const val = e.target.value;
          if (val && dayjs(val).isValid()) {
            const formatted = dayjs(val).format(format);
            setEnd(formatted);
            onQueryChange({ $lte: start, $gte: formatted });
          }
        }}
      />
    </>
  );
}

function NumberSearchField({ onQueryChange }) {
  const [value, setValue] = useState("");

  return (
    <TextField
      variant="outlined"
      size="small"
      type="number"
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        setValue(val);
        onQueryChange(val || null);
      }}
    />
  );
}

function BooleanSearchField({ onQueryChange }) {
  const [value, setValue] = useState(false);

  return (
    <FormControlLabel
      control={
        <Switch
          checked={value}
          onChange={() => {
            const val = !value;
            setValue(val);
            onQueryChange(val);
          }}
        />
      }
    />
  );
}

function TranslateSearchField({ options, onQueryChange }) {
  const { t } = useTranslate();
  const [value, setValue] = useState("");

  return (
    <TextField
      select
      variant="outlined"
      size="small"
      value={value}
      onChange={(e) => {
        const val = e.target.value;
        setValue(val);
        onQueryChange(val || null);
      }}
    >
      {options.map((option) => (
        <MenuItem key={option} value={option}>
          {t(option)}
        </MenuItem>
      ))}
    </TextField>
  );
}

export function ExcelExporterButton({ filename, data, headers }) {
  const { t } = useTranslate();

  // TODO
  // filter data by visible columns

  const handleExport = () => {
    console.log(data);
    // const fileType =
    //   "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const headerRow = headers.reduce(
      (acc, curr) => ({ ...acc, [curr]: t(curr) }),
      {}
    );
    const visibleData = data.data.map(pick(headers));
    const ws = XLSX.utils.json_to_sheet([headerRow, ...visibleData], {
      skipHeader: true,
    });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    XLSX.writeFile(wb, filename + ".xlsx");
    // const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    // const data = new Blob([excelBuffer], {type: fileType});
    // FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Tooltip title={t("export")}>
      <IconButton onClick={handleExport}>
        <CloudDownload />
      </IconButton>
    </Tooltip>
  );
}

function CreateLinkButton({ resource }) {
  return (
    <Portal>
      <Box position="fixed" bottom={24} right={24} className="no-print">
        <Zoom in style={{ transitionDelay: "250ms" }}>
          <Fab
            color="primary"
            component={RouterLink}
            to={`/s/${resource}/create`}
          >
            <Add />
          </Fab>
        </Zoom>
      </Box>
    </Portal>
  );
}
