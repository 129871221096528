import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "employee",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "employees.name",
  },
  {
    name: "rate",
    type: "translate",
    sortable: true,
    searchable: true,
    searchList: ["poor", "fair", "good", "excellent"],
  },
  {
    name: "starts",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "ends",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "notes",
    type: "text",
    sortable: true,
    searchable: true,
  },
];

export default function List({ title, query }) {
  return (
    <ResourceListPage
      url="evaluations"
      columns={columns}
      title={title}
      baseQuery={query}
    />
  );
}
