import React from "react";

const getDefaultValue = (item) => {
  if (item.type === "date") return "2020-01-01 12:00";
  if (item.type === "username") return "username";
  if (item.type === "counter") return "999";
  if (item.type === "daily") return "999";
  return "";
};

export default function Text({ item, value = null }) {
  return (
    <p
      style={{
        margin: "0",
        whiteSpace: "pre",
        fontSize: item.fontSize + "px",
        fontWeight: item.bold ? "bold" : "normal",
        fontStyle: item.italic ? "italic" : "normal",
        textDecoration: item.underlined ? "underline" : "none",
      }}
    >
      {item.value} {value || getDefaultValue(item)}
    </p>
  );
}
