import React from "react";
import FormTextField from "./FormTextField";

export function FormNumberField({ min = 0, step = 1, max, ...props }) {
  return (
    <FormTextField
      type="number"
      inputProps={{
        min: min,
        step: step,
        ...(max ? { max: max } : {}),
      }}
      {...props}
    />
  );
}
