import { Grid } from "@material-ui/core";
import { adjust, assoc, isEmpty } from "ramda";
import React, { useEffect } from "react";
import FormTextField from "./FormTextField";

export default function PrescriptionLines({
  label = "Rx",
  onChange,
  value = [],
}) {
  useEffect(() => {
    if (!Array.isArray(value)) {
      onChange([]);
      return;
    }
    const isFull = value.every((r) => !isEmpty(r.drug) && !isEmpty(r.dosage));
    if (isEmpty(value) || isFull) {
      onChange([...value, { drug: "", dosage: "", notes: "" }]);
    }
  }, [onChange, value]);

  const updateField = (index, field) => (val) =>
    onChange(adjust(index, assoc(field, val), value));

  return (
    <Grid item xs={12}>
      <div style={{ direction: "LTR" }}>
        <h3 style={{ marginBottom: 0 }}>{label}</h3>
        {Array.isArray(value) &&
          value.map((r, i) => (
            <Grid container spacing={2} key={i}>
              <FormTextField
                label="Drug"
                xs={4}
                grid={4}
                value={r.drug}
                onChange={updateField(i, "drug")}
              />
              <FormTextField
                label="Dosage"
                xs={4}
                grid={4}
                value={r.dosage}
                onChange={updateField(i, "dosage")}
              />
              <FormTextField
                label="Note"
                xs={4}
                grid={4}
                value={r.notes}
                onChange={updateField(i, "notes")}
              />
            </Grid>
          ))}
      </div>
    </Grid>
  );
}
