import React from "react";
import FormSelectField from "commons/components/FormSelectField";
import { FormDateTimeField } from "commons/components/FormDateField";
import { FormNumberField } from "commons/components/FormNumberField";
import dayjs from "dayjs";

export default function AttendanceRow({
  employees,
  model,
  updateModel,
  rules = {},
  label,
}) {
  const onEnterChange = (val) => {
    updateModel("enter")(val);
    if (model.exit) {
      updateModel("hours")(dayjs(model.exit).diff(dayjs(val), "hour"));
    }
  };

  const onExitChange = (val) => {
    updateModel("exit")(val);
    if (model.enter) {
      updateModel("hours")(dayjs(val).diff(dayjs(model.enter), "hour"));
    }
  };

  return (
    <>
      <FormSelectField
        grid={4}
        label={label}
        options={employees}
        value={model.employee_id}
        onChange={updateModel("employee_id")}
        error={rules.employee_id}
      />
      <FormDateTimeField
        grid={3}
        label="enter"
        value={model.enter}
        onChange={onEnterChange}
        error={rules.enter}
      />
      <FormDateTimeField
        grid={3}
        label="exit"
        value={model.exit}
        onChange={onExitChange}
        error={rules.exit}
      />
      <FormNumberField
        grid={2}
        label="hours"
        value={model.hours}
        onChange={updateModel("hours")}
        error={rules.hours}
        disabled
      />
    </>
  );
}
