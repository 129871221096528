export const arEG = {
  props: {
    MuiBreadcrumbs: {
      expandText: "Show path",
    },
    MuiTablePagination: {
      backIconButtonText: "الصفحة السابقة",
      labelRowsPerPage: "عنصر في الصفحة:",
      labelDisplayedRows: ({ from, to, count }) =>
        `${from}-${to} من ${count !== -1 ? count : `أكثر من ${to}`}`,
      nextIconButtonText: "الصفحة التالية",
    },
    MuiRating: {
      getLabelText: (value) => `${value} Star${value !== 1 ? "s" : ""}`,
      emptyLabelText: "Empty",
    },
    MuiAutocomplete: {
      clearText: "Clear",
      closeText: "Close",
      loadingText: "Loading…",
      noOptionsText: "No options",
      openText: "Open",
    },
    MuiAlert: {
      closeText: "Close",
    },
    MuiPagination: {
      "aria-label": "Pagination navigation",
      getItemAriaLabel: (type, page, selected) => {
        if (type === "page") {
          return `${selected ? "" : "Go to "}page ${page}`;
        }
        if (type === "first") {
          return "Go to first page";
        }
        if (type === "last") {
          return "Go to last page";
        }
        if (type === "next") {
          return "Go to next page";
        }
        if (type === "previous") {
          return "Go to previous page";
        }
        return undefined;
      },
    },
  },
};
