import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  stack: {
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  denseStack: {
    "& > * + *": {
      marginTop: theme.spacing(1),
    },
  },
}));

export default function Stack({ dense = false, children }) {
  const classes = useStyles();

  return (
    <div className={dense ? classes.denseStack : classes.stack}>{children}</div>
  );
}
