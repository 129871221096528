import React from "react";
import ReportManager from "commons/components/ReportManager";
import dayjs from "dayjs";

const filters = [
  {
    type: "filter",
    name: "users",
    key: "assigned_to",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "projects", key: "project_id" },
];

const columns = [
  { name: "started", type: "datetime" },
  { name: "finished", type: "datetime" },
  { name: "hour", type: "hours" },
  { name: "minute", type: "minutes" },
  { name: "actions", type: "text" },
  { name: "comment", type: "text" },
  { name: "task", type: "text" },
  { name: "user", type: "text" },
  { name: "project", type: "text" },
];

const recordsFn = (records) =>
  records.map((rec) => {
    if (rec.started && rec.finished) {
      const duration = dayjs(rec.finished).diff(dayjs(rec.started), "minute");
      return {
        ...rec,
        hour: duration,
        minute: duration,
      };
    }
    return { ...rec, hour: 0, minute: 0 };
  });

export default function TasksReport() {
  return (
    <ReportManager
      url="task-steps"
      title="tasksReport"
      columns={columns}
      recordsFunc={recordsFn}
      filterByDate
      dateField="started"
      filters={filters}
      totals={["hour", "minute"]}
    />
  );
}
