import React, { useState, useEffect } from "react";
import useTranslate from "commons/hooks/useTranslate";
import {
  Toolbar,
  Typography,
  IconButton,
  Box,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Tooltip,
} from "@material-ui/core";

import {
  ViewList,
  Add,
  Notifications,
  Lock,
  Save,
  History,
  FileCopy,
  Edit,
} from "@material-ui/icons";
import { Link as RouterLink, useHistory } from "react-router-dom";
import ResourceRemoveButton from "./ResourceRemoveButton";
import CardSection from "./CardSection";
import { FormDateTimeField } from "./FormDateField";
import FormTextField from "./FormTextField";
import useResourceMachine from "commons/hooks/useResourceMachine";
import LoadingIndicator from "./LoadingIndicator";
import api from "commons/helpers/api";
import ErrorAlert from "./ErrorAlert";
import dayjs from "dayjs";
import { omit } from "ramda";
import FormSelectField from "./FormSelectField";
import { PrintButton } from "./PrintManager";
import { DashboardShortcutPin } from "./DashboardShortcut";
import useQueryAllResources from "commons/hooks/useQueryAllResources";

export default function ResourceToolbar({
  title,
  current,
  send,
  accessGroup = false,
  notifications = false,
  logs = true,
  extras = null,
  route,
  onPrintRequest,
  printer = null,
  deleteURL = null,
}) {
  const { t } = useTranslate();
  const history = useHistory();
  const { model, url } = current.context;

  const onDuplicate = () => {
    send("RESET", { data: {} });
    history.push(
      `/s/${route || url}/create?model=${JSON.stringify(
        omit(["id", "reference", "code", "name"], model)
      )}`
    );
  };
  const onReset = () => {
    send("RESET", { data: {} });
    history.push(`/s/${route || url}/create`);
  };
  const goToList = () => history.push(`/s/${route || url}`);

  const hasResource = Boolean(model.id);
  const [showNotify, setShowNotify] = useState(false);
  const [showLogs, setShowLogs] = useState(false);

  return (
    <>
      <Toolbar className="resource-toolbar" disableGutters>
        <Typography style={{ flex: "1 1 100%" }} variant="h4">
          {t(title)}
        </Typography>
        <Box className="no-print" display="flex" alignItems="center">
          <Tooltip title={t("new")}>
            <IconButton id="resource-new-btn" onClick={onReset}>
              <Add />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("duplicate")}>
            <IconButton id="resource-duplicate-btn" onClick={onDuplicate}>
              <FileCopy />
            </IconButton>
          </Tooltip>
          <Tooltip title={t("list")}>
            <IconButton component={RouterLink} to={`/s/${route || title}`}>
              <ViewList />
            </IconButton>
          </Tooltip>
          {extras}
          <DashboardShortcutPin title={title} />
          {hasResource && (
            <>
              {notifications && (
                <Tooltip title={t("notifications")}>
                  <IconButton
                    onClick={() => {
                      setShowNotify((old) => !old);
                    }}
                  >
                    <Notifications />
                  </IconButton>
                </Tooltip>
              )}
              {logs && (
                <Tooltip title={t("history")}>
                  <IconButton
                    onClick={() => {
                      setShowLogs((old) => !old);
                    }}
                  >
                    <History />
                  </IconButton>
                </Tooltip>
              )}
              {printer || <PrintButton onPrintRequest={onPrintRequest} />}
              <ResourceRemoveButton
                url={deleteURL || url}
                resources={[model.id]}
                onResourcesRemoved={goToList}
              />
            </>
          )}
          {accessGroup && (
            <AccessGroupButton
              value={model.access_group_id}
              onChange={(value) => {
                send("UPDATE", { data: { access_group_id: value } });
              }}
            />
          )}
        </Box>
      </Toolbar>
      {showNotify && <NotificationManager id={model.id} url={url} />}
      {showLogs && (
        <AuditManager
          id={model.id}
          url={url}
          onSelect={(log) => send("OVERRIDE", { data: log.data })}
        />
      )}
    </>
  );
}

export function AccessGroupButton({ value = "", onChange }) {
  const { t } = useTranslate();
  const [showAccessGroup, setShowAccessGroup] = useState(true);
  const [groups] = useQueryAllResources("access-groups");

  const onAccessGroupChange = (value) => {
    onChange(value);
    window.localStorage.setItem("defaultAccessGroup", value);
  };

  return (
    <>
      <Tooltip title={t("access_groups")}>
        <IconButton onClick={() => setShowAccessGroup((old) => !old)}>
          <Lock />
        </IconButton>
      </Tooltip>
      {showAccessGroup && (
        <Box width={200}>
          <FormSelectField
            label={t("access-group")}
            options={groups}
            value={value}
            onChange={onAccessGroupChange}
          />
        </Box>
      )}
    </>
  );
}

function NotificationManager({ id, url }) {
  const { t } = useTranslate();
  const { model, updateModel, rules, send, current, error } =
    useResourceMachine(
      "notifications",
      "create",
      {
        resource_id: id,
        resource_type: url,
      },
      false
    );

  const isDirty = current.matches("dirty") || current.matches("invalid");
  const isLoading = current.matches("fetching") || current.matches("saving");
  const [users] = useQueryAllResources("users");

  useEffect(() => {
    api
      .service("notifications")
      .find({
        query: {
          resource_id: id,
          resource_type: url,
        },
      })
      .then((res) => {
        if (res.length > 0) {
          send("OVERRIDE", { data: res[0] });
        }
      });
  }, [id, url, send]);

  const onRemoveSuccess = () => {
    send("RESET", {
      data: {
        resource_id: id,
        resource_type: url,
      },
    });
  };

  return (
    <CardSection>
      <LoadingIndicator show={isLoading} />
      <ErrorAlert error={error} />
      <Typography variant="h6">{t("notification")}</Typography>
      <Grid container spacing={2}>
        <Grid item container sm spacing={2}>
          <FormSelectField
            grid={4}
            multiple
            disableCloseOnSelect
            label="users"
            options={users}
            optionLabel="username"
            value={model.users}
            onChange={updateModel("users")}
            error={rules.users}
          />
          <FormTextField
            grid={4}
            label="notes"
            value={model.notes}
            onChange={updateModel("notes")}
            error={rules.notes}
          />
          <FormDateTimeField
            grid={4}
            label="reminder"
            value={model.date}
            onChange={updateModel("date")}
            error={rules.date}
          />
        </Grid>
        <Grid item xs sm="auto">
          <IconButton onClick={() => send("SAVE")} disabled={!isDirty}>
            <Save />
          </IconButton>
          {model.id && (
            <ResourceRemoveButton
              url="notifications"
              resources={[model.id]}
              onResourcesRemoved={onRemoveSuccess}
            />
          )}
        </Grid>
      </Grid>
    </CardSection>
  );
}

export function AuditManager({ id, url, onSelect }) {
  const [logs] = useQueryAllResources("audit-logs", {
    query: {
      auditable_id: id,
      auditable_type: url,
    },
  });

  return (
    <CardSection p={1}>
      <List>
        {logs.map((log) => (
          <ListItem button key={log.id} onClick={() => onSelect(log)}>
            <ListItemIcon>
              {log.event === "create" ? <Add /> : <Edit />}
            </ListItemIcon>
            <ListItemText
              primary={`${log.user} - ${dayjs(log.date).format(
                "YYYY-MM-DD HH:mm"
              )}`}
            />
          </ListItem>
        ))}
      </List>
    </CardSection>
  );
}

// TODO relation with no audit right now
// Users
// Roles
// Access Groups

// add Salary lines
// All That Has SynMany, should have audti after action, not all
// History of Forms and Templates, some error happens
// Add to Accounts
