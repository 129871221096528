import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import { FormNumberField } from "commons/components/FormNumberField";
import { FormMoneyField } from "commons/components/FormMoneyField";
import ResourceRelations from "commons/components/ResourceRelations";

const base = "grades";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        grid={12}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormMoneyField
        grid={6}
        label="basic_salary"
        value={model.basic_salary}
        onChange={updateModel("basic_salary")}
        error={rules.basic_salary}
      />
      <FormNumberField
        grid={6}
        label="allowed_late_minutes"
        value={model.allowed_late_minutes}
        onChange={updateModel("allowed_late_minutes")}
        error={rules.allowed_late_minutes}
      />
      <LineManager value={model.lines} onChange={updateModel("lines")} />
    </ResourceSinglePage>
  );
}

function LineManager({ value, onChange }) {
  return (
    <ResourceRelations
      title="grade-lines"
      value={value}
      onChange={onChange}
      model={{
        applied: "always",
        name: "",
        value: "",
        min: 0,
        max: 0
      }}
      rowRenderer={(record, onItemChange) => (
        <>
          <FormTextField
            grid={3}
            label="name"
            value={record.name}
            onChange={onItemChange("name")}
          />
          <FormTextField
            grid={3}
            label="value"
            value={record.value}
            onChange={onItemChange("value")}
          />
          <FormMoneyField
            grid={3}
            label="min"
            value={record.min}
            onChange={onItemChange("min")}
          />
          <FormMoneyField
            grid={3}
            label="max"
            value={record.max}
            onChange={onItemChange("max")}
          />
        </>
      )}
    />
  );
}
