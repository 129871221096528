import React from "react";
import { Box } from "@material-ui/core";

export default function Center({ children, ...props }) {
  return (
    <Box
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      {...props}
    >
      {children}
    </Box>
  );
}
