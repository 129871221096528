import { useContext, useMemo } from "react";
import { FrontDeskContext } from "commons/helpers/contexts";
import { groupBy, prop } from "ramda";
import useQueryAllResources from "./useQueryAllResources";

export default function useFrontDeskContext() {
  const { products } = useContext(FrontDeskContext);
  const [customers] = useQueryAllResources("customers");
  const [suppliers] = useQueryAllResources("suppliers");
  const [stockLevels] = useQueryAllResources("stock-levels");

  const catalog = useMemo(() => {
    const saleables = products.filter((p) => p.saleable);
    const purchasables = products.filter((p) => p.purchasable);
    const saleable = groupBy(prop("facility_id"), saleables);
    const purchasable = groupBy(prop("facility_id"), purchasables);
    return {
      saleable: { ...saleable, all: saleables },
      purchasable: { ...purchasable, all: purchasables },
    };
  }, [products]);

  return {
    products,
    catalog,
    customers,
    suppliers,
    stockLevels: groupBy(prop("product_id"), stockLevels),
  };
}
