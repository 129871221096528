import React from "react";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";

export default function Select({ item, onChange }) {
  const [options] = useResourcesByQuery(item.service, true);
  return (
    <FormSelectField
      label={item.label}
      value={item.value}
      options={options}
      onChange={(e) => onChange({ ...item, value: e })}
    />
  );
}
