const { parseValue } = require("commons/helpers/utils");

export const calcDiscount = (amount, qty, discount) => {
  const { value, min_quantity, min_price, max_value } = discount;
  if (qty < Number(min_quantity) || amount < Number(min_price)) return 0;
  return parseValue(value, amount, 0, Number(max_value));
};

export const calcTax = (amount, tax) => {
  const { value, type, min_price, max_value } = tax;
  if (amount < Number(min_price)) return 0;
  const applied = parseValue(value, amount, 0, Number(max_value));
  if (type === "minimum_charge") {
    return amount > applied ? 0 : applied - amount;
  }
  return applied;
};

export const calcCommission = (amount, commission) => {
  const { value, min_price, max_value } = commission;
  if (amount < Number(min_price)) return 0;
  return parseValue(value, amount, 0, Number(max_value));
};
