import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormFileField from "commons/components/FormFileField";
import { Grid } from "@material-ui/core";

const base = "messages";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules,
  } = useControlledResourcePage(base, {}, false);
  const [users] = useResourcesByQuery("users", true);
  const isSaved = Boolean(model.id);

  return (
    <ResourceSinglePage title={base} current={current} send={send}>
      <FormSelectField
        options={users}
        label="recipient"
        optionLabel="username"
        value={model.recipient}
        onChange={updateModel("recipient")}
        error={rules.recipient}
        disabled={isSaved}
      />
      <FormTextField
        multiline
        rows={5}
        rowsMax={8}
        label="body"
        value={model.body}
        onChange={updateModel("body")}
        error={rules.body}
        InputProps={{
          readOnly: isSaved,
        }}
      />
      <Grid item sm={12} xs={12}>
        <FormFileField
          label="attachment"
          value={model.attachment}
          onChange={updateModel("attachment")}
          error={rules.attachment}
          disabled={isSaved}
          preview
        />
      </Grid>
    </ResourceSinglePage>
  );
}
