import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceEditor from "commons/components/ResourceEditor";
import useResourceMachine from "commons/hooks/useResourceMachine";
import { Grid, Toolbar, Typography, IconButton } from "@material-ui/core";
import FormTextField from "commons/components/FormTextField";
import FormSwitch from "commons/components/FormSwitch";
import useTranslate from "commons/hooks/useTranslate";
import { Add, Remove, Edit } from "@material-ui/icons";
import ResourceRemoveButton from "commons/components/ResourceRemoveButton";
import { AccessGroupButton } from "commons/components/ResourceToolbar";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import { TreeView, TreeItem } from "@material-ui/lab";

const base = "accounts";
const baseModel = { active: true };

export default function AccountsManager() {
  const { t } = useTranslate();
  const { current, send, model, updateModel, rules } = useResourceMachine(
    base,
    "create",
    baseModel
  );
  const hasResource = Boolean(model.id);
  const onReset = () => send("RESET", { data: { active: true } });
  const onEditAccount = (id) => send("OVERRIDE", { data: { id } });

  return (
    <PageCard>
      {/* {JSON.stringify(model)} */}
      <Toolbar disableGutters>
        <Typography style={{ flex: "1 1 100%" }} variant="h4">
          {t("accounts")}
        </Typography>
        <IconButton onClick={onReset}>
          <Add />
        </IconButton>
        {hasResource && (
          <ResourceRemoveButton
            url="accounts"
            resources={[model.id]}
            onResourcesRemoved={onReset}
          />
        )}
        <AccessGroupButton value={model.access_group_id} send={send} />
      </Toolbar>
      <ResourceEditor current={current} send={send}>
        <Grid item xs sm={4}>
          <AccountTree
            value={model.parent_account_id}
            onChange={updateModel("parent_account_id")}
            onEdit={onEditAccount}
          />
        </Grid>
        <Grid item container spacing={2} xs sm={8}>
          <FormTextField
            grid={3}
            label="code"
            value={model.code}
            onChange={updateModel("code")}
            error={rules.code}
          />
          <FormTextField
            grid={9}
            label="name"
            value={model.name}
            onChange={updateModel("name")}
            error={rules.name}
          />
          <FormSwitch
            label="active"
            value={model.active}
            onChange={updateModel("active")}
          />
          <FormTextField
            grid={12}
            label="notes"
            value={model.notes}
            onChange={updateModel("notes")}
            error={rules.notes}
          />
        </Grid>
      </ResourceEditor>
    </PageCard>
  );
}

function AccountTree({ value = null, onChange, onEdit }) {
  const [accounts] = useResourcesByQuery("accounts", true);

  const handleSelect = (event, nodeIds) => {
    onChange(parseInt(nodeIds));
  };

  const roots = accounts.filter((acc) => acc.parent_account_id === null);

  return (
    <TreeView
      defaultCollapseIcon={<Remove />}
      defaultExpandIcon={<Add />}
      onNodeSelect={handleSelect}
    >
      {roots.map((acc) => (
        <TreeBranch
          key={acc.id}
          account={acc}
          accounts={accounts}
          onEdit={onEdit}
        />
      ))}
    </TreeView>
  );
}

function TreeBranch({ account, accounts, onEdit }) {
  const myAccounts = accounts.filter(
    (acc) => acc.parent_account_id === account.id
  );

  return (
    <div style={{ background: "rgba(0,0,0,0.025)" }}>
      <TreeItem
        nodeId={`${account.id}`}
        label={
          <div style={{ display: "flex", padding: "8px" }}>
            <Typography variant="subtitle1">
              {`(${account.code}) ${account.name}`}
            </Typography>
            <IconButton
              size="small"
              edge="start"
              onClick={(e) => {
                e.stopPropagation();
                onEdit(account.id);
              }}
            >
              <Edit />
            </IconButton>
          </div>
        }
      >
        {myAccounts.map((acc) => (
          <TreeBranch
            key={acc.id}
            account={acc}
            accounts={accounts}
            onEdit={onEdit}
          />
        ))}
      </TreeItem>
    </div>
  );
}
