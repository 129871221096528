import React, { useEffect, useCallback } from "react";
import CardSection from "./CardSection";
import { Toolbar, Typography, IconButton, Grid } from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import useTranslate from "commons/hooks/useTranslate";
import { v4 as uuidv4 } from "uuid";
import { adjust, assoc, remove, mergeLeft } from "ramda";

export default function ResourceRelations({
  title,
  value = [],
  onChange,
  model = {},
  rowRenderer,
  header = null,
  footer = null,
  isRecordFull = null,
  disableRemove = false,
  autoAddOnFull = true,
  // onItemAddClicked = () => {},
}) {
  const { t } = useTranslate();

  const addItem = useCallback(() => {
    onChange([
      ...value,
      {
        id: uuidv4(),
        ...(model instanceof Function ? model() : model),
      },
    ]);
    // onItemAddClicked();
  }, [model, onChange, value]);

  const onItemChange = (index) => (field) => (val) => {
    onChange(adjust(index, assoc(field, val), value));
  };

  const onItemMerge = (index) => (obj) => {
    onChange(adjust(index, mergeLeft(obj), value));
  };

  const onRemove = (index) => {
    onChange(remove(index, 1, value));
  };

  useEffect(() => {
    if (!isRecordFull || value.length === 0 || !autoAddOnFull) return;
    const isFull = value.every(isRecordFull);
    if (isFull) addItem();
  }, [isRecordFull, value, addItem, autoAddOnFull]);

  return (
    <Grid item xs={12}>
      <CardSection>
        <Toolbar disableGutters>
          <Typography style={{ flex: "1 1 100%" }} variant="h5">
            {t(title)}
          </Typography>
          <IconButton size="small" onClick={addItem}>
            <Add />
          </IconButton>
        </Toolbar>
        {header && <header>{header}</header>}
        {value.map((record, index) => (
          <Grid container spacing={2} key={record.id}>
            <Grid item sm>
              <Grid container spacing={2}>
                {rowRenderer(
                  record,
                  onItemChange(index),
                  onItemMerge(index),
                  index
                )}
              </Grid>
            </Grid>
            <Grid item xs sm="auto">
              <IconButton
                size="small"
                disabled={disableRemove}
                onClick={() => onRemove(index)}
              >
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        ))}
        {footer && <footer>{footer}</footer>}
      </CardSection>
    </Grid>
  );
}
