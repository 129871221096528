import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import TemplateBuilder from "./TemplateBuilder";
import useTranslate from "commons/hooks/useTranslate";
import { FormNumberField } from "commons/components/FormNumberField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import FormSwitch from "commons/components/FormSwitch";

const base = "print-templates";

export default function Single() {
  const { t } = useTranslate();
  const { current, send, model, updateModel, rules } =
    useControlledResourcePage(base, { counter: 0, general: true });
  const [languages] = useResourcesByQuery("languages", true);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormSelectField
        grid={3}
        label="language"
        options={languages}
        value={model.language_id}
        onChange={updateModel("language_id")}
        error={rules.language_id}
      />
      <FormNumberField
        grid={3}
        label="counter"
        value={model.counter}
        onChange={updateModel("counter")}
        error={rules.counter}
      />
      <FormNumberField
        grid={2}
        label="content_font_size"
        value={model.content_font_size}
        onChange={updateModel("content_font_size")}
        error={rules.content_font_size}
      />
      <FormNumberField
        grid={2}
        label="content_line_height"
        value={model.content_line_height}
        onChange={updateModel("content_line_height")}
        error={rules.content_line_height}
      />
      <FormNumberField
        grid={2}
        label="top"
        value={model.margin_top}
        onChange={updateModel("margin_top")}
        error={rules.margin_top}
      />
      <FormNumberField
        grid={2}
        label="bottom"
        value={model.margin_bottom}
        onChange={updateModel("margin_bottom")}
        error={rules.margin_bottom}
      />
      <FormNumberField
        grid={2}
        label="left"
        value={model.margin_left}
        onChange={updateModel("margin_left")}
        error={rules.margin_left}
      />
      <FormNumberField
        grid={2}
        label="right"
        value={model.margin_right}
        onChange={updateModel("margin_right")}
        error={rules.margin_right}
      />

      <FormSwitch
        grid={3}
        label="general"
        value={model.general}
        onChange={updateModel("general")}
      />
      <FormSwitch
        grid={3}
        label="stockable"
        value={model.stocks}
        onChange={updateModel("stocks")}
      />
      <FormSwitch
        grid={3}
        label="sales"
        value={model.sales}
        onChange={updateModel("sales")}
      />
      <FormSwitch
        grid={3}
        label="purchases"
        value={model.purchases}
        onChange={updateModel("purchases")}
      />
      <FormSwitch
        grid={3}
        label="transactions"
        value={model.transactions}
        onChange={updateModel("transactions")}
      />
      <FormSwitch
        grid={3}
        label="reports"
        value={model.reports}
        onChange={updateModel("reports")}
      />
      <FormSwitch
        grid={3}
        label="group_by_category"
        value={model.group_by_category}
        onChange={updateModel("group_by_category")}
      />
      <FormSwitch
        grid={3}
        label="print_daily_counter"
        value={model.print_daily_counter}
        onChange={updateModel("print_daily_counter")}
      />
      <h3>{t("header")}</h3>
      <TemplateBuilder
        value={
          typeof model.header === "string"
            ? JSON.parse(model.header)
            : model.header || []
        }
        onChange={(val) => updateModel("header")(JSON.stringify(val))}
        error={rules.header}
      />
      <h3>{t("footer")}</h3>
      <TemplateBuilder
        value={
          typeof model.footer === "string"
            ? JSON.parse(model.footer)
            : model.footer || []
        }
        onChange={(val) => updateModel("footer")(JSON.stringify(val))}
        error={rules.footer}
      />
    </ResourceSinglePage>
  );
}
