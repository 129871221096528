import React, { useState } from "react";
import { compose, assoc, __ } from "ramda";
import FormSelectField from "commons/components/FormSelectField";
import { Grid } from "@material-ui/core";
import FormTextField from "commons/components/FormTextField";
import { FormDateTimeField } from "commons/components/FormDateField";

const saveDefaultFacility = (obj) => {
  window.localStorage.setItem("fdDefaultFacility", obj.facility_id);
  return obj;
};

export default function DataCard({
  compact = false,
  model,
  onChange,
  facilities,
  products,
  onProductSelect,
  rules,
}) {
  const [code, setCode] = useState("");
  const [name, setName] = useState("");
  const onFacilityChange = compose(
    onChange,
    assoc("lines", []),
    saveDefaultFacility,
    assoc("facility_id", __, model)
  );

  const updateField = (field) => compose(onChange, assoc(field, __, model));

  const onCodeChange = (e, value) => {
    setCode(value);
  };
  const onNameChange = (e, value) => {
    setName(value);
  };

  const onProductChosen = (product_id) => {
    const qty =
      code.length === 13 &&
      code.startsWith("20") &&
      code.substring(7, 12) !== "00000"
        ? Number(code.substring(7, 12)) / 1000
        : 1;
    setCode("");
    setName("");
    onProductSelect(product_id, qty);
  };

  const sortOptions = (a, b) => a.code.length - b.code.length;

  const filterOptions = (options, state) => {
    const input = state.inputValue;
    if (input.trim() === "") return options;
    const myOptions = options.filter((e) => e.code.startsWith(input));
    if (
      myOptions.length === 0 &&
      input.length === 13 &&
      input.startsWith("20")
    ) {
      return options
        .filter((e) => e.code.startsWith(Number(input.substring(2, 7))))
        .sort(sortOptions);
    }
    return myOptions.sort(sortOptions);
  };

  return (
    <Grid container spacing={2}>
      <FormSelectField
        grid={3}
        label="facility"
        options={facilities}
        value={model.facility_id}
        onChange={onFacilityChange}
        error={rules.facility_id}
      />
      <FormTextField
        grid={3}
        label="reference"
        value={model.reference}
        onChange={updateField("reference")}
        error={rules.reference}
      />
      {!compact && (
        <FormSelectField
          grid={6}
          label="searchByCode"
          options={products}
          optionLabel="code"
          optionKey="product_id"
          value={null}
          inputValue={code}
          onInputChange={onCodeChange}
          onChange={onProductChosen}
          filterOptions={filterOptions}
          autoHighlight
          id="fd-code-search"
        />
      )}
      <FormDateTimeField
        grid={6}
        label="date"
        value={model.date}
        onChange={updateField("date")}
        error={rules.date}
      />
      {!compact && (
        <FormSelectField
          grid={6}
          label="searchByName"
          options={products}
          optionKey="product_id"
          value={null}
          onChange={onProductChosen}
          inputValue={name}
          onInputChange={onNameChange}
          autoHighlight
        />
      )}
    </Grid>
  );
}
