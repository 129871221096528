import React, { useState } from "react";
import useTranslate from "commons/hooks/useTranslate";
import api from "commons/helpers/api";
import { TextField, Box, Zoom, Grid, Fab } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import PageCard from "commons/components/PageCard";
import LoadingIndicator from "commons/components/LoadingIndicator";
import ErrorAlert from "commons/components/ErrorAlert";

export default function ChangePassword() {
  const { t } = useTranslate();
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const onSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    api
      .service("change-password")
      .create({
        password,
        oldPassword,
        passwordConfirm,
      })
      .then(() => {
        setOldPassword("");
        setPassword("");
        setPasswordConfirm("");
      })
      .catch(setError)
      .finally((_) => setLoading(false));
  };

  const disabled =
    oldPassword === "" || password === "" || passwordConfirm === "";

  return (
    <PageCard>
      <LoadingIndicator show={loading} />
      <ErrorAlert error={error} />
      <h2>{t("changePassword")}</h2>
      <form onSubmit={onSubmit} autoComplete="off">
        <Grid container spacing={2}>
          <Grid item sm={4}>
            <TextField
              type="password"
              label={t("oldPassword")}
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              type="password"
              label={t("password")}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete="new-password"
            />
          </Grid>
          <Grid item sm={4}>
            <TextField
              type="password"
              name="passwordConfirm"
              label={t("passwordConfirm")}
              value={passwordConfirm}
              onChange={(e) => setPasswordConfirm(e.target.value)}
              autoComplete="new-password"
            />
          </Grid>
        </Grid>
        <Box position="fixed" bottom={24} right={24}>
          <Zoom in style={{ transitionDelay: "500ms" }}>
            <Fab disabled={disabled} type="submit" color="primary">
              <Save />
            </Fab>
          </Zoom>
        </Box>
      </form>
    </PageCard>
  );
}
