import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "name",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "asset_group",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "asset_groups.name",
  },
  {
    name: "description",
    type: "text",
    sortable: true,
    searchable: true,
  },
  {
    name: "acquired",
    type: "date",
    sortable: true,
    searchable: true,
  },
  {
    name: "disposed",
    type: "date",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="assets" columns={columns} />;
}
