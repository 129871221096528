import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from "@material-ui/core";
import { AccountCircle } from "@material-ui/icons";
import FormSelectField from "commons/components/FormSelectField";
import FormTextField from "commons/components/FormTextField";
import { DialogIframe } from "commons/pages/sales/components/FrontDeskPage";
import useTranslate from "commons/hooks/useTranslate";
import { assoc, compose, propEq } from "ramda";
import api from "commons/helpers/api";

export const saveDefaultEntity = (key, field) => (obj) => {
  window.localStorage.setItem(key, obj[field]);
  return obj;
};

export const updateLinesPrices = (base, products, policies) => (model) => {
  if (model.policy_id) {
    const lines = model.lines.map((line) => {
      const policy = policies.find(
        (pol) =>
          pol.policy_id === model.policy_id &&
          pol.product_id === line.product_id
      );
      if (policy) {
        return { ...line, price: policy.price };
      } else {
        const isSameProduct = propEq("product_id", line.product_id);
        const product = products.find(isSameProduct);
        return {
          ...line,
          price:
            product[base === "customers" ? "sale_price" : "purchase_price"],
        };
      }
    });
    return { ...model, lines };
  }
};

const updateModelCurrency = (policies, currencies) => (model) => {
  if (model.policy_id) {
    const policy = policies.find((pol) => pol.id === model.policy_id);
    if (policy) {
      const currency = currencies.find((c) => policy.currency_id === c.id);
      if (currency && model.currency_id !== currency.id) {
        return {
          ...model,
          currency_id: policy.currency_id,
          rate: currency.rate,
        };
      }
    }
  }
  return model;
};

export default function EntityCard({
  compact = false,
  phoneSearch = false,
  isCreating = false,
  matchFacility = false,
  model,
  onChange,
  base,
  rules,
  policies = [],
  productPolicies = [],
  products = [],
  entities = [],
  currencies = [],
}) {
  const { t } = useTranslate();
  const [open, setOpen] = useState(false);
  const [balance, setBalance] = useState(0);
  const field = useMemo(
    () => (base === "customers" ? "customer_id" : "supplier_id"),
    [base]
  );
  const defaultKey = useMemo(
    () => (base === "customers" ? "fdDefaultCustomer" : "fdDefaultSupplier"),
    [base]
  );

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  const filteredEntities = useMemo(() => {
    if (!matchFacility) return entities;
    return entities.filter((e) => e.facility_id === model.facility_id);
  }, [entities, matchFacility, model.facility_id]);

  const onEntityChange = useCallback(
    (entity) => {
      compose(
        onChange,
        updateModelCurrency(policies, currencies),
        updateLinesPrices(base, products, productPolicies),
        saveDefaultEntity(defaultKey, field),
        assoc("policy_id", entity.policy_id),
        assoc(field, entity.id)
      )(model);
    },
    [
      field,
      model,
      defaultKey,
      onChange,
      base,
      products,
      policies,
      productPolicies,
      currencies,
    ]
  );

  const selectEntityById = useCallback(
    (id) => {
      const entity = filteredEntities.find((rec) => rec.id === id);
      if (entity) {
        onEntityChange(entity);
      }
    },
    [filteredEntities, onEntityChange]
  );

  const entity = useMemo(
    () => filteredEntities.find((rec) => rec.id === model[field]),

    [filteredEntities, field, model]
  );

  useEffect(() => {
    if (entity) {
      api
        .service("balance")
        .get(entity.id, { query: { type: base } })
        .then((res) => setBalance(res.balance));
    }
  }, [entity, base]);

  useEffect(() => {
    if (isCreating && !model[field] && !model.policy_id) {
      const id = parseInt(window.localStorage.getItem(defaultKey)) || null;
      selectEntityById(id);
    }
  }, [isCreating, model, field, defaultKey, selectEntityById]);

  const showLimit = useMemo(
    () => entity && entity.credit_limit !== 0,
    [entity]
  );

  useEffect(() => {
    const handler = (event) => {
      if (event.data[field]) {
        onEntityChange({
          id: event.data[field],
          policy_id: event.data.policy_id,
        });
      }
    };
    window.addEventListener("message", handler);
    return () => {
      window.removeEventListener("message", handler);
    };
  }, [onEntityChange, field]);

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs>
          <Grid container spacing={1}>
            {phoneSearch ? (
              <FormSelectField
                key="phone"
                grid={6}
                label="phone"
                options={filteredEntities}
                value={model[field]}
                onChange={selectEntityById}
                optionLabel="primary_phone"
                error={rules[field]}
                autoHighlight
              />
            ) : (
              <FormSelectField
                key="code"
                grid={4}
                label="code"
                options={filteredEntities}
                value={model[field]}
                onChange={selectEntityById}
                optionLabel="id"
                error={rules[field]}
                autoHighlight
              />
            )}
            <FormSelectField
              grid={phoneSearch ? 6 : 8}
              label={field}
              options={filteredEntities}
              value={model[field]}
              onChange={selectEntityById}
              error={rules[field]}
              autoHighlight
            />
          </Grid>
        </Grid>
        {model[field] && (
          <Grid item xs="auto">
            <Box pt={1}>
              <IconButton size="small" onClick={handleDialogOpen}>
                <AccountCircle size="small" />
              </IconButton>
            </Box>
          </Grid>
        )}
        {!compact && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <FormTextField
                grid={4}
                label="policy"
                value={entity?.policy}
                disabled
              />
              <FormTextField
                grid={4}
                label="credit_limit"
                value={showLimit ? entity.credit_limit + balance : 0}
                disabled
              />
              <FormTextField
                grid={4}
                label="balance"
                value={showLimit ? balance : 0}
                disabled
              />
            </Grid>
          </Grid>
        )}
      </Grid>

      <Dialog onClose={handleDialogClose} open={open} maxWidth="xl" fullWidth>
        <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
          {t(base)}
        </DialogTitle>
        <DialogContent dividers>
          <DialogIframe base={base} path={model[field]} />
        </DialogContent>
      </Dialog>
    </div>
  );
}
