import React from "react";
import { Box, Paper, Grow } from "@material-ui/core";
import { useIsFetching } from "react-query";
import LoadingIndicator from "commons/components/LoadingIndicator";

export default function PageCard({ children }) {
  const isFetching = useIsFetching();

  return (
    <>
      <LoadingIndicator show={isFetching} />
      <Grow in style={{ transformOrigin: "50% 0 0" }}>
        <Paper elevation={4}>
          <Box p={2}>{children}</Box>
        </Paper>
      </Grow>
    </>
  );
}
