import React, { useEffect, useState } from "react";
import ErrorAlert from "commons/components/ErrorAlert";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import useTranslate from "commons/hooks/useTranslate";
import {
  Tooltip,
  IconButton,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { AccountTree, AddBox, ViewCompact } from "@material-ui/icons";

export default function FrontdeskPage({
  error,
  base,
  current,
  send,
  children,
  showCategories,
  onShowCategoriesChange,
  compactView,
  onCompactViewChange,
}) {
  const { t } = useTranslate();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuChoose = (resource) => {
    setSelected(resource);
    handleMenuClose();
    handleDialogOpen();
  };

  const handleDialogOpen = () => {
    setOpen(true);
  };
  const handleDialogClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ErrorAlert
        error={
          error
            ? {
                message: `${t(error.split(" ")[0])} ${
                  error.split(" ")[1] ? error.split(" ")[1] : ""
                }`,
              }
            : error
        }
      />
      <ResourceSinglePage
        title={base}
        current={current}
        send={send}
        accessGroup
        toolbarIcons={
          <>
            <Tooltip title={t("compactView")}>
              <IconButton
                onClick={() => {
                  onCompactViewChange(!compactView);
                  window.localStorage.setItem("fdCompactView", !compactView);
                }}
              >
                <ViewCompact />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("categories")}>
              <IconButton
                onClick={() => {
                  onShowCategoriesChange(!showCategories);
                  window.localStorage.setItem(
                    "fdShowCategories",
                    !showCategories
                  );
                }}
              >
                <AccountTree />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("fastAdd")}>
              <IconButton onClick={handleMenuClick}>
                <AddBox />
              </IconButton>
            </Tooltip>
          </>
        }
      >
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
        >
          <MenuItem
            onClick={() =>
              handleMenuChoose(base === "sales" ? "customers" : "suppliers")
            }
          >
            {base === "sales" ? t("customer") : t("supplier")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("products")}>
            {t("product")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("discounts")}>
            {t("discount")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("taxes")}>
            {t("tax")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("employees")}>
            {t("employee")}
          </MenuItem>
          <MenuItem onClick={() => handleMenuChoose("contractors")}>
            {t("contractor")}
          </MenuItem>
        </Menu>
        <Dialog onClose={handleDialogClose} open={open} maxWidth="xl" fullWidth>
          <DialogTitle id="customized-dialog-title" onClose={handleDialogClose}>
            {t(selected)}
          </DialogTitle>
          <DialogContent dividers>
            <DialogIframe base={selected} />
          </DialogContent>
        </Dialog>
        {children}
      </ResourceSinglePage>
    </>
  );
}

export function DialogIframe({ base, path = "create" }) {
  const { t } = useTranslate();

  useEffect(() => {
    const iframeEl = document.getElementById("fastAdd-iframe");
    const handler = () => {
      iframeEl.contentDocument.body.classList.add("iframe-embeded");
    };
    iframeEl.addEventListener("load", handler);
    return () => {
      iframeEl.removeEventListener("load", handler);
    };
  }, []);

  return (
    <iframe
      id="fastAdd-iframe"
      className="frontdesk-iframe"
      style={{ margin: "1%" }}
      width="98%"
      height="96%"
      src={`/s/${base}/${path}`}
      title={t(base)}
    />
  );
}
