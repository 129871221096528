import React from "react";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import Editor from "./Editor";

const base = "products";

const initialModel = {
  active: true,
  stockable: false,
  purchasable: false,
  saleable: true,
  type: "COURSE",
  image: "service.png",
};

export default function Single() {
  const { current, send, updateModel } = useControlledResourcePage(
    base,
    initialModel,
    true,
    "groups"
  );
  return (
    <ResourceSinglePage
      title="groups"
      route="groups"
      current={current}
      send={send}
      accessGroup
    >
      <Editor current={current} send={send} updateModel={updateModel} />
    </ResourceSinglePage>
  );
}
