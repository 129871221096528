import React from "react";
import ResourceListPage from "commons/components/ResourceListPage";

const columns = [
  {
    name: "service",
    label: "group",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "products.name",
  },
  {
    name: "facility",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "facilities.name",
  },
  {
    name: "service_location",
    type: "text",
    sortable: true,
    searchable: true,
    searchKey: "service_locations.name",
  },
  {
    name: "starts",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
  {
    name: "ends",
    type: "datetime",
    sortable: true,
    searchable: true,
  },
];

export default function List() {
  return <ResourceListPage url="sessions" columns={columns} />;
}
