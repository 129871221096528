import React from "react";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import {
  PrintButton,
  PrintTemplate,
  usePrintManager,
} from "commons/components/PrintManager";
import usePrintTemplates from "commons/hooks/usePrintTemplates";
import useTranslate from "commons/hooks/useTranslate";
import dayjs from "dayjs";
import { sumField } from "commons/helpers/utils";
import { Fragment } from "react";

const useStyles = makeStyles((theme) => ({
  highlight: {
    background: "rgba(0,0,0,0.1)",
  },
  strip: {
    background: "rgba(0,0,0,0.03)",
  },
}));

export default function TransactionPrinter({ model }) {
  const { t } = useTranslate();
  const { active, onPrintRequest, onPrintCompleted } = usePrintManager();
  const { translate } = usePrintTemplates();
  const classes = useStyles();

  return (
    <>
      <PrintButton
        onPrintRequest={onPrintRequest}
        filterFunc={(item) => item.transactions === true}
      />
      <div style={{ display: "none" }}>
        <PrintTemplate
          active={active}
          onPrintCompleted={onPrintCompleted}
          content={(language) => (
            <div>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      {translate(language, "labels", 1, t("reference_number"))}:{" "}
                      {model.id}
                    </TableCell>
                    <TableCell>
                      {`${translate(language, "labels", 20, t("date"))} : 
                      ${dayjs(model.created).format("YYYY-MM-DD")}`}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {model && model.entries && (
                <Table>
                  <TableHead className={classes.highlight}>
                    <TableRow>
                      <TableCell>
                        {translate(language, "labels", 12, t("code"))}
                      </TableCell>
                      <TableCell>
                        {translate(language, "labels", 13, t("account"))}
                      </TableCell>
                      <TableCell>
                        {translate(language, "labels", 14, t("currency"))}
                      </TableCell>
                      <TableCell>
                        {translate(language, "labels", 15, t("rate"))}
                      </TableCell>
                      <TableCell>
                        {translate(language, "labels", 16, t("debit"))}
                      </TableCell>
                      <TableCell>
                        {translate(language, "labels", 17, t("credit"))}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {model.entries.map((entry) => (
                      <Fragment key={entry.id}>
                        <TableRow>
                          <TableCell>{entry.code}</TableCell>
                          <TableCell>{entry.name}</TableCell>
                          <TableCell>{entry.currency}</TableCell>
                          <TableCell>{entry.rate / 100}</TableCell>
                          <TableCell>{entry.debit / 100}</TableCell>
                          <TableCell>{entry.credit / 100}</TableCell>
                        </TableRow>
                        {entry.note && (
                          <TableRow>
                            <TableCell colSpan="6">
                              <Typography variant="body1">
                                {entry.note}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                      </Fragment>
                    ))}
                    <TableRow>
                      <TableCell colSpan="4" />
                      <TableCell className={classes.highlight}>
                        <Typography variant="body1" noWrap>
                          <strong>
                            {sumField("debit")(model.entries) / 100}
                          </strong>
                        </Typography>
                      </TableCell>
                      <TableCell className={classes.highlight}>
                        <Typography variant="body1" noWrap>
                          <strong>
                            {sumField("credit")(model.entries) / 100}
                          </strong>
                        </Typography>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={6}>
                        {translate(language, "labels", 18, t("statement"))} :{" "}
                        {model.notes}
                      </TableCell>
                      {/* <TableCell></TableCell>
                      <TableCell></TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </div>
          )}
        />
      </div>
    </>
  );
}
