import { FrontDeskContext } from "commons/helpers/contexts";
import { prop, uniqBy } from "ramda";
import { useContext, useMemo } from "react";
import useQueryAllResources from "./useQueryAllResources";

export default function useProductDiscounts() {
  const { products } = useContext(FrontDeskContext);
  const [discountData] = useQueryAllResources("discounts");
  const [discountProduct] = useQueryAllResources("discount-product");

  const uniqProducts = useMemo(() => {
    return uniqBy(prop("product_id"), products);
  }, [products]);

  const discountsById = useMemo(() => {
    return discountData.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.id]: curr,
      }),
      {}
    );
  }, [discountData]);

  const productDiscounts = useMemo(() => {
    return discountProduct.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.product_id]: discountsById[curr.discount_id],
      };
    }, {});
  }, [discountProduct, discountsById]);

  return {
    uniqProducts,
    productDiscounts,
  };
}
