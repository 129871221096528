import React from "react";
import { Portal, Box, LinearProgress } from "@material-ui/core";

export default function LoadingIndicator({ show = false }) {
  return show ? (
    <Portal>
      <Box position="fixed" top={0} right={0} left={0} zIndex="snackbar">
        <LinearProgress color="secondary" />
      </Box>
    </Portal>
  ) : null;
}
