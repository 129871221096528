import React from "react";
import ResourceSinglePage from "commons/components/ResourceSinglePage";
import FormTextField from "commons/components/FormTextField";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";

const base = "leave-types";

export default function Single() {
  const {
    current,
    send,
    model,
    updateModel,
    rules
  } = useControlledResourcePage(base);

  return (
    <ResourceSinglePage title={base} current={current} send={send} accessGroup>
      <FormTextField
        grid={6}
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="annual_allowed"
        value={model.annual_allowed}
        onChange={updateModel("annual_allowed")}
        error={rules.annual_allowed}
        type="number"
        min="1"
      />
      <FormTextField
        label="description"
        value={model.description}
        onChange={updateModel("description")}
        error={rules.description}
      />
    </ResourceSinglePage>
  );
}
