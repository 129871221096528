import Heading from "./Heading";
import Textfield from "./Textfield";
import Textarea from "./Textarea";
import Datefield from "./Datefield";
import SingleChoose from "./SingleChoose";
import MultiChoose from "./MultiChoose";
import Table from "./Table";
import Select from "./Select";
import Upload from "./Upload";
import Prescription from "./Prescription";
import PrescriptionLines from "./PrescriptionLines";

const ComponentsMap = {
  heading: Heading,
  textfield: Textfield,
  textarea: Textarea,
  datefield: Datefield,
  singlechoose: SingleChoose,
  multichoose: MultiChoose,
  table: Table,
  select: Select,
  upload: Upload,
  prescription: Prescription,
  prescriptionlines: PrescriptionLines,
};

export {
  ComponentsMap,
  Heading,
  Textfield,
  Textarea,
  Datefield,
  SingleChoose,
  MultiChoose,
  Table,
  Select,
  Upload,
  Prescription,
};
