import React from "react";
import { TextField } from "@material-ui/core";
// import TextField from "/commons/components/TextField";

export default function Textfield({ item, onChange }) {
  return (
    <TextField
      label={item.label}
      value={item.value}
      onChange={(e) => onChange({ ...item, value: e.target.value })}
    />
  );
}
