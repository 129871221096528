import React from "react";
import ReportManager, {
  extractCurrencyName,
  groupByCurrency,
} from "commons/components/ReportManager";
import { columns } from "commons/pages/sales/List";

const filters = [
  { type: "filter", name: "facilities", key: "facility_id" },
  {
    type: "filter",
    name: "users",
    key: "user_id",
    extras: { optionLabel: "username" },
  },
  { type: "filter", name: "customers", key: "customer_id" },
  { type: "filter", name: "policies", key: "policy_id" },
  { type: "filter", name: "currencies", key: "sales.currency_id" },
];

export default function SalesByOperation() {
  return (
    <ReportManager
      url="sales"
      title="salesByOperation"
      columns={columns}
      filterByDate
      filters={filters}
      recordsFunc={groupByCurrency}
      titlesFunc={extractCurrencyName}
      totals={[
        "subtotal",
        "discount",
        "tax",
        "total",
        "commission",
        "cost",
        "profit",
      ]}
    />
  );
}
