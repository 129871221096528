import React from "react";
import { Button, ButtonGroup } from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";

export default function CloseCard({ value = false, onChange }) {
  const { t } = useTranslate();
  return (
    <ButtonGroup
      disableElevation
      color="primary"
      variant="contained"
      size="large"
      fullWidth
    >
      <Button
        onClick={() => {
          onChange(true);
        }}
        disabled={value === true}
        id="fd-end-btn"
      >
        {t("close")}
      </Button>
      <Button
        onClick={() => {
          onChange(false);
        }}
        disabled={value === false}
      >
        {t("open")}
      </Button>
    </ButtonGroup>
  );
}
