import React from "react";
import PageCard from "commons/components/PageCard";
import ResourceToolbar from "commons/components/ResourceToolbar";
import ResourceEditor from "commons/components/ResourceEditor";
import FormTextField from "commons/components/FormTextField";
import { FormDateField } from "commons/components/FormDateField";
import FormSelectField from "commons/components/FormSelectField";
import useResourcesByQuery from "commons/hooks/useResourcesByQuery";
import LinkTabs from "commons/components/LinkTabs";
import useControlledResourcePage from "commons/hooks/useControlledResourcePage";

const base = "employees";

export default function Standalone() {
  const { current, send, model, updateModel, id } = useControlledResourcePage(
    base
  );
  const hasResource = Boolean(model.id);

  return (
    <PageCard>
      <ResourceToolbar title={base} current={current} send={send} accessGroup />
      <LinkTabs
        show={hasResource}
        tabs={[
          {
            name: "editor",
            path: `/s/employees/${id}`,
            component: (
              <Editor current={current} send={send} updateModel={updateModel} />
            ),
          },
          {
            name: "balance",
            path: `/s/employees/${id}/balance`,
            component: null,
          },
        ]}
      />
    </PageCard>
  );
}

export function Editor({ current, send, updateModel, ...props }) {
  const { model, rules } = current.context;
  const [departments] = useResourcesByQuery("departments", true);
  const [grades] = useResourcesByQuery("grades", true);

  return (
    <ResourceEditor current={current} send={send} {...props}>
      <FormTextField
        label="name"
        value={model.name}
        onChange={updateModel("name")}
        error={rules.name}
      />
      <FormTextField
        grid={6}
        label="address"
        value={model.address}
        onChange={updateModel("address")}
        error={rules.address}
      />
      <FormTextField
        grid={6}
        label="nid"
        value={model.nid}
        onChange={updateModel("nid")}
        error={rules.nid}
      />
      <FormSelectField
        grid={3}
        label="department_id"
        options={departments}
        value={model.department_id}
        onChange={updateModel("department_id")}
        error={rules.department_id}
      />
      <FormSelectField
        grid={3}
        label="grade_id"
        options={grades}
        value={model.grade_id}
        onChange={updateModel("grade_id")}
        error={rules.grade_id}
      />
      <FormTextField
        grid={3}
        label="primary_phone"
        value={model.primary_phone}
        onChange={updateModel("primary_phone")}
        error={rules.primary_phone}
      />
      <FormTextField
        grid={3}
        label="secondary_phone"
        value={model.secondary_phone}
        onChange={updateModel("secondary_phone")}
        error={rules.secondary_phone}
      />
      <FormTextField
        grid={3}
        label="email"
        value={model.email}
        onChange={updateModel("email")}
        error={rules.email}
      />
      <FormDateField
        grid={3}
        label="dob"
        value={model.dob}
        onChange={updateModel("dob")}
        error={rules.dob}
      />
      <FormDateField
        grid={3}
        label="joined"
        value={model.joined}
        onChange={updateModel("joined")}
        error={rules.joined}
      />
      <FormDateField
        grid={3}
        label="terminated"
        value={model.terminated}
        onChange={updateModel("terminated")}
        error={rules.terminated}
      />
      <FormTextField
        multiline
        grid={6}
        label="qualifications"
        value={model.qualifications}
        onChange={updateModel("qualifications")}
        error={rules.qualifications}
      />
      <FormTextField
        multiline
        grid={6}
        label="custody"
        value={model.custody}
        onChange={updateModel("custody")}
        error={rules.custody}
      />
      <FormTextField
        multiline
        label="notes"
        value={model.notes}
        onChange={updateModel("notes")}
        error={rules.notes}
      />
    </ResourceEditor>
  );
}
