import React, { useMemo, useState, useEffect } from "react";
import CardSection from "commons/components/CardSection";
import {
  Box,
  Tabs,
  Tab,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Popover,
  Grid,
  Tooltip,
} from "@material-ui/core";
import useTranslate from "commons/hooks/useTranslate";
import {
  FormDateTimeField,
  FormDateField,
} from "commons/components/FormDateField";
import {
  Delete,
  InfoOutlined,
  CallSplit,
  LibraryAdd,
  KeyboardReturn,
} from "@material-ui/icons";
import FormTextField from "commons/components/FormTextField";
import { prop, sort, descend } from "ramda";
import FormSelectField from "commons/components/FormSelectField";

export default function StockManager({
  model,
  products = [],
  facilities = [],
  onStockChangeData,
  onPreviousStockChangeData,
  onPreviousStockSplit,
  onStockRemove,
  onStockSplit,
  onStockReturn,
  manualStockChange,
  printComponents = false,
  onApplyAllStockChanges,
}) {
  const { t } = useTranslate();
  const saved = model.stocks || [];
  const lineStocks = model.lines
    ? model.lines.flatMap((line) => line.stocks)
    : [];
  const changes = [...lineStocks, ...(model.returns || [])];
  const [active, setActive] = useState("current");

  const handleChange = (_, newValue) => {
    setActive(newValue);
  };

  return (
    <CardSection p={1}>
      {/* <JSONInspector data={changes} /> */}
      <Tabs value={active} variant="fullWidth" onChange={handleChange}>
        <Tab label={t("previous")} value="previous" />
        <Tab label={t("current")} value="current" />
      </Tabs>
      {active === "previous" && (
        <StocksCard
          stocks={saved}
          products={products}
          facilities={facilities}
          viewOnly
          onStockChangeData={onPreviousStockChangeData}
          onStockReturn={onStockReturn}
          onPreviousStockSplit={onPreviousStockSplit}
          manualStockChange={manualStockChange}
          printComponents={printComponents}
        />
      )}
      {active === "current" && (
        <>
          {manualStockChange && (
            <Tooltip title={t("apply_inventory")}>
              <IconButton onClick={onApplyAllStockChanges}>
                <LibraryAdd />
              </IconButton>
            </Tooltip>
          )}
          <StocksCard
            stocks={changes}
            products={products}
            facilities={facilities}
            onStockChangeData={onStockChangeData}
            onStockRemove={onStockRemove}
            onStockSplit={onStockSplit}
            onPreviousStockSplit={() => {}}
            onStockReturn={() => {}}
            manualStockChange={manualStockChange}
            printComponents={printComponents}
          />
        </>
      )}
    </CardSection>
  );
}

export function useStocksByFacility(
  stocks = [],
  products = [],
  facilities = []
) {
  const facilitiesNames = useMemo(
    () =>
      facilities.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.name }), {}),
    [facilities]
  );

  const productsNames = useMemo(
    () =>
      products.reduce(
        (acc, curr) => ({
          ...acc,
          [curr.product_id]: { name: curr.name, code: curr.code },
        }),
        {}
      ),
    [products]
  );

  const stocksByFacility = useMemo(() => {
    const allStocks = stocks.filter(
      (stock) => stock.quantity && stock.quantity !== 0
    );
    return allStocks.reduce(
      (acc, curr) => ({
        ...acc,
        [curr.facility_id]: [
          ...(acc[curr.facility_id] ? acc[curr.facility_id] : []),
          curr,
        ],
      }),
      {}
    );
  }, [stocks]);

  return {
    facilitiesNames,
    productsNames,
    stocksByFacility,
  };
}

export function StocksCard({
  stocks = [],
  products,
  facilities,
  viewOnly = false,
  printComponents = false,
  manualStockChange = false,
  onStockChangeData,
  onStockRemove,
  onStockSplit,
  onPreviousStockSplit,
  onStockReturn,
}) {
  const { t } = useTranslate();
  const [active, setActive] = useState(null);
  const {
    facilitiesNames,
    productsNames,
    stocksByFacility,
  } = useStocksByFacility(stocks, products, facilities);

  const handleChange = (event, newValue) => {
    setActive(newValue);
  };

  useEffect(() => {
    const first = Object.keys(stocksByFacility)[0];
    if (first && active === null) {
      setActive(first);
    }
  }, [active, stocksByFacility]);

  return (
    <div>
      {active && (
        <Tabs value={active} variant="fullWidth" onChange={handleChange}>
          {Object.keys(stocksByFacility).map((facility) => {
            return (
              <Tab
                key={facility}
                label={facilitiesNames[facility]}
                value={facility}
              />
            );
          })}
        </Tabs>
      )}
      {Object.keys(stocksByFacility).map((facility) => {
        const facilityStocks = stocksByFacility[facility];
        const byQty = descend(prop("quantity"));
        const sorted = sort(byQty, facilityStocks).filter(
          (rec) => Number(rec.quantity) !== 0
        );
        const filtered = sorted.filter((rec) => {
          if (
            !rec.operation_type ||
            ["sale", "purchase"].includes(rec.operation_type)
          )
            return true;
          return printComponents
            ? "componentOut" === rec.operation_type ||
                ("compositeOut" === rec.operation_type &&
                  Number(rec.quantity) > 0)
            : "compositeOut" === rec.operation_type;
        });
        return (
          <div key={facility} hidden={facility !== active}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>{t("code")}</TableCell>
                  <TableCell>{t("name")}</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{t("fulfilled")}</TableCell>
                  <TableCell>{t("quantity")}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filtered.map((line, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {t(line.quantity > 0 ? "enter" : "exit")}
                    </TableCell>
                    <TableCell padding="none">
                      {Math.abs(line.quantity) > 1 && (
                        <IconButton
                          onClick={() =>
                            viewOnly
                              ? onPreviousStockSplit(line)
                              : onStockSplit(line)
                          }
                        >
                          <CallSplit />
                        </IconButton>
                      )}
                    </TableCell>
                    <TableCell>{productsNames[line.product_id].code}</TableCell>
                    <TableCell>{productsNames[line.product_id].name}</TableCell>
                    <TableCell padding="none">
                      <StockLineData
                        line={line}
                        onStockChangeData={onStockChangeData}
                        products={products}
                      />
                    </TableCell>
                    <TableCell padding="none">
                      <Box maxWidth={200}>
                        <FormDateTimeField
                          size="small"
                          value={line.fulfilled}
                          onChange={onStockChangeData(line, "fulfilled")}
                          disabled={viewOnly}
                        />
                      </Box>
                    </TableCell>
                    <TableCell>{Math.abs(line.quantity)}</TableCell>
                    <TableCell padding="none">
                      {!viewOnly && (
                        <IconButton onClick={() => onStockRemove(line)}>
                          <Delete />
                        </IconButton>
                      )}
                      {viewOnly && manualStockChange && (
                        <IconButton onClick={() => onStockReturn(line)}>
                          <KeyboardReturn />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        );
      })}
    </div>
  );
}

function StockLineData({ products, line, onStockChangeData }) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "stock-line-popover-" + line.id : undefined;
  const tags = useMemo(() => {
    const product = products.find(
      (prod) => prod.product_id === line.product_id
    );
    return product && product.storage_tags
      ? product.storage_tags.split("|")
      : [];
  }, [products, line]);

  const onTagsChange = (val) => {
    onStockChangeData(line, "tags")(val.join("|"));
  };

  return (
    <>
      <IconButton size="small" onClick={handleClick}>
        <InfoOutlined />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box p={1}>
          <Grid container spacing={1}>
            <FormSelectField
              multiple
              grid={3}
              label="storage_tags"
              value={
                typeof line.tags === "string" ? line.tags.split("|") : null
              }
              options={tags.map((tag) => ({ id: tag, name: tag }))}
              onChange={onTagsChange}
            />
            <FormTextField
              grid={3}
              label="serial"
              value={line.serial}
              onChange={onStockChangeData(line, "serial")}
            />
            <FormDateField
              grid={3}
              label="exp_date"
              value={line.exp_date}
              onChange={onStockChangeData(line, "exp_date")}
            />
            <FormDateField
              grid={3}
              label="mfg_date"
              value={line.mfg_date}
              onChange={onStockChangeData(line, "mfg_date")}
            />
          </Grid>
        </Box>
      </Popover>
    </>
  );
}
